import React, { useState, useEffect, useRef } from 'react';
import Person2Icon from '@mui/icons-material/PersonOutline';
import { SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Divider, Grid, Stack, Card } from '@mui/material';

/* For Tooltip CSS */
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2563EB',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000000',
    fontWeight: '400',
    boxShadow: '-1px 0px 6px #aaa',
    padding: "15px",
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #2563EB',
    borderRadius: 0,
  },
}));

/* Svg Node Height Width Spacing */
const childXStart = 1;
const childYStart = 1;
const nodeWidth = 269;
const nodeHeight = 48;
const horizontalSpacing = 80;
const verticalSpacing = 274;
const verticalSpacingCollapse = 80;
const innerNodeWidth = 85;

function Teams({ treeData, currentCandidate, type, currentDeptId }) {
  const [selectedCandidate, setSelectedCandidate] = useState(currentCandidate);
  const [selectedCandidateBranch, setSelectedCandidateBranch] = useState([]);
  const [expandedCandidate, setExpandedCandidate] = useState([]);
  const [maxDepth, setMaxDept] = useState(0);
  const [links, setLinks] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [widthSvg, setWidthSvg] = useState(1000);
  const [heightSvg, setHeightSvg] = useState(1000);
  const [dierectors, setDierectors] = useState([]);
  const [showExpanded, setShowExpanded] = useState([]);
  const [parentCandidate, setParentCandidate] = useState([]);
  const [childCandidate, setChildCandidate] = useState([]);
  const [selectedLeaf, setSelectedLeaf] = useState();

  const handleMouseOver = () => {
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };

  const checkLeaf = (nodes, parentId = selectedCandidate) => {
    return nodes.reduce((prev, curr) => {
      if (curr.userId == selectedCandidate && !curr.employee?.length) {
        prev = parentId;
      } else if (curr.employee?.length && prev == selectedCandidate) {
        prev = checkLeaf(curr.employee, curr.userId);
      }
      return prev;
    }, selectedCandidate)
  }

  const getShowExpanded = (teams) => {
    return teams.reduce((prev, curr) => {
      if (curr.employee?.length) {
        let checkBranch = getShowExpanded(curr.employee);
        if (checkBranch.length) {
          prev = [curr, ...checkBranch, ...prev];
        }
      }
      if (curr.userId == selectedCandidate) {
        prev.push(curr);
        setChildCandidate([...(curr?.employee ?? [])]);
      }
      return prev;
    }, [])
  }

  {/* Calculate Width (Desktop view Org chart) */ }
  const calcWidthSvg = () => {
    if (maxDepth && expandedCandidate.length) {
      let max = Math.max(...expandedCandidate) + 1;
      let collapsed = Math.max(0, max - 2);
      let expanded = Math.max(max, 2);
      setWidthSvg((collapsed * (nodeHeight + verticalSpacingCollapse) + expanded * (nodeHeight + verticalSpacing)));
      // console.log((collapsed * (nodeHeight + verticalSpacingCollapse) + expanded * (nodeHeight + verticalSpacing)) - 72)
    } else {
      setWidthSvg(((maxDepth + 3) * innerNodeWidth) + (2 * nodeWidth));
    }
  }

  {/* Calculate Height (Desktop view Org chart) */ }
  const calcHeightSvg = () => {
    if (type == 'expander') {
      setHeightSvg(treeData.length * horizontalSpacing);
      return;
    }
    if (selectedCandidateBranch.length) {
      let max = treeData.length;
      let treeJsonData = treeData;
      for (let i = 0; i < treeJsonData.length; i++) {
        if (selectedCandidateBranch.indexOf(treeJsonData[i].userId) != -1 && treeJsonData[i]?.employee?.length) {
          max = Math.max(max, treeJsonData[i].employee.length);
          treeJsonData = treeJsonData[i].employee;
          i = -1;
        }
      }
      setHeightSvg(max * horizontalSpacing);
      // console.log({ max, len: treeData.length, selectedCandidateBranch, treeData })
    } else {
      setHeightSvg(((maxDepth + 3) * innerNodeWidth) + (2 * nodeWidth));
    }
  }

  const checkSelectedCandidate = () => {
    let leaf = checkLeaf([...treeData])
    setSelectedLeaf(leaf);
  }

  useEffect(() => {
    setDierectors([...treeData]);
    setMaxDept(calculateMaxDepth(treeData, 0));
    calcNodePositions(treeData, childXStart, childYStart);
    calcWidthSvg();
    calcHeightSvg();
    setShowExpanded(getShowExpanded(treeData));
    checkSelectedCandidate();
  }, [])

  useEffect(() => {
    checkSelectedCandidate();
    setSelectedCandidateBranch(getSelectedCandidateBranch(treeData));
    setExpandedCandidate(getExpandedBranch());
    setShowExpanded(getShowExpanded(treeData));
  }, [maxDepth, selectedCandidate])

  useEffect(() => {
    currentDeptId(selectedCandidate);
  }, [selectedCandidate])

  useEffect(() => {
    calcWidthSvg();
  }, [expandedCandidate]);

  useEffect(() => {
    calcNodePositions(treeData, childXStart, childYStart)
    calcHeightSvg();
  }, [selectedCandidateBranch])

  useEffect(() => {
    calcLinkPositions(treeData);
  }, [nodes]);

  useEffect(() => {
    checkSelectedCandidate();
    setDierectors([...treeData]);
    setMaxDept(calculateMaxDepth(treeData, 0));
    setSelectedCandidateBranch(getSelectedCandidateBranch(treeData));
  }, [treeData, type, currentCandidate]);

  useEffect(() => {
    let expanded = showExpanded.map(v => v.userId);
    setParentCandidate([...dierectors.filter(v => expanded.indexOf(v.userId) == -1), ...showExpanded])
  }, [showExpanded, dierectors]);

  // Calculate the maximum depth of the SVG tree (Desktop View)
  const calculateMaxDepth = (nodes, depth = 0) => {
    let maxDepth = Math.max(depth, depth);
    nodes.forEach(node => {
      if (node.employee) {
        maxDepth = Math.max(depth, calculateMaxDepth(node.employee, depth + 1));
      }
    });
    return maxDepth;
  };

  {/* For Selected Candidate (Desktop view Org chart) */ }
  const getSelectedCandidateBranch = (teams) => {
    return teams.reduce((prev, curr) => {
      if (curr.employee?.length) {
        let checkBranch = getSelectedCandidateBranch(curr.employee);
        if (checkBranch.length) {
          prev = [...prev, ...checkBranch, curr.userId];
        }
      }
      if (curr.userId == selectedCandidate)
        prev.push(curr.userId);
      return prev;
    }, [])
  }

  const getExpandedBranchDept = (teams, dept = 0) => {
    return teams.reduce((prev, curr) => {
      if (curr.employee?.length) {
        let checkBranch = getExpandedBranchDept(curr.employee, dept + 1);
        if (checkBranch.length) {
          prev = [...prev, ...checkBranch];
        }
      }
      if (curr.userId == selectedCandidate) {
        prev.push(dept);
        if (curr?.employee?.length) {
          prev.push(dept + 1);
        } else {
          prev.push(dept - 1);
        }
      }

      return prev;
    }, [])
  }

  const getExpandedBranch = () => {
    return [...new Set(getExpandedBranchDept(treeData))];
  }


  // Calculate the position of each node (Desktop View)
  const calcNodePositions = (treeData, x, y) => {
    const nodesCalc = [];
    const calculateNodePositions = (nodes, x, y, dept = 0) => {
      nodes.forEach(node => {
        node.x = x;
        node.y = y;
        node.fill = selectedCandidateBranch.indexOf(node.userId) != -1 ? '#00AEEF' : '#909090';
        if (node.employee) {
          const childY = y + nodeHeight + ((expandedCandidate.indexOf(dept) != -1) ? verticalSpacing : verticalSpacingCollapse);
          calculateNodePositions(node.employee, childXStart, childY, dept + 1);
        }
        x += horizontalSpacing;
        nodesCalc.push(node);
      });
    }
    calculateNodePositions(treeData, x, y);
    setNodes(nodesCalc);
  };

  // Calculate the position of each link (Desktop View)
  const calcLinkPositions = (treeData) => {
    const linksCalc = [];
    const calculateLinkPositions = (nodes, dept = 0) => {
      nodes.forEach(node => {
        if (node.employee) {
          node.employee.forEach(child => {
            if (selectedCandidateBranch.indexOf(node.userId) != -1) {
              linksCalc.push({ source: node, target: child, x1: node.y + ((expandedCandidate.indexOf(dept) != -1) ? nodeWidth : innerNodeWidth), y1: node.x + nodeHeight / 2, x2: child.y, y2: child.x + nodeHeight / 2, fill: (selectedCandidateBranch.indexOf(node.userId) != -1 && selectedCandidateBranch.indexOf(child.userId) != -1) ? '#2563EB' : '#909090' });
            }
          });
          calculateLinkPositions(node.employee, dept + 1);
        }
      });
    };
    calculateLinkPositions(treeData);
    setLinks(linksCalc);
  }

  const getTranslate = (node) => {
    return `translate(${node.y ?? 0},${node.x ?? 0})`;
  }

  /* Get Unique key */
  const getUniqueKey = (key, dept) => {
    return key;
    return parseInt('' + key + dept);
  }

  /* calculate character limit */
  function limitCharacters(str, maxChars) {
    if (str?.length > maxChars) {
      return str.substr(0, maxChars) + "...";
    }
    return str;
  }

  /* To render nodes (Desktop view Org chart) */
  const renderNodes = (nodes, dept = 0) => {
    let result = [];
    for (let i = 0; i < nodes?.length; i++) {
      const node = nodes[i];
      result.push(

        <g key={getUniqueKey(node.userId, dept)} transform={getTranslate(node)}
          onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
          onClick={() => setSelectedCandidate(node.userId)}>

          <rect width={nodeWidth} height={nodeHeight} fill={node.userId == selectedCandidate ? '#DBEAFE' : "#FFFFFF"} stroke={node.fill} strokeWidth={(selectedCandidateBranch.indexOf(node.userId) != -1 || node.userId == selectedCandidate) ? '2px' : '.7px'} rx={3} className={((expandedCandidate.indexOf(dept) != -1) || (type == 'expander')) ? 'node-expanded' : 'node-collapsed'} />

          <HtmlTooltip placement="bottom" title={<>

            <Stack spacing={2} direction='row' divider={node?.employee?.length > 0 && (<Divider orientation="vertical" flexItem />)}>
              {node?.userName ? <>   <Box>
                <Box>{node.userName + ' ' + node.lastName}</Box>
                <Box sx={{ fontSize: '12px', color: '#757575', mt: .5 }}>{node.designation?.designation}</Box>
              </Box></> : <> <Box>{node.department}</Box></>}
              <Box>
                {node?.employee?.length > 0 && (
                  <>
                    <Box sx={{ color: '#2563EB', fontSize: '18px' }}>{node?.employee?.length}</Box>
                    <Box sx={{ fontSize: '12px', color: '#757575', mt: 0 }}>Direct reports</Box>
                  </>)}
              </Box>
            </Stack>
          </>}>
            <foreignObject x="3" y="3" width="40" height="42" >
              {node.userAvatar ? (

                <Card sx={{ height: '42px', boxShadow: 'none', width: '40px' }}><img src={node.userAvatar} alt={(node.userName + ' ' + node.lastName)?.split(' ', 2).map(w => w.charAt(0)).join('')} width="40" height="42" /></Card>
              ) : (<Card sx={{ height: '42px', boxShadow: 'none', width: '40px', background: '#D9D9D9', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <Box>
                  {node?.userName ? <>
                    <Typography variant='subtitle1'>{(node.userName + ' ' + node.lastName)?.split(' ', 2).map(w => w.charAt(0)).join('')}</Typography>
                  </> : <>
                    <Typography variant='subtitle1'>{node.department?.split(' ', 2).map(w => w.charAt(0)).join('')}</Typography>
                  </>}
                </Box>
              </Card>)}
            </foreignObject>
          </HtmlTooltip>

          {((expandedCandidate.indexOf(dept) == -1) && (type == 'structure')) && (
            <>
              {(node?.employee?.length > 0 || parseInt(node?.userCount) > 0) && (
                <g>
                  <SvgIcon component={Person2Icon} x="50" y="17" width="14" height="14" color="action" />
                  <text x={68} y={28} fontSize="12px" fill='#757575'>
                    {node?.employee?.length ?? node?.userCount}
                  </text>
                </g>)}
            </>
          )}

          {((expandedCandidate.indexOf(dept) != -1) || (type == 'expander')) && (
            <>

              {node?.userName ? <>
                <text x={55} y={20} fontSize="14px" fill='#222222'>
                  {limitCharacters(node.userName + ' ' + node.lastName, 20)}  </text></> :
                <><text x={55} y={28} fontSize="14px" fill='#222222'>{limitCharacters(node.department, 25)}</text></>}

              {(node?.employee?.length > 0 || parseInt(node?.userCount) > 0) && (
                <g>
                  <rect x={nodeWidth - 42} y="0" width="0.1" height={nodeHeight} fill="black;"></rect>
                  <SvgIcon component={Person2Icon} x="232" y="17" width="14" height="14" color="action" />
                  <text x={252} y={28} fontSize="12px" fill='#757575'>
                    {node?.employee?.length ?? node?.userCount}
                  </text>
                </g>)}
              <text x={55} y={38} fontSize="12px" fill='rgba(34, 34, 34, 0.5)'>
                {/* {node.designation ? node.designation?.designation.substring(0, 24) + ((node?.designation?.designation?.length > 24) ? '...' : '') : ''} */}
                {limitCharacters(node.designation?.designation, 25)}
              </text>
            </>
          )}
        </g>

      );
      if (node?.employee && selectedCandidateBranch.indexOf(node.userId) != -1)
        result = [...result, ...renderNodes(node.employee, dept + 1)]
    }
    return result;
  }

  /* To render nodes (Mobile view Org chart) */
  const getNode = (node, showLine = false) => {
    let borderColor = (selectedCandidateBranch.indexOf(node.userId) != -1 || node.userId == selectedCandidate) ? '#00AEEF' : '#909090';
    let backgroundColor = (node.userId == selectedCandidate) ? '#DBEAFE' : '#FFFFFF';
    let borderWidth = (selectedCandidateBranch.indexOf(node.userId) != -1 || node.userId == selectedCandidate) ? '2px' : '1px';
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Box sx={{ width: nodeWidth + 'px', height: nodeHeight + 'px', border: borderWidth + ' solid ' + borderColor, borderRadius: '5px', display: 'flex', background: backgroundColor, alignItems: 'center', paddingLeft: '2px' }}>
          {node.userAvatar ? (

            <Card sx={{ height: '40px', boxShadow: 'none', width: '40px' }}><img src={node.userAvatar} alt={(node.userName + ' ' + node.lastName)?.split(' ', 2).map(w => w.charAt(0)).join('')} width="40" height="40" /></Card>
          ) : (<Card sx={{ height: '40px', boxShadow: 'none', width: '40px', background: '#D9D9D9', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
            <Box>
              {node?.userName ? <>
                <Typography variant='subtitle1'>{node.userName?.split(' ', 2).map(w => w.charAt(0)).join('')}</Typography>
              </> :
                <>
                  <Typography variant='subtitle1'>{node.department?.split(' ', 2).map(w => w.charAt(0)).join('')}</Typography>
                </>}
            </Box>
          </Card>)}
          <Box sx={{ width: '180px', padding: '2px 0px 0px 12px' }}>
            <Typography variant='body2' lineHeight={.8} sx={{ mt: type == 'structure' ? .3 : 0 }}>
              {/* {type == 'structure' ? <>{(node?.userName) + " " +  (node.lastName)}</> : <>{node?.department }</>} */}
              {node?.userName ? <>{limitCharacters(node?.userName + " " + node.lastName, 20)}</> : <>{limitCharacters(node?.department, 25)}</>}
            </Typography>
            <Typography variant='caption' color='rgba(34, 34, 34, 0.5)' lineHeight={.8}>  {limitCharacters(node.designation?.designation, 25)}</Typography>
          </Box>
          {(node?.employee?.length > 0 || parseInt(node?.userCount) > 0) && (<Divider orientation="vertical" flexItem />)}
          <Box pl={.7}>
            {(node?.employee?.length > 0 || parseInt(node?.userCount) > 0) && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Person2Icon color='action' fontSize='12px' />
                <Typography variant='caption' sx={{ pt: .2, pr: 1, pl: .4 }} >  {node?.employee?.length ?? node?.userCount}</Typography>
              </Box>)}
          </Box>
        </Box>
        <Box sx={{ width: '100%', height: '20px', display: 'flex', justifyContent: 'center' }}>
          {(showLine && selectedLeaf == node.userId) && <>
            <Box sx={{ borderLeft: '2px solid #2563EB' }}>

            </Box>
          </>}
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ minHeight: '50vh', display: 'flex', alignItems: 'start', justifyContent: 'start', cursor: 'pointer', overflow: 'auto' }} >

      {/* To render nodes (Desktop view Org chart)  */}
      <Box width={widthSvg - 1} sx={{ display: { xs: 'none', sm: 'block', paddingLeft: '16px' } }}>
        {nodes.length ?

          <svg width={widthSvg} height={heightSvg}>
            {links.sort(v => v.fill != '#909090' ? 1 : -1).map((link, index) => {
              let path = 'm ' + link.x1 + ' ' + link.y1 + ' l ' + (link.x2 - link.x1) / 2 + ' 0 l 0 ' + (link.y2 - link.y1) + ' l ' + (link.x2 - link.x1) / 2 + ' 0';
              if (path.indexOf('NaN') != -1) {
                path = 'm 0 0';
              }
              return (<path key={`${getUniqueKey(link.source.userId, index)}-${getUniqueKey(link.target.userId, index)}`} d={path} markerEnd="url(#pos)" fill="none" stroke={link.fill} strokeWidth="1.3" rx={3} fillRule="evenodd"></path>)
            })}
            {renderNodes(treeData)}
          </svg>
          : <></>}
      </Box>

      {/* To render nodes (Mobile view Emp Org chart) */}
      <Box sx={{ display: { xs: 'block', sm: 'none' }, width: '100%' }}>

        {type == 'structure' ? <>
          <Box>
            {parentCandidate.map((node, index) => {
              return <Box key={getUniqueKey(node.userId, index)} onClick={() => setSelectedCandidate(node.userId)}>
                {getNode(node, true)}
              </Box>
            })}
          </Box>

          {childCandidate.length > 0 && (
            <Card sx={{ background: 'rgba(191, 206, 227, 0.4);', width: '100%', pt: 2.5 }}>
              {childCandidate.map((node, index) => {
                return <Box key={getUniqueKey(node.userId, index)} onClick={() => setSelectedCandidate(node.userId)}>
                  {getNode(node)}
                </Box>
              })}
            </Card>)}
        </> : <>
          {/* To render nodes (Mobile view Dept. chart) */}
          {dierectors.map((node, index) => {
            return <Box key={getUniqueKey(node.userId, index)} onClick={() => setSelectedCandidate(node.userId)}>
              {getNode(node, selectedLeaf == node.userId && node.employee?.length > 0)}
              {(selectedLeaf == node.userId && node.employee?.length > 0) && <>
                <Card sx={{ background: 'rgba(191, 206, 227, 0.4);', width: '100%', pt: 2.5, mb: 2.5 }}>
                  {node?.employee?.map((v, i) => <Box key={getUniqueKey(v.userId, ('' + index + i))} onClick={(e) => { e.stopPropagation(); setSelectedCandidate(v.userId); }}>
                    {getNode(v)}
                  </Box>)}
                </Card>
              </>}
            </Box>
          })}
        </>}
      </Box>
    </Box>
  );

}

export default Teams;