import { createSlice } from '@reduxjs/toolkit';
// import dashboard from '../../pages/Dashboard'



const routingSlice = createSlice({
    name: 'routing',
    initialState: {
      currentRoute: window?.location?.pathname ?? '/',
    },
    reducers: {
      setCurrentRoute: (state, action) => {
        state.currentRoute = action.payload;
        console.log(state.currentRoute)
      },
    },
  });
  
  export const { setCurrentRoute,} = routingSlice.actions;
  export default routingSlice.reducer;