import { createSlice } from '@reduxjs/toolkit';

const taskSlice = createSlice({
    name: 'task',
    initialState: {
        taskDetails: [],
    },
    reducers: {
        setTaskDetails: (state, action) => {
            state.taskDetails = action.payload;
        },
    },
});

export const { setTaskDetails } = taskSlice.actions;
export default taskSlice.reducer;
