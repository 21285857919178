import React, { useRef, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

const BasicDetails = ( { setCurrentJSXData, currentJSXData, checkError } ) => {

  const [sameAddress, setSameAddress] = React.useState(false);
  const inputRef = useRef(null)
  const [showErrors, setShowErrors] = React.useState(0);

  // console.log("Aka", {checkError});

  //this function makes the checkbox appear clicked
  const handleSameAddressChange = (event) => {
      event.preventDefault();
      setSameAddress(event.target.checked);

      if(!sameAddress)
      debounceValue();
      inputRef.current = "sometnhing"
  };

  // Function to update the value
  const updateValue = (e) => {
  };

  function debounce(callback, delay){
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        callback(...args)
      }, delay)
    }
  }

  var debounceValue = debounce(()=>{
    let p = presentAddressHelper.get()
    permanentAddressHelper.set({
      'Address': p.Address,
      'Country': p.Country,
      "State": p.State,
      "City": p.City,
      "Pincode": p.Pincode
    }, () => {
    })
  }, 500)


  //Model that contains all the relevant details regarding each and every field that's needed to be rendered in the BASIC DETAILS Component
  let basicDetailsModel = [
    { label: 'First Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[A-Za-z]+$/, errorMessage:'Field must only contain alphabets' }]  },
    { label: 'Last Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[A-Za-z]+$/, errorMessage:'Field must only contain alphabets' } ]  },
    { label: 'Gender', type: "singleSelect", value: '', validators:[ { name: 'required'} ] , data: [{ value: '', label: '' }, { value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }]},
    { label: 'Date Of Birth', type: "dateField", value: '', validators:[ { name: 'required'} ]},
    { label: 'Marital Status', type: "singleSelect", value: '', validators:[ { name: ''} ], data: [{ value: 'single', label: 'Single' }, { value: 'married', label: 'Married' }, { value: 'widowed', label: 'Widowed' }, { value: 'divorced', label: 'Divorced' }] },
    { label: 'Nationality/Region', type: "singleSelect", value: '', validators:[ { name: ''} ], data: [{ value: 'indian', label: 'Indian' }, { value: 'american', label: 'American' }, { value: 'chinese', label: 'Chinese' }, { value: 'russian', label: 'Russian' }]},
    { label: 'Email ID', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, errorMessage:'e.g: hello@gmail.com' } ]},
    { label: 'Mobile No.', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain numbers'},{name:'length', max:15}] },
    { label: 'Alternate Number', type: "textField", size: 'small', value: '', validators:[ { name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain numbers'},{name:'length', max:15}] },
    { label: "Father's Name", type: "textField", size: 'small', value: '', validators:[ { name: ''} ] },
    { label: 'Mothers Name', type: "textField", size: 'small', value: '', validators:[ { name: ''} ] },
    { label: 'Passport Number', type: "textField", size: 'small', value: '', validators:[{ name:'length', absolute:12 }] },
    { label: 'Language Known', type: "textField", size: 'small', value: '', validators:[ { name: ''} ] },
    //validator on identification mark needs to be discussed
    { label: 'Identity Mark', type: "textField", size: 'small', value: '', validators:[ { name: ''} ]},
    { label: 'Blood Group', type: "singleSelect", value: '', validators:[ { name: ''} ], data: [{ value: 'a+', label: 'A+' }, { value: 'a-', label: 'A-' }, { value: 'b+', label: 'B+' }, { value: 'b-', label: 'B-' }, { value: 'ab+', label: 'AB+' }, { value: 'ab-', label: 'AB-' }, { value: 'o+', label: 'O+' }, { value: 'o-', label: 'O-' }] }
  ];

  let basicDetailsHelper = new FormHelper({ model: basicDetailsModel, id: 'basicDetails' });

  let presentAddressModel = [
    {  label: 'Address', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}] , variant: "outlined", sx: { gridColumn: 'span 2' },multiline: true},
    {  label: 'Country', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'bharat', label: 'Bharat' }, { value: 'china', label: 'China' }, { value: 'america', label: 'America' }, { value: 'russia', label: 'Russia' }] },
    {  label: 'State', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'punjab', label: 'Punjab' }, { value: 'kerala', label: 'Kerala' }, { value: 'maharashtra', label: 'Maharashtra' }, { value: 'mp', label: 'Madhya Pradesh' }, { value: 'jnk', label: 'Jammu & Kashmir' }] },
    {  label: 'City', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'delhi', label: 'Delhi' }, { value: 'ghaziabad', label: 'Ghaziabad' }, { value: 'noida', label: 'NOIDA' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
    {  label: 'Pincode', type: "textField", size: 'small', validators:[ { name: 'required'}], value: '' }
  ];

  let presentAddressHelper = new FormHelper({ model: presentAddressModel, id: 'presentAddress' } );
  
  let permanentAddressModel = [
    { disabled: sameAddress, label: 'Address', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}], variant: "outlined", sx: { gridColumn: 'span 2' },multiline: true },
    { disabled: sameAddress, label: 'Country', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'bharat', label: 'Bharat' }, { value: 'china', label: 'China' }, { value: 'america', label: 'America' }, { value: 'russia', label: 'Russia' }] },
    { disabled: sameAddress, label: 'State', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'punjab', label: 'Punjab' }, { value: 'kerala', label: 'Kerala' }, { value: 'maharashtra', label: 'Maharashtra' }, { value: 'mp', label: 'Madhya Pradesh' }, { value: 'jnk', label: 'Jammu & Kashmir' }] },
    { disabled: sameAddress, label: 'City', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'delhi', label: 'Delhi' }, { value: 'ghaziabad', label: 'Ghaziabad' }, { value: 'noida', label: 'NOIDA' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
    { disabled: sameAddress, label: 'Pincode', type: "textField", size: 'small', value:'', validators:[ { name: 'required'}] }
  ];

  let permanentAddressHelper = new FormHelper({ model: permanentAddressModel, id: 'permanentAddress' });
  
  const [basicDetailsHelperData, setBasicDetailsHelperData] = useState(basicDetailsModel);
  const [presentAddressHelperData, setPresentAddressHelperData] = useState(presentAddressModel);
  const [permanentAddressHelperData, setPermanentAddressHelperData] = useState(permanentAddressModel);
  // presentAddressHelper.isActiveTouch.activeTouch.subscribe(()=>{
  //   if(sameAddress)
  //   {
  //     debounceValue()
  //   }
  // })
  
  const valueChangeListner = () => {
    let basicDetailsHelper = basicDetailsHelperData.reduce((prev,curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, []);

    let presentAddressHelper = presentAddressHelperData.reduce((prev,curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, []);

    let permanentAddressHelper = permanentAddressHelperData.reduce((prev,curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, []);

    var error = false;
    var errorMessage = false;
    
    for (let i = 0; i < basicDetailsHelperData.length; i++) {
        for (let j = 0; j < basicDetailsHelperData[i]?.validation?.length; j++) {
            var res = basicDetailsHelperData[i]?.validation[j](basicDetailsHelperData[i].value);
            if (res.error) {
              console.log(res.error, res.errorMessage, basicDetailsHelperData[i], basicDetailsHelper)
                error = res.error;
                errorMessage = res.errorMessage;
                break;
            }
        }
    }

    for (let i = 0; i < presentAddressHelperData.length; i++) {
      for (let j = 0; j < presentAddressHelperData[i]?.validation?.length; j++) {
          var res = presentAddressHelperData[i]?.validation[j](presentAddressHelperData[i].value);
          if (res.error) {
            console.log(res.error, res.errorMessage, basicDetailsHelperData[i])
              error = res.error;
              errorMessage = res.errorMessage;
              break;
          }
      }
    }

    for (let i = 0; i < permanentAddressHelperData.length; i++) {
      for (let j = 0; j < permanentAddressHelperData[i]?.validation?.length; j++) {
          var res = permanentAddressHelperData[i]?.validation[j](permanentAddressHelperData[i].value);
          if (res.error) {
            console.log(res.error, res.errorMessage, basicDetailsHelperData[i])
              error = res.error;
              errorMessage = res.errorMessage;
              break;
          }
      }
    }

    console.log("Akah", {checkError, error});
    if(error){
        setShowErrors(showErrors + 1);
        return;
    }

    console.log({basicDetailsHelper, presentAddressHelper, permanentAddressHelper});
    setCurrentJSXData({...currentJSXData,basicDetails: basicDetailsHelper, presentAddress: presentAddressHelper, permanentAddress: permanentAddressHelper})
  }

  useEffect(() => {
    // console.log("Akas top", {checkError});
    if(checkError){
      valueChangeListner();
    }
  }, [checkError])

  // useEffect(() => {
  //   console.log("Tera Aks", {basicDetailsHelperData, presentAddressHelperData, permanentAddressHelperData})
  //   valueChangeListner();
  // }, [basicDetailsHelperData, presentAddressHelperData, permanentAddressHelperData]);

  return (
    <>
      <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
        <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {setBasicDetailsHelperData(data);  }} {...basicDetailsHelper.model} />
      </Box>
      <Box>
        <Box sx={{ paddingBottom: "var(--padding-bottom-1)", paddingTop: "var(--padding-top-1)" }}>
          <Typography>
            Present Address
          </Typography>
        </Box>
        <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
          <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {setPresentAddressHelperData(data);  }} {...presentAddressHelper.model} />
        </Box>
      </Box>
      <Box>
        <Box sx={{ paddingBottom: "var(--padding-bottom-1)", paddingTop: "var(--padding-top-1)" }}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={sameAddress} name="sameAddress" onChange={handleSameAddressChange}  />} label="Permanent Address and Present Address is same" />
          </FormGroup>
        </Box>
      </Box>
        <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
          <RenderHelper disabled={sameAddress} showErrors={showErrors} getCurrentValues={(data) => {setPermanentAddressHelperData(data);  }} {...permanentAddressHelper.model} />
        </Box>
    </>
  );
}
export default BasicDetails;