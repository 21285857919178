// import React from 'react'
import Coin from '../../assets/images/gold-coin.png';
import Box from '@mui/material/Box';
import Badge from '../../assets/images/badge.png';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LeaderboardData from '../../datafiles/Leaderboard/Leaderboard/leaderboardDataTable.json';
import manfollow from '../../assets/images/run.gif'
import ApiService from '../../utils/ApiService';
import React, { useEffect, useState, useRef } from "react";
import { openSnackbar } from "../../features/snakebar/snackbarSlice";
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const LeaderboardTab = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.userListName.userId);
  const LeaderData = [
    { id: 'rank', label: 'Rank', minWidth: 100, template: (row) => {return (<>
    <b className="rank-on-leaderboard">
      {/* {row['rank']} */}
      {row.userId}
    </b>
      </>)}},
    { id: 'name', label: 'Name', minWidth: 100,template: (row) => {return (<>
    <Box sx={{display: 'flex', gap:'20%'}}>
      <Box sx={{display: 'flex',alignItems: 'center', justifyContent: 'left'}}>
        {/* {<img className='image-on-leaderboard' src={row.avatar} />} */}
        {<b className="name-on-leaderboard"> {row.firstName} </b>}
      </Box>
      <Box sx={{display: 'flex',alignItems: 'flex-end'}}>
        {
        row.userId === userId && 
        <Box>
        <img src={manfollow} alt="My Image" style={{scale:'2.8',height:"1rem",width:'2rem',mr:'1rem'}}/>
        </Box>
        }
      </Box>
    </Box>
    </>)}},
    { id: 'badge', label: 'Badge', minWidth: 100,template: (row) => {return (<>
       {row.badges.map((v, i) =>
        
        <img className="badge-on-leaderboard" key={i} alt={v?.name} src={v?.badgeImage} />)}
    </>)}},
    { id: 'coin', label: 'Coin', minWidth: 100, template: (row) => {   
      return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
          {/* {row.coin.type == 'Coin' ? <img className='coin-style coin-on-leaderboard' src={Coin} /> : <img className='coin-style coin-on-leaderboard' src={Badge} />} {<b className="name-on-leaderboard"> {row.totalCoins} </b>} */}
          { <img className='coin-style coin-on-leaderboard' src={Coin} />}
          {/* {  <img className='coin-style coin-on-leaderboard' src={Badge} />} */}
           {<b className="name-on-leaderboard"> {row.totalCoins} </b>}
          </Box>
        </>
      )
    }} ,
    { id: 'totalPoint', label: 'Total Points', minWidth: 100, template: (row) => {return(<>
     {/* <b className="name-on-leaderboard">
       {parseInt(row.totalPoint)}</b> */}
    </>
      )}
      },
  ];

  const [leaderData, setLeaderData] = useState([])
  const [meta, setMeta] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const searchInputRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [currentPage,rowsPerPage,searchQuery]);

  useEffect(() => {
    // Focus on the search input after each re-render
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  });

  const fetchData = () => {
    let params = {
      limit : rowsPerPage,
     page : currentPage,
     // search: pageNo + 1,
      }
      if (searchQuery) {
        params.search = searchQuery;
      }
    ApiService.get('gamificationUserSearch',params).then(res => {
        let response = res.data.data
        // console.log(response)
        setLeaderData(response)
        setMeta(res.data.meta)
        // console.log(res.data.meta.last_page);
        applySearch(response);
        // if (response) {
        //     dispatch(setUserInfo(response));
        // }
    }).catch((error) => {
        console.log(error);
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }))
    })  
}

// const applySearch = (data) => {
//   const lowercasedQuery = searchQuery.toLowerCase();
//   const filteredData = data.filter((item) => {
//     return item.name.toLowerCase().includes(lowercasedQuery); // Filter by the name field
//   });
//   setFilteredData(filteredData);
// };

const applySearch = (data) => {
  const lowercasedQuery = searchQuery ? searchQuery.toLowerCase() : "";
  const filteredData = data.filter((item) => {
    // Ensure item.name exists and is a string before calling toLowerCase()
    const itemName = item.name && typeof item.name === 'string' ? item.name.toLowerCase() : "";
    return itemName.includes(lowercasedQuery);
  });
  setFilteredData(filteredData);
};

const handlePageChange = (event, newPage) => {
  setCurrentPage(newPage + 1); // MUI Pagination starts from 0, but your API starts from 1
};
  
  function createData(rank, name, badge, coin,totalPoint) {
    return { rank, name, badge, coin, totalPoint };
  }
    // const [page, setPage] = React.useState(0);
    
  
    // const handleChangePage = (event, newPage) => {
    //   setPage(newPage);
    // };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setCurrentPage(1);
    };

    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value); // Update searchQuery state
    };
    const Search = styled('div')(({ theme }) => ({
      position: 'relative',
      borderRadius: '36px',
      backgroundColor: '#EDF5FE',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    }));
  
    const SearchIconWrapper = styled('div')(({ theme }) => ({
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }));
  
    const StyledInputBase = styled(InputBase)(({ theme }) => ({
      color: 'inherit',
      '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '12ch',
          '&:focus': {
            width: '20ch',
          },
        },
      },
    }));
    

  return (
    <div>
      <Grid container spacing={2} sx={{mb: 2}}>
                  <Grid item xs={6} md={8} lg={9} sx={{alignItems:'center'}}>
                    <Typography variant="h6" fontWeight='bold' color="#000000">Leaderboard</Typography>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                  <Search>
                      <SearchIconWrapper sx={{ color:'#64748B' }}>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        // sx={{borderRadius:'30px',backgroundColor:"#EEF6FF"}}
                        placeholder="Search here…"
                        inputProps={{ 'aria-label': 'search' }}
                        value={searchQuery}
          onChange={handleSearchChange}
          inputRef={searchInputRef}
                      />
                    </Search>
                  </Grid>
                </Grid>
    
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            {LeaderData.map((column, index) => {
                        return (
                <TableCell
                  sx={{backgroundColor:'#EEF6FF'}}
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth , color:"#64748B"}}
                >
                  {column.label}
                </TableCell>
             )
            })}
            </TableRow>
          </TableHead>
          <TableBody>
                  {/* {LeaderboardData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row,index) => {
                      return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {LeaderData.map((column) => {
                      const value = row[column.id];
                      
                      return (
                        <TableCell sx={{p:'.5rem 1rem', borderBottom:'1px solid rgba(0, 0, 0, 0.1) '}} key={column.id} align={column.align}>
                          {
                          column.template
                            ? column.template(row) 
                            : value
                            }
                          
                             
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  );
                })} */}
                {leaderData.map((row, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {LeaderData.map((column) => {
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.template(row)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        // rowsPerPageOptions={[5, 10, 15]}
        // component="div"
        count={meta.last_page}
        // rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handlePageChange}
        // onRowsPerPageChange={handleChangeRowsPerPage}
        // onChange={handlePageChange}
      /> */}
      {meta.last_page && (
      <TablePagination
      // rowsPerPageOptions={[10]}
      // rowsPerPageOptions={[10,10]}
        count={meta.total} // Total number of pages
        page={currentPage - 1} // MUI Pagination starts from 0, but your API starts from 1
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // pageSizeOptions='10'
      />
      )}
    </Paper>
    </div>
  )
}

export default LeaderboardTab