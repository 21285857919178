import React,{useEffect,useState} from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InputUnstyled from '@mui/base/InputUnstyled';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import TextField from '@mui/material/TextField';
import banner from '../../assets/images/company-banner.png'
import bannerEnlarged from '../../assets/images/company-banner-enlarged.png'
import DownloadIcon from '@mui/icons-material/Download';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Modal from '@mui/material/Modal';
import ImageIcon from '@mui/icons-material/Image';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Post from '../../assets/images/harddisk-vector.svg';
import Profile from '../../assets/images/linkedinSample-vector.svg';
import LinkIcon from '../../assets/images/link-vector.svg';
import SampleImage1 from '../../assets/images/linkedin-post-image.png';
import SampleImage2 from '../../assets/images/linkedin-post-image2.png';
import Link from '@mui/material/Link';
import { openSnackbar } from "../../features/snakebar/snackbarSlice";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDispatch,useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import ApiService from '../../utils/ApiService';
// import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import { CircularProgress } from "@mui/material";


const LinkedInDrawer = ({parentId}) => {
  //stack item//

  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));
  //


  const [age, setAge] = React.useState('');
  const [flag, setFlag] = useState(false)
  const userId = useSelector((state) => state?.user?.userListName.userId);
  const [openModal1, setOpenModal1] = React.useState(false);
  const handleOpen1 = () => setOpenModal1(true);
  const handleClose1 = () => setOpenModal1(false);
  
  const [openModal2, setOpenModal2] = React.useState(false);
  const handleOpen2 = () => setOpenModal2(true);
  const handleClose2 = () => setOpenModal2(false);

  const [openModal3, setOpenModal3] = React.useState(false);
  const handleOpen3 = () => setOpenModal3(true);
  const handleClose3 = () => setOpenModal3(false);
  
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  //text fiels///
  const StyledTextareaElement = styled(TextareaAutosize)(
    ({ theme }) => `
  width: 320px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px;
  border-radius: 8px;
  min-width: 32rem;
  min-height: 6rem;

`,
  );

  const [userTemplates, setUserTemplates] = React.useState([]);
  const [templateBanner, setTemplateBanner] = React.useState([]);
  // console.log(templateBanner[0]?.fileUrl)
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [description, setDescription] = useState('');
  const [tempId, setTempId] = useState('');
  useEffect(() => {
    ApiService.get('usersPostTemplate',{related:['usersPostTemplate']}).then(userTemplate => {
      // let response = res.data.data
      // console.log(userTemplate.data.data)
      setUserTemplates(userTemplate.data.data)
      
  }).catch((error) => {
      console.log(error);
      dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }))
  })

  ApiService.get('postTemplateBanner',{type:'LinkedIn'}).then(banner => {
    // let response = res.data.data
    // console.log(banner.data.data)
    setTemplateBanner(banner.data.data)
    
}).catch((error) => {
    console.log(error);
    dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }))
})
  }, []);

  const handleChange = event => {
    const selectedTemplateId = event.target.value;
    setSelectedTemplate(selectedTemplateId);

    // Find the selected template
    const selectedTemplate = userTemplates.find(template => template.id === selectedTemplateId);
    // console.log(selectedTemplate.id)
    if (selectedTemplate) {
      // Update the description based on the selected template
      setDescription(selectedTemplate.description);
      setTempId(selectedTemplate.id)
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(description);
  };

  const [linkedinURL, setLinkedinURL] = useState('');
  const handleURL = (event) => {
    const newValue = event.target.value;
    setLinkedinURL(newValue);
    
  };


  const sendLinkedinURL = async () => {
    const formData = new FormData();
    // formData.append('fileName', fileName.split(".")[0]);
    if (linkedinURL) {
      formData.append('postLink', linkedinURL);
    }
    formData.append('userId', userId);
    formData.append('userPostTempleId', tempId);
    setFlag(true)
    // try {
      ApiService.postAdopter('postLink', formData).then(response => {
      // console.log(response)
      setFlag(false)
      handleDrawerClose() 
      // dispatch(openSnackbar({ dataLoad: true, message: 'Document uploaded successfully', severity: 'success' }));
      dispatch(openSnackbar({ dataLoad: true, message: response.data.data.title, severity: "info" }));
     
    }).catch((error) => {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const errorMessage = error.response.data.message || 'Something went wrong';
        dispatch(openSnackbar({ dataLoad: true, message: errorMessage, severity: 'error' }));
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        dispatch(openSnackbar({ dataLoad: true, message: 'No response received', severity: 'error' }));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error setting up request:', error.message);
        dispatch(openSnackbar({ dataLoad: true, message: 'Error setting up request', severity: 'error' }));
      }
      setFlag(false)
    });
  }

  const CustomInput = React.forwardRef(function CustomInput(props, ref) {
    return (
      <InputUnstyled
        slots={{ textarea: StyledTextareaElement }}
        {...props}
        ref={ref}
      />
    );
  });
 return (
    <Box sx={{minWidth:'var(--width-drawer)'}}>
        <Stack sx={{ backgroundColor: "var(--color-lightblue)" }}>
                  <Box sx={{p:'0.25rem .1rem'}}>
                      <Grid container direction="row" alignItems="center">
                          <Grid item xs={10}>
                              <Box pl='1rem' display='flex' sx={{flexDirection:'column'}}>
                                  <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Update LinkedIn Profile </Typography>
                                  <Typography variant="body2" display="block" color='var(--color-greyblue)' >Update your organization information on LinkedIn and Earn coins</Typography>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sx={{display:'flex', justifyContent:'flex-end'}}>
                              <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                                  <CloseIcon sx={{color:'var(--color-black)'}}/>
                              </IconButton>
                          </Grid>
                      </Grid>
                  </Box>
        </Stack>
      {/* <Stack sx={{ backgroundColor: "#DBEAFE", mb:'1rem' }}>
        <Box sx={{ mx: '15px', my: '15px' }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Box><Typography variant="h8" color="text.primary" fontWeight="bold" display="block" gutterBottom>Update Linkedin Profile</Typography></Box>
              <Box><Typography variant="h8" color="text.secondary" display="block" gutterBottom>Update your organization information on Linkedin and Earn coins</Typography></Box>
            </Grid>
            <Grid item xs={2}>
              <IconButton sx={{ ml: "40px" }} aria-label="upload picture" component="label" onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Stack> */}
      <Box sx={{maxHeight: 'calc(100vh - 8rem)', overflow: 'auto', mb:'2rem', pt:'var(--equal-hw1)'}}>
          {/* //Step 2 */}
          <Stack sx={{ backgroundColor: "#DBEAFE"  }}>
            <Box sx={{ mx: '1rem', mt: '1rem' }}>
              <Typography variant="h8" color="text.primary" display="block" gutterBottom><b>Step 1: </b>Update your organization information on Linkedin</Typography>
            </Box>

            <Box sx={{ mx: '1rem'  }}>
              <FormControl sx={{m:'var(--margin-linkedin-template)', minWidth: '14rem'}} size='small'>
                {/* <InputLabel sx={{ display: 'flex', color:'black' }}>
                  <Box className='linkedin-select-template'><DescriptionSharpIcon fontSize='small' />
                  <Typography variant='subtitle2' >SELECT TEMPLATE</Typography></Box>
                </InputLabel> */}
                
                <TextField
                select
        // labelId="demo-simple-select-autowidth-label"
        value={selectedTemplate}
        onChange={handleChange}
        autoWidth
        required
        label="SELECT TEMPLATE---"
        sx={{ backgroundColor: 'white',borderRadius:'6px' }}
      >
        {userTemplates.map(template => (
          <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
        ))}
      </TextField>
      
              </FormControl>
            </Box>
            {/* <Box className='multiline-field' sx={{ position:'relative', ml: '1rem' }}>
              <CustomInput aria-label="Demo input" multiline placeholder="I am excited to start my new job! #THP" />
              <Box className='overlay-copy-icon'>
                <ContentCopyIcon fontSize='small'/>
                <Typography variant='body2'>Copy</Typography>
              </Box>
            </Box> */}
            <Box className='multiline-field' sx={{ position:'relative', m: '1rem' }}>
        <TextField
          value={description}
          fullWidth
          variant="outlined"
          placeholder="I am excited to start my new job! #THP"
          label="Description"
          multiline
          rows={4}
          // InputProps={{
          //   readOnly: true,
          // }}
        />
        <Button onClick={handleCopyClick}>Copy Description</Button>
      </Box>
            {/* <Box sx={{ ml: '0.5rem' }}>
            <Button variant="text" sx={{letterSpacing:'0.05rem'}}># Add Hashtag</Button>
            </Box> */}
            <Box sx={{ mx: '1rem', mt: '1rem' }}>
              <Stack direction="column" spacing={1}>
                <Box><Typography variant="subtitle2" color="text.primary" gutterBottom >Visit Linkedin to post a new announcement, Review the sample post below.</Typography></Box>
                <Box>
                  <Stack direction="row" spacing={1}>
                    <Box>
                    <a rel="noopener nofollow" target="_blank" href="https://www.linkedin.com/">
                      <Button variant="contained" startIcon={<LinkedInIcon />} size="small">
                        GO TO LINKEDIN
                      </Button>
                      </a>
                    </Box>
                    <Box>
                      <Link>
                          <InputLabel sx={{ display: 'flex', color:'black' }}>
                          <Button onClick={handleOpen2} startIcon={<img src={Post} alt="sample" />} size="small">
                            <Typography variant='body2' color="var(--color-black)">Sample Post</Typography>
                          </Button>
                          </InputLabel>
                      </Link>
                    </Box>

                    <Modal
                        open={openModal2}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                          <Box>
                            <Stack direction='row' p={1} sx={{position:'relative'}}>
                                <ArrowBackIcon onClick={handleClose2} sx={{color:'white'}}/>
                            </Stack>
                            <img className='modal-style2' src={SampleImage1} alt="banner"/>
                          </Box>
                      </Modal>

                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Box sx={{ m:'1rem'}}>
              <Stack direction="column" spacing={1}>
                <Box><Typography variant="subtitle2" color="text.primary" display="block" gutterBottom >Update the linkedin background image with the given below. Review the sample profile.</Typography></Box>
                <Box>
                  <Stack direction="row" spacing={2}>
                      <Card sx={{ maxWidth: '8.25rem' }}>
                        <CardActionArea className="card-action" onClick={handleOpen1}>
                          <CardMedia
                            sx={{position:'relative'}}
                            component="img"
                            height="30px"
                            image={templateBanner[0]?.fileUrl}
                            alt="company-banner"
                          />
                          <Box className="overlay-icon-banner" sx={{display:'flex', justifyContent:'center'}}>
                            <DownloadIcon fontSize='small' sx={{color:'white', mt:'.3rem'}}/>
                          </Box>
                          <CardContent sx={{p:"0.1rem", backgroundColor:'#E6E6E6'}}>
                            <Typography textAlign="center" variant="body2" fontSize=".6rem" >
                                Linkedin background photo
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                      <Modal
                        open={openModal1}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                          <Box>
                            <Stack direction='row' p={1} sx={{position:'relative'}}>
                                <ArrowBackIcon onClick={handleClose1} sx={{color:'white'}}/>
                                <InputLabel sx={{ display: 'flex', color:'black' }}>
                                  <Box pl={4}><ImageIcon color='primary' /></Box>
                                  <Typography variant="body2" color='white' fontSize='1.125rem' pl="1rem">LinkedIn background photo</Typography>
                                </InputLabel>
                                 <Box sx={{position:'absolute', right:'1rem'}}> <DownloadIcon sx={{color:'white'}}/> </Box>
                            </Stack>
                            <img className='modal-style' src={bannerEnlarged} alt="banner"/>
                          </Box>
                        
                      </Modal>
                    <Box>
                    <Link>
                          <InputLabel sx={{ display: 'flex'}}>
                          <Button sx={{hover:{backgroundColor:'none !important'}}} onClick={handleOpen3} startIcon={<img src={Profile} alt="sample" />} size="small">
                            <Typography variant='body2' color="var(--color-black)">Sample Post</Typography>
                          </Button>
                          </InputLabel>
                      </Link>
                    </Box>
                    <Modal
                        open={openModal3}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                          <Box>
                            <Stack direction='row' p={1} sx={{position:'relative'}}>
                                <ArrowBackIcon onClick={handleClose3} sx={{color:'white'}}/>
                            </Stack>
                            <img className='modal-style2' src={SampleImage2} alt="banner"/>
                          </Box>
                      </Modal>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>

          {/* //Step 2 */}
          <Stack>
            <Box sx={{ mx: '1rem', mt: '1rem' }}>
              <Typography variant="h8" color="text.primary" display="block" gutterBottom><b>Step 2: </b>Share the updated Linkedin url after completion the task</Typography>
            </Box>
          </Stack>
          <Stack sx={{ mt: '1rem', mx: '1rem', boxShadow: 'none' }} direction="column" spacing={0.5}>
            <Box sx={{ display: 'inline-flex', gap:'.5rem', alignItems:'center' }}>
              <img src={LinkIcon} alt='link' />
              <Typography variant="subtitle2" color="text.primary" display="block" gutterBottom>Link From The Web</Typography>
            </Box>
            <Box sx={{ backgroundColor: '#DBEAFE' }}>
              <TextField fullWidth label="Paste Link Here" 
                                required
                                id="linkedinURL"
                                value={linkedinURL}
                                onChange={handleURL}
                                name="linkedinURL"
                                />
            </Box>
          </Stack>
          
        </Box>
        <Box sx={{backgroundColor:'white', width:'100%', position:'absolute', bottom:'0', mt:'4rem', height:'4rem'}}>
        <Stack sx={{ mx:'1rem', pt:'1rem'}} direction="row" spacing={1}>
        {flag == false ?
          <Button variant="contained" size="small"
          disabled={
            linkedinURL.length == '' ||
            selectedTemplate.length == '' }
           onClick={() => sendLinkedinURL()}>SUBMIT</Button>
           :
                                <Button variant="contained">
                                    <CircularProgress color={'success'} size="20px" />
                                </Button>
                        }
          <Button variant="text" size="small" onClick={handleDrawerClose} >CANCEL</Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default LinkedInDrawer