/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, {useEffect, useState} from 'react';
import InputTextField from './component/inputTextField';
import InputSingleSelect from './component/InputSingleSelect';
import InputDatePicker from './component/InputDatePicker';
import { subscribe } from './helper/publisher.js';
import InputUploadFiles from './component/inputUploadFiles.js';
import InputAutocomplete from './component/InputAutocomplete.js';

const RenderHelper = ({model, id, readOnly, disabled, editMode, showErrors, updatedData, getCurrentValues = () => {}}) => {
  // console.log("modelTemp initiated")
  const [modelTemp, setModelTemp] = useState(model);
  const [valueUpdated, setValueUpdated] = React.useState(true);
  const [modelRender, setModelRender] = React.useState(model);
  const [chainUpdatedData, setChainUpdatedData] = React.useState(updatedData)

  function subscriberFunction(data) {
    setModelRender(data);
    setModelTemp(data);
    getCurrentValues(data);
    // console.log("Satyam is here", {id, data})
    // setValueUpdated(false);
  }

  React.useState(() => {
    subscribe(subscriberFunction, id);
  }, [])

  useEffect(() => {
    if(!valueUpdated){
      setValueUpdated(true);
    }
  }, [valueUpdated])

  useEffect(() => {
    if(Array.isArray(updatedData))
      setChainUpdatedData([...updatedData]);
  }, [updatedData]);

  useEffect(() => {
    setValueUpdated(false);
  }, [readOnly,disabled,editMode]);
  
  useEffect(() => {
    setValueUpdated(false);
  }, [modelRender])

  const updateParent = (value, i) => {
    let tempModel = [...modelTemp];
    tempModel[i].value = value;
    setModelTemp(tempModel);
    getCurrentValues(tempModel);
  }

  return (
    // <>
     valueUpdated ? 
       <>
          {modelRender?.map((v,i) => {
              switch(v.type){
                  case 'textField': return (<InputTextField chainUpdatedData={chainUpdatedData} showErrors={showErrors} model={model} modelKey={i} key={v.version + i} id={id} editMode={editMode} change={(value) => {updateParent(value, i)}} {...v} />);
                  case 'singleSelect': return (<InputSingleSelect chainUpdatedData={chainUpdatedData} showErrors={showErrors} model={model} modelKey={i} id={id} key={v.version + i} {...v} editMode={editMode} change={(value) => {updateParent(value, i)}} />);
                  case 'autoComplete': return (<InputAutocomplete chainUpdatedData={chainUpdatedData} showErrors={showErrors} model={model} modelKey={i} id={id} key={v.version + i} {...v} editMode={editMode} change={(value) => {updateParent(value, i)}} />);
                  case 'dateField': return (<InputDatePicker chainUpdatedData={chainUpdatedData} showErrors={showErrors} model={model} modelKey={i} id={id} key={v.version + i} {...v} editMode={editMode} change={(value) => {updateParent(value, i)}} />);
                  case 'uploadFile': return (<InputUploadFiles chainUpdatedData={chainUpdatedData} showErrors={showErrors} model={model} modelKey={i} id={id} key={v.version + i} {...v} editMode={editMode} change={(value) => {updateParent(value, i)}} />);
              }
          })}
      </>
      :
      <></>
      // </>
  )
}
export default RenderHelper;