import axios from "axios";
import { getDepartmentApi } from '../../_constant/organization/department.constant'


export async function getDepartment(limit, page) {
  let prarmTempObj = {};

  // prarmTempObj.app=getAppToken();
  prarmTempObj.app = '1234560';

  if (limit) {

    prarmTempObj.limit = limit;

  }

  if (page) {

    prarmTempObj.page = page;

  }

  const response = await axios.get(

    process.env.REACT_APP_BASEURL + getDepartmentApi.LIST,

    {

      params: prarmTempObj,

      headers: {

        // Authorization: 'Bearer '+getSelfToken(),
        Authorization: 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZGQyM2E0OGZhMDBhYWI0ZGU3ODdmMDBkYmVlYzg1NDJhYjcyZGM2YmQzNzNhMTA2NGE2YzRhNzg0ZDVjMmFjN2NiNTMzNWYwZTBkZjQzYzIiLCJpYXQiOjE2NzU5NTAxNzAuMTA4Njk4LCJuYmYiOjE2NzU5NTAxNzAuMTA4NzA2LCJleHAiOjE3MDc0ODYxNzAuMTAzMzY0LCJzdWIiOiI3Iiwic2NvcGVzIjpbXX0.LLiTzT_r41VglafoT5cWYZVqWucoJpVEDzAijZUAyv6EJscpIXTX8PpYSPSuQsvS7jc2-Hwieee5ekTmlvyhnDD3s-eghMpDehfTtOkZRpSPyz8F7UnJSVRFNwZYQ0KcVZEAPVWKruzWktMQ6aFdxEUEKpA-92ZxR1vR-jCxETwicmgam3OLKlAu74Puh8k5EZV4bOl3eqnE-n41TRutPsYi2pUnZ9QqvwfEWKMM1E-chF6kw4pNLJd6IrdTmc3ZeOL-APi3dm9iLgSb0HFXWUJM6iKoq-smAvlXQsUOReF1NLdpAyq125pz1c9gbUowDSnxMVHpIGkiOHeXZlAXzoJAiwmo0MHOo5kL1o1VEnFlB84dkbxk7nzH6alUyOUbAQQ5SflAU4vHLhuLmcpStKokCppBm-P81r6vhuhEOuryrKEnC3EedOkKoQxP7gZsGB98ZK22jR27gFpu8yRbPIYZAyHgKcsxXReCMdtXiA819NsscZ-CbFLq5k0h9rBM_AWVhOsStVz5301fPJC2H__jzRsVDXn776iyCoT7ccKxqQ0zZfy7vVMQ1qixrLHWTqu2D0VpZkbMGyldi76aWG7YdXzbJCW0yhahGhuD3LEAf5vnf_ooDuhmAamxU5587nCUWQ3upuSUkpEkHuxmd-ATMoi3n9zws4VArJtglLk',

      },

    }

  ).then(function (response) {

    return response;

  }).catch(function (error) {
    return error.response;

  });

  return response;

}