import React, {useEffect, useState} from 'react'
import {Box, Typography, CardContent, Card, Avatar, CardHeader, AvatarGroup,
        List, ListItem, ListItemText, Button,
        EventSharp as EventSharpIcon,
        blue} from '../../utils/AllImportsHelper';
// import UpcomingEventsDummyData from '../../datafiles/dashboard/upcomingEventsDummyData.json';
import ApiService from '../../utils/ApiService';
import CircularProgress from '@mui/material/CircularProgress';

const UpcomingEvent = () => {
    const [dense, setDense] = React.useState(false);

    const [loading, setLoading] = useState(true);
    const [upcomingEvents, setUpcomingEvents] = useState([]);

    useEffect(() => {
      ApiService.get('upcomingEvents', {}).then(res => {
        // setLoading(false)
        let data = res.data
        // console.log({res,data});
        if (data) {
          setUpcomingEvents(data);
          setLoading(false);
            // let reverseData = _.orderBy(data, ['type'], ['asc']);
            // setDashboardList(reverseData)
        }
    }).catch((error) => {
      setLoading(false);
        // setLoading(false)
        console.log(error);
    })
    }, [])

    const getBackroundColor = (index) => {
      switch(index % 4){
        case 0: return "#DDFCE6";
        case 1: return "#FBE7F3";
        case 2: return "#DBEAFE";
        case 3: return "#FFEDD6";
      }
    }

    const getTimeFormat = (key) => {
      return key.startTime + " " + key.endTime + " " + key.toDate;
    }

  return (
    <>
          <Card sx={{ minHeight: 'var(--min-height-dashboard-components)', height:'100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardHeader sx={{ p:'var(--padding-all-1)' }}
              avatar={
                <Avatar>
                  <EventSharpIcon fontSize="small" />
                </Avatar>
              }
              title={<Typography variant="h6">Upcoming Events</Typography>}
            // subheader="September 14, 2016"
            />
            <CardContent sx={{ padding:'var(--padding-horizontal-1)'}}>
              <Box id='UpcomingEventsData' className='upcoming-events'>
              {loading ? ( // Render loader if loading state is true
       <Box className='policy-data' sx={{display:'flex',justifyContent:'center',alignItems:'center'}}> <CircularProgress /></Box>
      ) : (
        <>
                {upcomingEvents.map((key, index) => {
                  return (
                    <Card key={index} className="event-card-style" sx={{ backgroundColor: getBackroundColor(index) }}>
                      <List dense={dense}>
                        <ListItem secondaryAction={<ListItemText edge="end" secondary={<Button variant="contained" size="medium" sx={{ borderRadius: 'var(--border-radius-accordian)' }} >JOIN</Button>} />}>
                          <ListItemText primary={<Typography variant="subtitle2">{key.eventName}</Typography>} secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>{getTimeFormat(key)}</Typography>} />
                        </ListItem>
                        <ListItem>
                          <ListItemText secondary={key.description} />
                        </ListItem>
                        <ListItem>
                          <AvatarGroup max={4}>
                            {key.invited?.map((v, i) =>
                              <Avatar key={i} alt={v.name} src={v.src} sx={{ width:'var(--equal-hw2)', height:'var(--equal-hw2)'}} />
                            )}
                          </AvatarGroup>
                        </ListItem>
                      </List>
                    </Card>
                  )
                })}
                </>
      )}
              </Box>
            </CardContent>
          </Card>
    </>
  )
}

export default UpcomingEvent