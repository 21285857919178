import { createSlice } from '@reduxjs/toolkit';

const dialogBoxSlice = createSlice({
  name: 'dialog',
  initialState: {
    isOpen: false,
    id: ''
  },
  reducers: {
    openDialog: (state, actions) => {
      state.isOpen = true;
      state.id = actions.payload;
      // console.log("Sar fat jayega",actions)
    },
    closeDialog: (state, actions) => {  
      state.isOpen = false;
      state.id = actions.payload;
      // console.log(state,state.isOpen,'closeDialog')
    },
  },
});

export const { openDialog, closeDialog } = dialogBoxSlice.actions;

export default dialogBoxSlice.reducer;
