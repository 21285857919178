import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { publish } from '../helper/publisher.js';
import PersonIcon from '@mui/icons-material/Person';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import {Typography,Alert} from '@mui/material';
import Box from '@mui/material/Box';

const InputAutocomplete = ({ value, data, showErrors, label,chainUpdatedData, freeSolo = false, multiple = false, model, modelKey, validation, id, type, option, sx,change = () => {} , ...props}) => { 


  const [textValue, setTextValue] = useState(value);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    // console.log("Ankur Ka Data", {showErrors})
    if(showErrors){
      var error = false;
      var errorMessage = false;
      for( let i = 0; i < validation?.length; i++){
        var res = validation[i](textValue);
        if(res.error){
          error = res.error;
          errorMessage = res.errorMessage;
          break;
        }
      }
      // if(textValue.length)
      setError(error);
      setErrorMessage(errorMessage);
    }
  }, [showErrors]);

  //This function enables the functionality of showing the error once the textField is clicked by checking if the field the empty and if the field contains "required" as validator or not
  const onClickTextValue = (e,c) => {
    let value = e.target.value;
    var error = false;
    var errorMessage = false;

    for( let i = 0; i < validation?.length; i++){
      var res = validation[i](value);
      if(res.error){
        error = res.error;
        errorMessage = res.errorMessage;
        break;
      }
    }
    
    // console.log("inklab", {value,errorMessage,validation})
    setError(error);
    setErrorMessage(errorMessage);
  }

  const onChangeTextValue = (e, value) => {
    // console.log("Important", {value})

    var error = false;
    var errorMessage = false;

    value.map(v => {
      for( let i = 0; i < validation?.length; i++){
        var res = validation[i](v);
        if(res.error){
          error = res.error;
          errorMessage = res.errorMessage;
          break;
        }
      }
    })
    
    setError(error);
    setErrorMessage(errorMessage);

    // console.log("inklab", {value,errorMessage,validation})

    if(error){
      return;
    }

    setTextValue(value);
    model[modelKey].value = value;
    change(value); 

    /*PUBLISHING DATA TO THE TOUCHED HELPER */
    // publish({data: model, id:'touched' + id});
    
    // /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
    // publish({data: model[modelKey], id:'activeTouch' + id});

    // /*PUBLISHING DATA TO THE FILLED HELPER */
    // publish({data: error, id:'filled' + id});
  }

  // , '& .MuiOutlinedInput-notchedOutline': {position: 'absolute', top: 0}
  // , '& .MuiFormLabel-root': {paddingTop: '0.5rem'}
  // , '& .MuiFormControl-root': {maxHeight: '5rem', overflow: 'auto', position: 'relative'} 
  return (
   <>
      <Autocomplete
          key={props.version + modelKey}
          className={error? 'Mui-error': ''}
          sx={{...sx, '& .MuiOutlinedInput-notchedOutline': {borderColor: 'transparent !important'}, '& .MuiFormControl-root': {maxHeight: '5rem', overflow: 'auto', position: 'relative', border: '1px solid rgba(145, 158, 171, 0.32)', borderRadius: '6px'}, '&:hover .MuiFormControl-root': {border: '1px solid #000000'}, '&.Mui-focused .MuiFormControl-root': {border: '1px solid #2065D1'}, '& .MuiFormLabel-root': {paddingTop: '0.5rem'}, marginBottom: 0, '&.Mui-error > div': {borderColor: '#FF4842'}}}
          multiple={multiple}
          onChange={onChangeTextValue}
          error={error}
          id="tags-filled"
          options={data.map((option) => option.label)}
          freeSolo={freeSolo}
          value={textValue}
          renderTags={(value, getTagProps) =>
               value.map((option, index) => (
               <Chip 
                    sx={{backgroundColor:'var(--background-color-chip)'}} 
                    label={option} {...getTagProps({ index })} />
               ))
          }
          renderInput={(params) => (
               <TextField
               onChange={onClickTextValue}
               variant="outlined"
               label={label}
               id=""
               error={error}
              //  helperText={errorMessage ? errorMessage : ''}
               {...params}
               />
          )}
      />
      {/* <Box> */}
        {/* <Typography component="span" sx={{ fontSize: "12px", color: "#0072E5" }}>*Please press enter for creating a tag</Typography> */}
        {error ? <Alert variant="none" sx={{ color: "red", width: '32rem', paddingTop: 0 }} severity="error">
          {errorMessage}
        </Alert> : ""}
      {/* </Box> */}
    </>
  )
}

export default InputAutocomplete