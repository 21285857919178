import { subscribe } from './publisher.js';
import { Observable } from './observableHelper.js';

const filledHelper = (model) => {

    let self = {};
    self.filled = new Observable(false);

    function subscriberFunctionFilled(prop) {

        /* FILTERS OUT THE FIELDS THAT HAS 'REQUIRED' AS THEIR VALIDATOR AND ARE FILLED */
        var filteredArray = model.model.filter(function (e){
            let a = (e.validators[0].name == "required" && (!e.value))
            return a
        }) 
        if(filteredArray.length === 0 && prop === false) {
            self.filled.set(true);
        }
        else{
            self.filled.set(false)
        }
    }

    self.initiation = () => {
        subscribe(subscriberFunctionFilled, 'filled' + model.id);
    }
    return self;
}

export default filledHelper;