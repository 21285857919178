//SAPERATE PROFILE PAGE
import React, { useState } from 'react';
import { Box, Paper, LinearProgress, Typography, useMediaQuery, styled, Stack, IconButton, Grid, Badge,Button, Avatar, Dialog, DialogTitle, DialogContent, DialogActions,
  Close as CloseIcon, KeyboardBackspace as KeyboardBackspaceIcon, UploadFile as UploadFileIcon, Edit as EditIcon, ModeEditSharp as ModeEditSharpIcon, Delete as DeleteIcon
} from '../utils/AllImportsHelper';
import ProfileImage from '../assets/images/Avtaar.png';
import Correct from '../assets/images/correct.jpg'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Cropper from 'react-easy-crop';
import Banner from '../assets/images/banner.png';
import BasicDetails from "../components/profileComponents/PersonalDetails.js";
import EmergencyDetails from "../components/profileComponents/EmergencyDetails.js";
import Achievements from "../components/profileComponents/Achievements.js";
import WorkDetails from "../components/profileComponents/WorkDetails.js";
import ChangeProfile from '../components/dashboardPages/ChangeProfileDialog';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { openDialog, closeDialog } from "../features/dialogBox/dialogBoxSlice";
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import SharedDialog from '../features/dialogBox/dialogBox';
import { setOpen, setImage } from "../features/uploadPic/uploadSlice";
import workDetailsData from "../datafiles/ProfileComponents/workDetailsDummyData.json";
import personalDetailsData from "../datafiles/ProfileComponents/personalDetailsDummyData.json";
import emergencyDetailsData from "../datafiles/ProfileComponents/emergencyDetailsDummyData.json";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
  },
}));

  var tempWD = workDetailsData
  var tempPD = personalDetailsData
  var tempED = emergencyDetailsData

  localStorage.setItem("tempWD", JSON.stringify(tempWD))
  localStorage.setItem("tempPD", JSON.stringify(tempPD))
  localStorage.setItem("tempED", JSON.stringify(tempED))

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
          {children}
          {onClose ? (
              <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                  }}
              >
                  <CloseIcon />
              </IconButton>
          ) : null}
      </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



const Profile = () => {

    const dispatch = useDispatch();
    const dialogContent = (id) => {
      dispatch(openDialog(id));
    //   console.log(openDialog(id))
    }
    const cancel = (id) => {
    dispatch(closeDialog(id));
    }
    // const dispatch = useDispatch();
    const handleEndedDialog = (id) => {
      // setOpenCongrats(true);
      dispatch(openDialog(id));
    }

    const handleCropSave = () => {
        // Dispatch the action to update the user's profile picture
        dispatch(setImage(image));
        // Close the dialog
        // dispatch(openDialog(Id));
        dialogContent('changeProfileDialog')
      };

      const {image} = useSelector(state => state.profile);
    
//   const [gender, setGender] = useState('male');
    
//   const onGenderChange = (e,c) => {
//     console.log({e,c});
//     setGender(e.target.value);
//   }
  
  
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [mode, setMode] = useState(true)
  const [disabled, setDisabled] = useState(false)
  
//   var temp = 1


  const [shape, setShape] = useState('round')
  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels,round) => {
    // console.log(croppedArea, croppedAreaPixels)
  }, [])
  const theme = useTheme();
//   const [progress, setProgress] = React.useState(20);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openCrop, setCropOpen] = React.useState(false);
  const [openCongrates, setCongratesOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  /////////profile update///////////////
  const [img, setImg] = React.useState(ProfileImage)
  const handleImageUpload = (event) => {
      // Get the selected file from the input element
      var file = event.target.files[0];
      // Use the FileReader API to read the file and convert it to a data URL
      var reader = new FileReader();
      reader.onload = function (event) {
          // Get the data URL from the reader result
          var dataUrl = event.target.result;
        //   console.log(dataUrl)
          setImg(dataUrl)
      };
      reader.readAsDataURL(file);
  }
  const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  }));

  function LinearProgressWithLabel(props) {
      return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress variant="determinate" {...props} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">{`${Math.round(
                      20
                  )}%`}</Typography>
              </Box>
          </Box>
      );
  }

  LinearProgressWithLabel.propTypes = {
      value: PropTypes.number.isRequired,
  };

  const handleCongratesOpen = () => {
      setCongratesOpen(true);
  };

  const handleCongratesClose = () => {
      setCongratesOpen(false);
  };
  const cropClickOpen = () => {
      setCropOpen(true);
  };
  const cropClose = () => {
      setCropOpen(false);
  };

  const handleClickOpen = () => {
      setOpen(true);
  };
  const handleClickOpen1 = () => {
      setOpen1(true);
  };
  const handleClose = () => {
      setOpen(false);
  };
  const handleCloseGoBack = () => {
    setOpen1(false);
  };
  const handleCloseConfirm = () => {
    setMode(true)
    setOpen1(false);
  };
  const changeMode = () => {
    setDisabled((disabled == false) ? true : false);
    setMode( (mode == false) ? true : false );
    // console.log(mode);
  }


//   React.useEffect(() => {
//       setProgress((prevProgress) => (prevProgress >= 100 ? 20 : prevProgress + 20));
//   }, []);



  return (
      <>
      <Box>
          <Box>
                <Box sm={12} md={12} lg={12} sx={{justifyContent:'center', minHeight:'50rem', backgroundColor:'var(--background-color-theme)', mb:'1rem'}}>
                    <Box className='top-part'>
                        <img className='banner-profile' src={Banner} alt="banner"/>
                        <Box className='main-profile'>
                            <Box sx={{p:'0rem 1rem'}}>
                            <Badge
                                // onClick={handleClickOpen}
                                sx={{ borderRadius: '100px' }}
                                id="edit-icon"
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Avatar sx={{ height: '1.5rem', width: '1.5rem', marginRight: 'var(--margin-right-1)', border:'1px solid white' }} onClick={handleCropSave}>
                                        <ModeEditSharpIcon sx={{ fontSize: 'small' }} />
                                    </Avatar>
                                }
                            >
                                <Avatar id="profile-icon" alt="Travis Howard" src={image} sx={{ height: 'var(--equal-hw9)', width: 'var(--equal-hw9)' }} />
                            </Badge>
                            <SharedDialog id='changeProfileDialog'>
                                <Box >
                                   <ChangeProfile/>
                                </Box>
                            </SharedDialog>
                            </Box>
                        </Box> 

                        <Box className='text-stack'>
                            <Grid container spacing={0}>
                                <Grid className='text-stack-grid-1' sx={{display: {sm:'block', md:'inline-flex', lg:'inline-flex'} ,flexDirection:'column'}} item xs={8} sm={8} md={9} lg={9}>
                                    <Box>
                                        <Typography variant='h6' fontWeight='var(--font-weight-6)' sx={{lineHeight:'1.5rem'}}>Alinaelf Johnson - THP812</Typography>
                                        <Typography variant='body1' color='black' sx={{lineHeight:'1.5rem'}}>Sr. assistant</Typography>
                                        <Typography variant='body1' color='black' sx={{lineHeight:'1.5rem'}}>alina@thehigherpitch.com</Typography>
                                    </Box>
                                </Grid>
                                
                                <Grid sx={{display: 'flex', justifyContent:'flex-end', alignItems:'flex-end', height:{xs:'auto', sm:'auto', md:'6rem'}}} item xs={4} sm={4} md={3} lg={3}>
                                
                                {(mode == false) ? <>
                                        <Grid container spacing={2} sx={{justifyContent:'flex-end'}}>
                                            <Grid item>
                                            <Button mr='1rem' variant="outlined" onClick={handleClickOpen1}><Typography variant='body1'>CANCEL</Typography></Button></Grid>
                                            <Grid item>
                                            <Button variant="contained"onClick={handleClickOpen1}><Typography variant='body1'>SAVE</Typography></Button></Grid>
                                        </Grid>
                                        <SharedDialog id='confirmChanges'>
                                            <Box >
                                            </Box>
                                        </SharedDialog>
                                        <Dialog
                                            open={open1}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={handleCloseGoBack,handleCloseConfirm}
                                            aria-describedby="alert-dialog-slide-description"
                                        >
                                            <DialogTitle>{"Would you like to save and confirm?"}</DialogTitle>
                                            <DialogContent>
                                            <DialogContentText id="alert-dialog-slide-description">
                                                You've made some changes in your details
                                            </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                            <Button onClick={handleCloseGoBack}>Go Back</Button>
                                            <Button onClick={handleCloseConfirm}>Confirm</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </> : <>
                                        <Button variant="contained" size='medium' startIcon={<EditIcon />} onClick={changeMode}>
                                            <Typography variant='body1'>EDIT PROFILE</Typography>
                                        </Button> 
                                    </>
                                }

                                </Grid>
                            </Grid>
                        </Box> 

                    </Box>



                    <Box sx={{columnWidth:'var(--width-profile-component)', columnCount:'2', mt:{xs:'5rem', sm:'5rem', md:'1rem'}, display: {xs: 'flex', lg: 'block'}, flexDirection: 'column'}}>
                        
                        <BasicDetails readOnly={mode} /> 


                        <EmergencyDetails readOnly={mode} />
                        

                        <Box sx={{order: {xs: -1, lg: 0}}}>
                            <Achievements />
                        </Box>
                        
                        <WorkDetails readOnly={mode} />

                    </Box> 

            </Box>                                             
         </Box>
      </Box>
      </>
  )
}

export default Profile