import React from "react";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

function AppWrapper(props) {
    return (
        <Router>
            <App />
        </Router>
    );
}


export default AppWrapper;
