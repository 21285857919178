import { subscribe } from './publisher.js';
import { Observable } from './observableHelper.js';

const activeTouchHelper = (model, listner) => {

    let self = {};
    self.activeTouch = new Observable(null);

    //This function is running more than once (four times exactly). Reason unknown :(
    function subscriberFunctionActiveTouch(prop) {

        if(!self.activeTouch.get()){

            self.activeTouch.set(prop);   
        }
        self.activeTouch.set(false);
    }
    self.initiation = () => {
        subscribe(subscriberFunctionActiveTouch, 'activeTouch' + model.id);
    }

    return self;
}

export default activeTouchHelper;