import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import {Button} from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

const EpfDetails = ({ setCurrentJSXData, currentJSXData, checkError }) => {

    const [showErrors, setShowErrors] = React.useState(0);

    let epfDetailsModel = [
        { label: 'Previous UAN/PF Number (If available)', type: "textField", size: 'small', value: '' },
        { label: 'Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
        { label: `Father's Name`, type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
        { label: 'Date Of Birth', type: "dateField", value: '', validators:[{ name:'required'}] },
        { label: 'Date Of Joining', type: "dateField", value: '', validators:[{ name:'required'}] },
        { label: `Mobile Number`, type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain number'},{name:'length', max:15}] },
        { label: `ID Card`, type: "textField", size: 'small', value: '', validators:[{ name:'required'}] },
        { label: `Name of Nominee`, type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
        { label: `Designation (Optional)`, type: "textField", size: 'small', value: '' },
      ];
    
    let epfDetailsHelper = new FormHelper({ model: epfDetailsModel, id: 'epfDetails' });

    const [epfDetailsData, setEpfDetailsData] = useState(epfDetailsHelper);

    const valueChangeListner = () => {
        let epfDetailsHelper = epfDetailsData.reduce((prev,curr) => {
        prev[curr.label] = curr.value;
        return prev;
        }, []);

        var error = false;
        var errorMessage = false;
        
        for (let i = 0; i < epfDetailsData.length; i++) {
            for (let j = 0; j < epfDetailsData[i]?.validation?.length; j++) {
                var res = epfDetailsData[i]?.validation[j](epfDetailsData[i].value);
                if (res.error) {
                    error = res.error;
                    errorMessage = res.errorMessage;
                    break;
                }
            }
        }

        if(error){
            setShowErrors(showErrors + 1);
            return;
        }

        // console.log({epfDetailsHelper});
        setCurrentJSXData({...currentJSXData,epfDetails: epfDetailsHelper})
    }

    useEffect(() => {
        console.log("Akas top", {checkError});
        if(checkError){
          valueChangeListner();
        }
      }, [checkError])

    return ( 
    <> 
    <Box
        component="form"
        sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: "var(--width-full)",
        rowGap: "var(--row-gap)",
        columnGap: "var(--column-gap)"
    }}>
        {/* <RenderHelper {...epfDetailsHelper.model} /> */}
        <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {setEpfDetailsData(data);  }} {...epfDetailsHelper.model} />
    </Box>

     </>
      );
   }

   export default EpfDetails