import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpen, setImage } from "../features/uploadPic/uploadSlice";
import ImageUploading from "react-images-uploading";
import Cropper from "react-easy-crop";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { cropImage } from "./cropUtils";
import Avatar from 'react-avatar';
// import { setOpen, setImage } from "../features/uploadPic/uploadSlice";

const ImageUploadingButton = ({ value, onChange, ...props }) => {
  return (
    <ImageUploading value={value} onChange={onChange}>
      {({ onImageUpload, onImageUpdate }) => (
        <Button
          color="primary"
          onClick={value ? onImageUpload : () => onImageUpdate(0)}
          {...props}
        >
          Click to Upload
        </Button>
      )}
    </ImageUploading>
  );
};

const ImageCropper = ({
  open,
  image,
  onComplete,
  containerStyle,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const dispatch = useDispatch();

  const handleCropImage = () => {
    dispatch(setOpen(false));
    // dispatch(setImage(null));
    onComplete(cropImage(image, croppedAreaPixels, console.log));
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Crop Image</DialogTitle>

      <DialogContent>
        <div style={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            cropShape='round'
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>
      </DialogContent>

      <DialogActions>
        {/* <Button
          color="primary"
          onClick={handleCropImage}
        >
          Finish
        </Button> */}

        <Button variant="contained" color="primary"
          onClick={handleCropImage}>Save Profile</Button>
      </DialogActions>
    </Dialog>
  );
};

export default function ImageCrop() {
  const [croppedImage, setCroppedImage] = useState(false);
  const { open, image } = useSelector((state) => state.imageCropper);
  

  const dispatch = useDispatch();
  
  const handleImageComplete = (imagePromise) => {
    imagePromise.then((image) => {
      // console.log({image,imagePromise})
      // setCroppedImage(image);
      dispatch(setImage(image))
    });
  }

  return (
    <div className="App">
      <ImageCropper
        open={open}
        image={image}
        onComplete={handleImageComplete}
        containerStyle={{
          position: "relative",
          width: "100%",
          height: 300,
          background: "#333"
        }}
        />
    </div>
  );
  // {/* <ImageUploadingButton
  //   value={image}
  //   onChange={(newImage) => {
  //     dispatch(setImage(newImage));
  //     dispatch(setOpen(true));
  //   }}
  // /> */}
  // {/* {croppedImage && <Avatar style={{ height: '10rem', width: '10rem' }} src={croppedImage} alt="blab" />} */}
}
