import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Button } from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

let detaisData = [];
const FamilyMemberDetails = ({ showErrors, setShowErrors, index }) => {

  let familyDetailsModel = [
    { label: 'Family Member Name', type: "textField", size: 'small', value: '', validators: [{ name: 'required' }, { name: 'pattern', regex: /^[A-Za-z\s]+$/, errorMessage: 'Field contains invalid character(s)' }] },
    { label: 'Relation', type: "singleSelect", value: '', validators: [{ name: 'required' }], data: [{ value: '', label: '' }, { value: 'father', label: 'Father' }, { value: 'mother', label: 'Mother' }, { value: 'sibling', label: 'Sibling' }, { value: 'spouse', label: 'Spouse' }, { value: 'son', label: 'Son' }, { value: 'daughter', label: 'Daughter' }] },
    // , {name:'pattern', regex:/^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ,errorMessage:'correct format: mm/dd/yyyy'}
    { label: 'Date Of Birth', type: "dateField", value: '', validators: [{ name: 'required' }] },
    { label: 'Dependent (Yes/No)', type: "singleSelect", validators: [{ name: 'required' }], value: '', data: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }] },
    { label: 'Whether Employed', type: "singleSelect", validators: [{ name: 'required' }], value: '', data: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }] },
    { label: 'Employee Code (Optional)', type: "textField", validators: [{ name: '' }], size: 'small', value: '' },
  ];

  let familyDetailsHelper = new FormHelper({ model: familyDetailsModel, id: 'familyDetails' });

  const [familyDetailsHelperData, setFamilyDetailsHelperData] = useState(familyDetailsModel);

  const valueChangeListner = () => {
    let familyDetailsHelper = familyDetailsHelperData.reduce((prev, curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, []);

    var error = false;
    var errorMessage = false;

    for (let i = 0; i < familyDetailsHelperData.length; i++) {
      for (let j = 0; j < familyDetailsHelperData[i]?.validation?.length; j++) {
        var res = familyDetailsHelperData[i]?.validation[j](familyDetailsHelperData[i].value);
        if (res.error) {
          error = res.error;
          errorMessage = res.errorMessage;
          break;
        }
      }
    }

    if (error) {
      setShowErrors(showErrors + 1);
      // setDataInIndex({error: true});
      detaisData[index] = {error: true};
      return;
    }

    console.log({ familyDetailsHelper });
    // setDataInIndex(familyDetailsHelper);
    detaisData[index] = familyDetailsHelper;
  }

  useEffect(() => {
    valueChangeListner();
  }, [showErrors])

  return (<>
    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => { setFamilyDetailsHelperData(data); }} {...familyDetailsHelper.model} />
  </>)
}

const FamilyDetails = ({ setCurrentJSXData, currentJSXData, checkError }) => {

  const [showErrors, setShowErrors] = React.useState(0);
  const [componentDetails, setComponentDetails] = useState({familyDetails: []});

  //  const familyMemberDetails = { 'familyMemberName': '', 'relation': '', 'dateOfBirth': '', 'dependent': '', 'whetherEmployed': '', 'employeeCode': '' };
  const [membersFamily, setMembersFamily] = useState([new Date().getTime()]);

  //  const [isTouched, setIsTouched] = React.useState(false);
  //  const [isFilled, setIsFilled] = React.useState(false);

  //  familyDetailsHelper.isTouched.touched.subscribe(() => {
  //       setIsTouched(familyDetailsHelper.isTouched.touched.get());
  // })

  // familyDetailsHelper.isFilled.filled.subscribe(() => {
  //   setIsFilled(familyDetailsHelper.isFilled.filled.get());
  // })

  const addNewFamily = () => {
    // setMembersFamily([...membersFamily, new FormHelper({ model: familyDetailsModel, id: new Date().getTime() })]);
    setMembersFamily([...membersFamily, new Date().getTime()]);
    // familyDetailsHelper.isFilled.filled.set(false)
    // setIsFilled(false)
  }

  const removeFamily = (i) => {
    let familyMembers = [...membersFamily];
    familyMembers.splice(i, 1);
    if (!familyMembers.length)
      familyMembers.push(new Date().getTime());
    setMembersFamily(familyMembers);
  }

  // const getValueFromChildren = (data, index) => {
  //   let currentData = [...componentDetails.familyDetails];
  //   currentData[index] = data;
  //   console.log("Suraj the great", {currentData});
  //   setComponentDetails({familyDetails: currentData});
  // }

  const valueChangeListner = () => {
    setShowErrors(showErrors + 1);
    
    setTimeout(() => {
      // console.log("Suraj", {detaisData});
      let checkError = detaisData.reduce((prev,curr) => {
        if(curr.error){
          prev = true;
        }
        return prev;
      }, false);
      console.log("Suraj", {detaisData,checkError})
      if(!checkError){
        setCurrentJSXData({...currentJSXData, familyDetails: detaisData})
      }
    }, 100)
  }

  useEffect(() => {
    console.log("Akas top", { checkError });
    if (checkError) {
      valueChangeListner();
    }
  }, [checkError])

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
        {membersFamily.map((v, i) => {
          return <Box key={i} component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
            <Box sx={{ gridColumn: 'span 3' }} id={v}>
              <Grid container spacing={2}>
                <Grid sx={{ display: 'flex', alignContent: 'center', flexWrap: 'wrap' }} item xs={10}>
                  <Typography variant='h8' fontWeight={800}>Member {i + 1}</Typography>
                </Grid>

                <Grid item xs={2} className="dashboardStyle">
                  <IconButton aria-label="Example" onClick={() => removeFamily(i)}  >
                    {/* disabled={!isTouched} */}
                    <DeleteIcon icon={faEllipsisV} size="small" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            {/* <RenderHelper {...familyDetailsHelper.model}/> */}
            <FamilyMemberDetails key={v} showErrors={showErrors} setShowErrors={setShowErrors} index={i} />
          </Box>
        })}

        <Box sx={{ border: "var(--border-add-btn)", borderRadius: "var(--border-radius-add-btn)", display: "flex", justifyContent: "center" }}>
          <Button sx={{ minWidth: "var(--min-width-15)" }} startIcon={<AddIcon />} onClick={addNewFamily} >
            {/* disabled={!isFilled} */}
            Add Member
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default FamilyDetails