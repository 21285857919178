
import './App.css';
import './assets/styles/Customized.css';
import './assets/styles/Theme.css';
import ProfileImage from './assets/images/Avtaar.png';
import { setProfileImage } from './features/commonDataSlice/commonSlice';
import { purple, red } from '@mui/material/colors';
import Sidebar from './layouts/sidebar/Sidebar';
import {  createTheme } from "@mui/material/styles";
import { useEffect, useState } from 'react';
import { checkCallback, removeUserToken } from './_helper/secureToken';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PrivateComponent } from './components/PrivateComponent';
import Logout from './pages/Logout';

const windowObject = window;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function App() {
  const [component, setComponent] = useState([]);
  let navigate = useNavigate()
  useEffect(() => {
    setComponent(PrivateComponent());
  }, []);
  let query = useQuery();
  let authToken = query.get("token");
  let appToken = query.get("app");
  let authType = query.get("authType");
  let callback = query.get("callback");
  let ssoToken = query.get("ssoToken");
  let location = useLocation();
  let loginauthenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/login?callback=${window.location.href}`;
  // let loginauthenticationUrl = "localhost:3000"
  // let loginauthenticationUrl = "https://www.google.com"
  return (  
    <Routes>
      {component.map((u, i) =>
        checkCallback(authToken, appToken, authType, callback, ssoToken) ?
          <Route key={i} path={u.url} element={u.page} />
          :
          (window.location.assign(loginauthenticationUrl))
      )}
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;