import { subscribe } from './publisher.js';
import { Observable } from './observableHelper.js';

const touchedHelper = (model) => {

    let self = {};
    self.touched = new Observable(false);

    function subscriberFunctionTouched() {
        if(!self.touched.get()){
            self.touched.set(true);
        }   
    }

    self.initiation = () => {
        subscribe(subscriberFunctionTouched, 'touched' + model.id);
    }

    return self;
}

export default touchedHelper;