import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Button } from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

let detaisData = [];
const FamilyMemberDetails = ({ showErrors, setShowErrors, index }) => {

  let familyDetailsModel = [
    { label: 'Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[A-Za-z\s]+$/, errorMessage:'Field contains invalid character(s)' }]},
    { label: 'Gender', type: "singleSelect", value: '', validators: [{name:'required'}] , data: [{ value: '', label: '' }, { value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }]},
    { label: 'Date Of Birth', type: "dateField", validators: [{name:'required'}] , value: '' },
    { label: 'Dependent (Yes/No)', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }] },
    { label: 'Relation', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: '', label: '' }, { value: 'father', label: 'Father' }, { value: 'mother', label: 'Mother' }, { value: 'sibling', label: 'Sibling' }, { value: 'spouse', label: 'Spouse' }, { value: 'son', label: 'Son' }, { value: 'daughter', label: 'Daughter' }] },
    { label: 'Health Conditions (Optional)', validators: [{name:''}] , type: "textField", size: 'small', value: '' },
    { label: 'Address', type: "textField", validators: [{name:'required'}] , size: 'small', value: '', variant: "outlined", sx: { gridColumn: 'span 2' },multiline: true },
    { label: 'Country', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'bharat', label: 'Bharat' }, { value: 'china', label: 'China' }, { value: 'america', label: 'America' }, { value: 'russia', label: 'Russia' }] },
    { label: 'State', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'punjab', label: 'Punjab' }, { value: 'kerala', label: 'Kerala' }] },
    { label: 'City', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'delhi', label: 'Delhi' }, { value: 'gaziyabad', label: 'Ghaziabad' }, { value: 'noida', label: 'Noida' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
    { label: 'Pincode', type: "textField", validators: [{name:'required'}] , size: 'small', value: '' }
  ];

  let familyDetailsHelper = new FormHelper({ model: familyDetailsModel, id: 'familyDetails' });

  const [familyDetailsHelperData, setFamilyDetailsHelperData] = useState(familyDetailsModel);

  const valueChangeListner = () => {
    let familyDetailsHelper = familyDetailsHelperData.reduce((prev,curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, []);

    var error = false;
    var errorMessage = false;
    
    for (let i = 0; i < familyDetailsHelperData.length; i++) {
        for (let j = 0; j < familyDetailsHelperData[i]?.validation?.length; j++) {
            var res = familyDetailsHelperData[i]?.validation[j](familyDetailsHelperData[i].value);
            if (res.error) {
                error = res.error;
                errorMessage = res.errorMessage;
                break;
            }
        }
    }

    // console.log({familyDetailsHelper});

    if (error) {
      setShowErrors(showErrors + 1);
      // setDataInIndex({error: true});
      detaisData[index] = {error: true};
      return;
    }

    // console.log({ familyDetailsHelper });
    // setDataInIndex(familyDetailsHelper);
    detaisData[index] = familyDetailsHelper;
  }

  
  useEffect(() => {
    valueChangeListner();
  }, [showErrors])

  return(<>
    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {setFamilyDetailsHelperData(data);  }} {...familyDetailsHelper.model} />
  </>)
}


const MedicalDetails = ({ setCurrentJSXData, currentJSXData, checkError }) => {

  // let medicalInsuranceModel = [
  //   { label: 'Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[A-Za-z\s]+$/, errorMessage:'Field contains invalid character(s)' }]},
  //   { label: 'Gender', type: "singleSelect", value: '', validators: [{name:'required'}] , data: [{ value: '', label: '' }, { value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }]},
  //   { label: 'Date Of Birth', type: "dateField", validators: [{name:'required'}] , value: '' },
  //   { label: 'Dependent (Yes/No)', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }] },
  //   { label: 'Relation', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: '', label: '' }, { value: 'father', label: 'Father' }, { value: 'mother', label: 'Mother' }, { value: 'sibling', label: 'Sibling' }, { value: 'spouse', label: 'Spouse' }, { value: 'son', label: 'Son' }, { value: 'daughter', label: 'Daughter' }] },
  //   { label: 'Health Conditions (Optional)', validators: [{name:''}] , type: "textField", size: 'small', value: '' },
  //   { label: 'Address', type: "textField", validators: [{name:'required'}] , size: 'small', value: '', variant: "outlined", sx: { gridColumn: 'span 2' },multiline: true },
  //   { label: 'Country', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'bharat', label: 'Bharat' }, { value: 'china', label: 'China' }, { value: 'america', label: 'America' }, { value: 'russia', label: 'Russia' }] },
  //   { label: 'State', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'punjab', label: 'Punjab' }, { value: 'kerala', label: 'Kerala' }] },
  //   { label: 'City', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'delhi', label: 'Delhi' }, { value: 'gaziyabad', label: 'Ghaziabad' }, { value: 'noida', label: 'Noida' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
  //   { label: 'Pincode', type: "textField", validators: [{name:'required'}] , size: 'small', value: '' }
  // ];

  // let medicalInsuranceHelper = new FormHelper({ model: medicalInsuranceModel, id: 'medicalInsurance' });
     const [showErrors, setShowErrors] = React.useState(0);
     const [dateOfBirth, setDateOfBirth] = React.useState(dayjs('2022-04-07'));
     const [dateOfBirthDept, setDateOfBirthDept] = React.useState(dayjs('2022-04-07'));
     const [dateOfBirthEPF, setDateOfBirthEPF] = React.useState(dayjs('2022-04-07'));
     const [medicalInsurances, setMedicalInsurances] = React.useState([1]);
    //  const [isTouched, setIsTouched] = React.useState(false);
    //  const [isFilled, setIsFilled] = React.useState(false);

//      medicalInsuranceHelper.isTouched.touched.subscribe(() => {
//    setIsTouched(true);
//  })

//  medicalInsuranceHelper.isFilled.filled.subscribe(() => {
//      setIsFilled(medicalInsuranceHelper.isFilled.filled.get())
//    })

     const addNewMedicalInsurances = () => {
          setMedicalInsurances([...medicalInsurances, 1 ]);
          // setIsFilled(false)
        }

     const removeInsurance = (i) => {
     let insuranceMembers = [...medicalInsurances];
     insuranceMembers.splice(i, 1);
     if (!insuranceMembers.length)
          insuranceMembers.push(1);
     setMedicalInsurances(insuranceMembers);
    //  setIsFilled(true)
     }

     
   const valueChangeListner = () => {
    setShowErrors(showErrors + 1);
    
    setTimeout(() => {
      // console.log("Suraj", {detaisData});
      let checkError = detaisData.reduce((prev,curr) => {
        if(curr.error){
          prev = true;
        }
        return prev;
      }, false);
      console.log("Suraj", {detaisData,checkError})
      if(!checkError){
        setCurrentJSXData({...currentJSXData, medicalDetails: detaisData})
      }
    }, 100)
  }

  useEffect(() => {
    console.log("Akas top", { checkError });
    if (checkError) {
      valueChangeListner();
    }
  }, [checkError])

     return(

          <>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
               {medicalInsurances.map((v, i) => {
                 return <Box key={'medicalInsurances' + i} component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                   <Box sx={{ gridColumn: 'span 3' }} id={v + "jds"}>
                   <Grid container spacing={2} display='flex'>
                         <Grid sx={{display:'flex', alignContent:'center', flexWrap:'wrap'}} item xs={10}>
                         <Typography variant='h8' fontWeight={800}>Person {i+1}</Typography>
                         </Grid>
                         <Grid item xs={2} className="dashboardStyle">
                             <IconButton aria-label="Example" onClick={() => removeInsurance(i)} >
                             {/* disabled={!isTouched} */}
                                     <DeleteIcon icon={faEllipsisV}  size="small" />
                             </IconButton>
                         </Grid>
                   </Grid>
                   </Box>
                   {/* <RenderHelper {...medicalInsuranceHelper.model}/> */}
                   <FamilyMemberDetails key={v} showErrors={showErrors} setShowErrors={setShowErrors} index={i} />

                 </Box>
               })}
               <Box sx={{ border: "var(--border-add-btn)", borderRadius: "var(--border-radius-add-btn)", display: "flex", justifyContent: "center" }}>
                 <Button sx={{ minWidth: "var(--min-width-15)" }} startIcon={<AddIcon />} onClick={addNewMedicalInsurances} >
                 {/* disabled={!isFilled} */}
                   Add Person
                 </Button>
               </Box>
             </Box>

          </>

     );
}

export default MedicalDetails