import React, { useEffect, useRef } from 'react'
import EmployeeTree from '../components/Teams/EmployeeTree';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { getOrganization } from '../Service/Organisation/organization.service';
import { getDepartment } from '../Service/Organisation/department.service';
import { getDepartmentUser } from '../Service/Organisation/departmentUser.service';
import { styled } from "@mui/material/styles";
import SkeletonTree from './SkeletonTree';


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "rgba(0, 0, 0, 0.85);",
  },
  fontWeight: '600', fontSize: '16px', textTransform: 'capitalize'
});

const Teams = () => {
  const [value, setValue] = React.useState('0');
  const [currentModule, setCurrentModule] = React.useState('employeeTree');
  const [employeeTreeData, setEmployeeTreeData] = React.useState([]);
  const [deptTreeData, setDeptTreeData] = React.useState([]);
  const [departmentTreeData, setDepartmentTreeData] = React.useState([]);
  const [showSkeleton , setShowSkeleton] = React.useState(false);
  const deptPrefix = 'dept';

  useEffect(() => {
    org();
    // deptUser(userDeptId);
  }, []);
  
  {/* Org chart Api */ }
  const org = () => {
    getOrganization().then(function (response) {
      setEmployeeTreeData(response.data.data);
      dept();
    }).catch(function (error) {
      console.log(error);
      // console.log(JSON.stringify(error));
    });
  }

  {/* Department Api */ }
  const dept = () => {
    getDepartment().then(function (response) {
      let deptTree = response.data.data.map(v => { v.userId = deptPrefix + v.id; return v; });
      setDeptTreeData(deptTree);
      // setDepartmentTreeData(deptTree);
      // console.log({dept: response.data.data});
    }).catch(function (error) {
      console.log(error);
      // console.log(JSON.stringify(error));
    });
  }
  
  useEffect(() => {
    deptUser('32');
  },[deptTreeData]);

  useEffect(() => {
      setShowSkeleton(false);
      if(window.innerWidth > 599){
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
  }, [departmentTreeData])

  {/* Department User Api */ }
  const deptUser = (id) => {
    // console.log(id)/
    // console.log(id.substring(4))
    getDepartmentUser({ departmentCode: id }).then(function (response) {
      let departMent = [...deptTreeData];
      // console.log({departmentTreeData,employee: response.data.data})
      departMent = departMent.map(dept => {
        // console.log(dept.id == id,dept.id , id)
        if (dept.id == id) {
          dept.employee = response.data.data;
        }
        return dept;
      })
      // console.log({ departMent });
      setDepartmentTreeData(departMent);
    }).catch(function (error) {
      console.log(error);
      // console.log(JSON.stringify(error));
    });
  }

  const currentDeptId = (id) => {
    if (currentModule === 'departmentTree' && typeof id == "string" && id.indexOf(deptPrefix) != -1){
      deptUser(id.substring(4));
      setShowSkeleton(true);
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    // <Box sx={{ background: 'lightgrey' }} id='orgChart'>
    // {/* #F8F8F8 */}
    //   <Box sx={{ m: 1, pt: 2, pb: .5 }}>
    //     <Typography variant="h6" fontWeight={500} gutterBottom>
    //       Teams
    //     </Typography>
    //   </Box>
      <>
      <Card sx={{ background: '#fff', borderRadius: '12px' }}>
      <Box sx={{ m:2 }}>
        <Typography variant="h6" fontWeight={500} gutterBottom>
           Teams
         </Typography>
      </Box>

        <TabContext value={value}>

          <Box sx={{ borderBottom: 1, borderColor: 'divider', mx:2 }}>
            <TabList onChange={handleChange} aria-label="basic TabList example" variant="fixed" scrollButtons allowScrollButtonsMobile>
              <StyledTab label="Employee Tree"  {...a11yProps(0)} onClick={() => setCurrentModule('employeeTree')} value="0" />
              <StyledTab label="department Tree"  {...a11yProps(1)} onClick={() => setCurrentModule('departmentTree')} value="1" />
            </TabList>
          </Box>

          <Box sx={{ mt: 4 }}>
              {showSkeleton && <>
                <SkeletonTree />
              </>}
            {/* {showSkeleton ? <>
                Skeleton
              </> : 
              <> */}
                {/* two types structure and expander with Tabs */}
    
                <TabPanel value='0' index={0} sx={{ padding: 0 }}>
                  <Box sx={(currentModule === 'employeeTree' || !showSkeleton) ? {} : { display: 'none' }}>
                    <EmployeeTree treeData={employeeTreeData} currentCandidate={1} type={'structure'} currentDeptId={currentDeptId} />
                  </Box>
                </TabPanel>
                {/* </> : <> */}
                <TabPanel value='1' index={1} sx={{ padding: 0 }}>
                  <Box sx={(currentModule === 'employeeTree' || showSkeleton) ? { display: 'none' } : {}}>
                    <EmployeeTree treeData={departmentTreeData} currentCandidate={139} type={'expander'} currentDeptId={currentDeptId} />
                  </Box>
                </TabPanel>
                {/* </>} */}

              {/* </> */}
            {/* } */}
          </Box>
        </TabContext>
      </Card>
      </>
    // </Box>
  )
}

export default Teams