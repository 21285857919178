import { subscribe } from "./publisher";

const getterHelper = (model, id) => {

  let self = {};

  self.model = {};

  // self.subscriberFunction = (data) => {
  //   // setModelRender(data);
  //   console.log("Jiren is here with Goku", {model, data})
  //   // model.model = data;
  //   // setValueUpdated(false);
  // }

  // subscribe(self.subscriberFunction, id);

  self.get = () => {
    self.model = {};
    
    model.model.map((m) => {
        self.model[m.label] = m.value;
    })
    // console.log("Chopper", {whole: model.model, odel: self.model})
    return self.model;
  };
    
  return self;
}

export default getterHelper;