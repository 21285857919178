// import { createSlice } from '@reduxjs/toolkit';

// const snackbar = createSlice({
//     name: 'snakebar',
//     // initialState: {
//     //     snakebar: { dataLoad: false, message: "" },
//     // },
//     // reducers: {
//     //     setSnackBar: (state, action) => {
//     //         state.snakebar = action.payload;
//     //     },
//     // },
//     initialState: {
//       isOpen: false,
//       message:'',
//       severity:'',
//       id: ''
//     },
//     reducers: {
//         setSnackBar: (state, actions) => {
//         state.isOpen = true;
//         state.message = actions.payload.message;
//         state.severity = actions.payload.severity;
//         //console.log(actions)
//       },
//       closeSnackbar: (state, actions) => {
//         state.isOpen = false;
//       //   state.message = actions.payload;
//       //   state.id = actions.payload;
//       },
//     },
// });

// export const { setSnackBar } = snakebar.actions;
// export default snakebar.reducer;


import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    isOpen: false,
    message:'',
    severity:'',
    id: ''
  },
  reducers: {
    openSnackbar: (state, actions) => {
      state.isOpen = true;
      state.message = actions.payload.message;
      state.severity = actions.payload.severity;
      // console.log(actions)
    },
    closeSnackbar: (state, actions) => {
      state.isOpen = false;
    //   state.message = actions.payload;
    //   state.id = actions.payload;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
