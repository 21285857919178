// import React, { useRef } from 'react'
import React, { useEffect, useState, useRef } from "react";
import '../assets/styles/Theme.css';
import Footer from '../layouts/footer/Footer';
import Angel from '../assets/images/angel.svg';
import VideoThunbnailImg from '../assets/images/videoThunbnailImg.png'
import Coin from '../assets/images/gold-coin.png';
import Typography from '@mui/material/Typography';
import Card from '@mui/joy/Card';
import { Box, Button, Grid, PlayArrow as PlayArrowIcon } from '../utils/AllImportsHelper';
import introVideo from '../assets/video/companyVideo.mp4';
import background from '../assets/images/Frame.png';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
// import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// import DynamicDrawer from '../features/drawers/DynamicDrawer';
import { GamificationDrawer } from '../components/drawerBody/GamificationDrawer';
// import { useState } from 'react';
import { Link } from "react-router-dom";
import Fab from '@mui/material/Fab'
import PauseIcon from '@mui/icons-material/Pause';
import SharedDrawer from '../features/drawers/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from "../features/drawers/drawerSlice";
import { openDialog, closeDialog } from "../features/dialogBox/dialogBoxSlice";
// import DialogBox from '../features/dialogs/dialogBox';
import SharedDialog from '../features/dialogBox/dialogBox';
import { setUserInfo } from '../features/userInfoStore/userSlice';
import { openSnackbar } from "../features/snakebar/snackbarSlice";
import ApiService from "../utils/ApiService";


const Welcome = () => {
  const dispatch = useDispatch();
  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
    // console.log(openDialog(id))
  }
  const closeDialogPublish = (id) => {
  dispatch(closeDialog(id));
  }
  // const dispatch = useDispatch();
  // const openDialogPublish = (id) => {
  //   // setOpenCongrats(true);
  //   dispatch(openDialog(id));
  // }

  const [videoUpdate, setVideoUpdate] = useState([]);
  const [category, setCategory] = useState([]);
  const [videoListByCategory, setVideoListByCategory] = useState([]);
  // console.log(videoListByCategory)
  const [currentVideo, setCurrentVideo] = useState('');
  const [currentThumb, setCurrentThumb] = useState('');
  const [currectDesc, setCurrectDesc] = useState('');
  const [currentReleasedDate, setCurrentReleasedDate] = useState('');
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentId, setCurrentId] = useState('');
  const [getWelcomVideo, setWelcomVideo] = useState('');

  useEffect(() => {
    const params = {
      related: ['streamCategory','streamSubcategory']
    }
    ApiService.get('videoUploadService', params).then(res => {
      // setLoading(false)
      let data = res.data.data
      // console.log({res,data});
      if (data) {
        setVideoUpdate(data);
          // let reverseData = _.orderBy(data, ['type'], ['asc']);
          // setDashboardList(reverseData)
      }
    }).catch((error) => {
        // setLoading(false)
        console.log(error);
    })

    const paramsCat = {
      related: ['streamSubcategory', 'videoServices']
    }
    ApiService.get('streamCategory', paramsCat).then(res => {
      // setLoading(false)
      let data = res.data.data
      // console.log({res,data});
      if (data) {
        setCategory(data);
          // let reverseData = _.orderBy(data, ['type'], ['asc']);
          // setDashboardList(reverseData)
      }
    }).catch((error) => {
        // setLoading(false)
        console.log(error);
    })
  }, [])

  useEffect(() => {
    if(videoUpdate.length && category.length){
      let videoListByCategory = category.map(cat => {
        cat.videos = videoUpdate.filter(video => video.StreamSubCategoryId == cat.streamSubcategory[0]?.id);
        return cat;
      })

      if(currentVideo == ''){
        setCurrentVideo(videoListByCategory?.[0]?.videos?.[0]?.videoUrl)
        setCurrentReleasedDate(videoListByCategory?.[0]?.videos?.[0]?.created_at)
        setCurrectDesc(videoListByCategory?.[0]?.videos?.[0]?.description)
        setCurrentTitle(videoListByCategory?.[0]?.videos?.[0]?.name)
        setCurrentId(videoListByCategory?.[0]?.videos?.[0]?.id)
      }
      setVideoListByCategory(videoListByCategory);
      const lastVideoIndex = videoListByCategory?.[0]?.videos.length - 1;
const lastVideo = videoListByCategory?.[0]?.videos?.[lastVideoIndex];
      // console.log("videoListByCategory",{videoListByCategory,currentVideo, video: videoListByCategory?.[0]?.videos, lastVideo});
     setWelcomVideo(lastVideo)
    }
  }, [videoUpdate, category])


  const [userName, setUserName] = useState("")
  useEffect(() => {
    ApiService.get('userInfo', { toolsUser: 'null' }).then(res => {
        let response = res.data.data
        setUserName(res.data.data)
        if (response) {
            dispatch(setUserInfo(response));
        }
    }).catch((error) => {
        console.log(error);
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }))
    })
},[])

  // const closeDialogPublish = (id) => {
  //   // setOpenCongrats(false);
  //   dispatch(closeDialog(id));
  // };

  // const dispatch = useDispatch();
    const openDrawerPublish = (id) => {
      dispatch(openDrawer(id));
    }
    // const closeDialogPublish = (id) => {
    // dispatch(closeDrawer(id));

    const centerText = {
      textAlign: "center",
    };
  // const [open, setOpen] = React.useState(false);
  // const [openCongrats, setOpenCongrats] = React.useState(false);
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const navigate = useNavigate()


  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const [openGamification, gamificationOpenDrawer] = useState(false);
  // const gamificationDrawer = () => {
  //   gamificationOpenDrawer(!openGamification);
  // };
  // const gamificationData = {
  //   Body: GamificationDrawer,
  //   onClick: gamificationDrawer,
  //   open: openGamification
  // };

  // const handleEnded = () => {
  //   setOpenCongrats(true);
  // }


  // const closeDialogPublish = () => {
  //   setOpenCongrats(false);
  // };

  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const videoRef = useRef();  

  function handleMouseEnter() {
    setShowPlayButton(true);
  }

  function handleMouseLeave() {
    setShowPlayButton(false);
  }

  function handlePlayClick() {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
     else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  }

  return (

    <div style={{
      backgroundImage: `url(${background})`,
      backgroundSize: '100% 90vh',
      backgroundPosition: 'top center',
      height: '100vh',
      backgroundRepeat: 'no-repeat',
    }}>
      <Box sx={{minHeight:'95vh'}}>
      <Box style={{ color: '#ffff', paddingTop: '2rem' }}>
        {/* <div style={bodyText}> */}
        <Box>
          <Box>
            <Typography textAlign='center' fontSize='2.5rem' fontWeight='var(--font-weight-7)'>
              Welcome {userName?.userName}!
            </Typography>
          </Box>
          <Box>
            <Typography textAlign='center' fontSize='2.5rem' fontWeight='var(--font-weight-3)' mb='.5rem'>
              Watch & earn
            </Typography>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={0} md={1} lg={3}>
            </Grid>
            <Grid item xs={12} md={10} lg={6}>
            {/* Removed sx={{paddingRight: {xs: '24px', md: '0px'}}} */}
              {/* <Box onClick={gamificationDrawer} sx={{ cursor: 'pointer' }}> */}
              <Box onClick={() => openDrawerPublish('gamification')} sx={{ cursor: 'pointer', px:'1rem' }}>
                <Typography textAlign='center' variant="body2" fontWeight="var(--font-weight-3)">
                  This Video is about the Organization goals & achievements, etc. Also about the tinggit platform where you can fill and share all the required detail like personal, professional info etc. with <span className='game-link'>Gamification</span> where you can complete your task and earn coins and purchase any training or courses by earned coins.
                </Typography>
              </Box>
              <SharedDrawer id='gamification'>
                <Box sx={{position: 'relative', minWidth: {sm:'1', md:'26em'}, height: '100vh'}}>{/*Previously, minWidth: '40em' */}
                    <Box>
                       <GamificationDrawer parentId="gamification"/>
                    </Box>
                </Box>
              </SharedDrawer>

            </Grid>
          </Grid>
        </Box>
      </Box>
      <div>
        <Grid container spacing={2} sx={{justifyContent:'center'}}>

          <Grid item xs={11} md={7} lg={6} sx={{pl:{xs:'0rem', md:'4rem', lg:'4rem'}}}>
            <Box
              sx={{ width: '100%' }}>
              <Box
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <Card sx={{p:'1rem 0rem 0rem 0rem', minWidth: 300, flexGrow: 1 }}>
                  <video
                    controls
                    ref={videoRef}
                    src={getWelcomVideo.videoUrl}
                    // poster={getWelcomVideo.thumbNailUrl}
                    onEnded={() => openDialogPublish('congratulationsDialog')}
                    style={{ borderRadius: '14px',width: '100%' }}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}

                  />
                  {showPlayButton && (

                    <Fab onClick={handlePlayClick} sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      opacity: .8,
                      transform: 'translate(-50%, -50%)'
                    }} color="primary" aria-label="play arrow">
                      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </Fab>
                  )}
                </Card>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={11} md={1} lg={.5}>
            {/* <div style={{ height: "100%", display: "flex", alignItems: "end" }}>
              <Button sx={{ transition: 'transform 1s', opacity: .5, ':hover': { transform: 'scale(1.2)', background: 'white', boxShadow: '1px 1px 5px 1px #8A8A8A' } }} onClick={() => {dispatch2(openDialog({title: 'title',message: 'message',button:'save'}))}} endIcon={<KeyboardArrowRightSharpIcon />}>SKIP</Button>
              <DialogBox 
              title="Are you sure?" 
              message='This Video is about the Organization goals & achievements, etc. Also about the tinggit platform where you'
              button='saveddd'>
              </DialogBox>
            </div> */}

            <Box sx={{ height: "100%", display: "flex", alignItems: "end", justifyContent: {xs: 'end', lg: 'start'}, padding: {xs: '0rem', md:'0rem 0rem .35rem 0rem'} }}>
              <Button size="small" sx={{ transition: 'transform 1s', opacity: .5, ':hover': { transform: 'scale(1.2)', background: 'white', boxShadow: '1px 1px 5px 1px #8A8A8A' } }} onClick={() => openDialogPublish('choiceDialog')} endIcon={<KeyboardArrowRightSharpIcon />}>SKIP</Button>
            </Box>
            <SharedDialog id='choiceDialog'>
              <Box >
                  <DialogTitle id="dialog-title" sx={{ minWidth:{xs:'0',sm:'var(--minWidth-welcome-dialog)'} }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={9}>
                          <Typography variant="h6">Are you sure?</Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <img className='dialog-top-right-logo' src={Angel} alt="React Logo" />
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText color="black">
                      If skip, you will lose your <span><b>250</b></span><span className='icon-position'><img src={Coin} alt="React Logo" /></span> coin
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{pb:'1rem', pr:'1rem'}}>
                    <Link to="/introduction" className='MuiLink-underlineNone'><Button autoFocus>
                      Skip
                      {/* onClick={handleClose} */}
                    </Button>
                    </Link>
                    <Button variant="contained" onClick={() => closeDialogPublish('choiceDialog')} autoFocus>
                      Cancel
                    </Button>
                  </DialogActions>
              </Box>
            </SharedDialog>
          </Grid>
        </Grid>
      </div>
      </Box>
      {/* <div> */}
        <Footer />
      {/* </div> */}
{/* 
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title" sx={{ minWidth: '20rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <div>
                {"Are you sure?"}
              </div>
            </Grid>
            <Grid item xs={3}>
              <img className='dialog-top-right-logo' src={Angel} alt="React Logo" />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            if skip,you can lose your <span><b>250</b></span><span className='icon-position'><img src={Coin} alt="React Logo" /></span> coin
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/introduction" className='MuiLink-underlineNone'><Button autoFocus onClick={handleClose}>
            Skip
          </Button>
          </Link>

          <Button variant="contained" onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}
      <SharedDialog id='congratulationsDialog'>
              <Box sx={{}}>
              <DialogTitle id="dialog-title" sx={{ minWidth: '15rem' }}>
                  <Grid container spacing={2} sx={{ width: 'unset', pt:'4rem' }}>
                    <Grid item xs={12}>
                      <Box className='items-center'>
                        <img src={Coin} alt="React Logo" />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <div className='typography-heading-2x items-center'>
                        Congratulations!
                      </div>
                    </Grid>
                  </Grid>
              </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className='items-center'>
                <Box sx={{ maxWidth: '20rem' }}>
                  <Typography variant='body2'>You are rewarded with <Typography sx={{display:'inline-flex'}}>20</Typography> coins on watching introduction video.</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '8px 16px' }}>
        <Link className="disable-link-styles width-full" to="/introduction">
          <Button variant="outlined" className='width-full' sx={{ borderRadius: 20, mb:'1rem' }} autoFocus>
            Continue
          </Button>
          </Link>
        </DialogActions>
              </Box>
      </SharedDialog>

      {/* <Dialog
        fullScreen={fullScreen}
        open={openCongrats}
        onClose={closeDialogPublish}
        aria-labelledby="dialog-title">
        <DialogTitle id="dialog-title" sx={{ minWidth: '15rem' }}>
          <Grid container spacing={2} sx={{ width: 'unset' }}>
            <Grid item xs={12}>
              <div className='items-center'>
                <img src={Coin} alt="React Logo" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='typography-heading-2x items-center'>
                Congratulations!
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className='items-center'>
                <Box sx={{ maxWidth: '15rem' }}>
                  You are rewarded with <b>20</b> coins on watching introduction video.
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: '8px 16px' }}>
          <Button variant="outlined" className='width-full' style={{ borderRadius: 20 }} onClick={closeDialogPublish} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog> */}
    </div >
  )
}

export default Welcome
