import React from 'react';
import Grid from '@mui/material/Grid';
import Videos from '../components/dashboardPages/Videos';
import Profile from '../components/dashboardPages/Profile';
import TaskList from '../components/dashboardPages/TaskList';
import Policy from '../components/dashboardPages/Policy';
import NewsUpdates from '../components/dashboardPages/NewsUpdates';
import UpcomingEvent from '../components/dashboardPages/UpcomingEvent';
import Teams from '../components/dashboardPages/Teams';


const Dashboard = () => {
  return (
    <>
      <Grid container spacing={2} pb={1} >
        <Grid item xs={12} md={12} lg={4}>
          <Profile />
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <TaskList />
        </Grid> 
        <Grid item xs={12} md={6} lg={4}>
          <Policy />
        </Grid>
        <Grid item xs={12} md={6} lg={4} order={{ md: 4, lg: 3 }}>
          <NewsUpdates />
        </Grid>
        <Grid item xs={12} md={6} lg={4} order={{ md: 5, lg: 4 }}>
          <UpcomingEvent />
        </Grid>
        <Grid item xs={12} md={12} lg={12} order={{ md: 6, lg: 6 }}>
          <Videos />
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4} order={{ md: 4, lg: 7 }}>
          <Teams />
        </Grid> */}
      </Grid>
    </>
  )
}

export default Dashboard