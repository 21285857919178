export const organizationConstants = {

    ORGANIZATION_REQUEST: 'ORGANIZATION_REQUEST',
    
    ORGANIZATION_SUCCESS: 'ORGANIZATION_SUCCESS',
    
    ORGANIZATION_FAILURE: 'ORGANIZATION_FAILURE',
    
    }
    
    
    
    
    export const getOrganizationApi = {
    LIST:'api/v1/users/hierarchy',
    VIEW:'api/v1/users/hierarchy/',
    
    }