import React from 'react'
import {Box, Typography, Card, Avatar, CardHeader, IconButton,
        List, ListItem, ListItemText, ListItemAvatar,
        Groups2Sharp as Groups2SharpIcon,ForumSharp as ForumSharpIcon, blue} from '../../utils/AllImportsHelper';
import teamsDummyData from '../../datafiles/dashboard/teamsDummyData.json';
// import messagingSvg from "../../assets/images/messaging.svg";
import ForumIcon from '@mui/icons-material/Forum';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';


const Teams = () => {
    const [dense, setDense] = React.useState(false);
  return (
    <>
          <Card sx={{ minHeight: 'var(--min-height-dashboard-components )', height:'100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
            <CardHeader sx={{ pt: 'var(--padding-all-1)' }}
              avatar={
                <Avatar>
                  <Groups2SharpIcon fontSize="small" />
                </Avatar>

              }
              title={<Typography variant="h6">Teams</Typography>}
            // subheader="September 14, 2016"
            />
            <Box sx={{ p: "0 var(--padding-all-1) var(--padding-all-1)" }}>
              <Box id='UpcomingEventsData' className='upcoming-events'>
                {teamsDummyData.map((key, index) => {
                  return (
                    <Card key={index} sx={{ mb: 2, backgroundColor: "var(--color-lightestblue)" }}>
                      <List dense={dense} sx={{ p: 'var(--padding-none)'}} >
                        <ListItem
                          secondaryAction={<IconButton edge="end" aria-label="delete"><ForumIcon fontSize='small' color='primary' /></IconButton>}>
                          <ListItemAvatar> <Avatar alt={key.name} src={key.src} sx={{ height: 'var(--equal-hw2_5)', width: 'var(--equal-hw2_5)' }} /></ListItemAvatar>
                          <ListItemText primary={<Typography variant="subtitle2" sx={{ pt: 'var(--padding-none)' }}>{key.name}</Typography>} />
                        </ListItem>
                        <ListItem sx={{ pt: 'var(--padding-none)' }}>
                          <ListItemText 
                              // primaryTypographyProps={{ fontSize: '14px', fontWeight: 'bold', color: '#64748B' }} 
                              primary={<Typography variant='subtitle2' color='var(--color-grey)'>{key.profile}</Typography>}
                              secondary={<Typography variant="caption" color='var(--color-greyblue)'>{key.email}</Typography>}/>
                        </ListItem>
                        {/*//primaryTypographyProps={{ fontSize: '14px', fontWeight: 'bold', color: '#64748B' }} */}
                      </List>
                    </Card>
                  )
                })}
              </Box>
            </Box>
            {/* </Box> */}
          </Card>
    </>
  )
}

export default Teams