

import { createSlice } from '@reduxjs/toolkit';

const wheelSlice = createSlice({
  name: 'wheel',
  initialState: {
    count: 0,
    disableWheel: false,
    disableClaimReward: true,
    disablePrintReward:true,
    currentSegment: '',
    id: 'wheel'
  },
  reducers: {
     increaseCount: (state, actions) => {
       if(state.count < 3){
         state.count = state.count + 1;
         state.disableWheel = !state.disableWheel;
         state.disableClaimReward = !state.disableClaimReward;
         state.disablePrintReward =!state.disablePrintReward;
         state.currentSegment = actions.payload.currentSegment;
        }
        state.id = actions.payload.id;
        // console.log('rewardClaimed',{state, actions})
    },
    setWheel: (state, actions) => {
      state.id = actions.payload;
    },
    rewardClaimed: (state, actions) => {
      if(state.count < 3){
        state.disableWheel = !state.disableWheel;
      }
      state.disableClaimReward = !state.disableClaimReward;
      state.disablePrintReward =!state.disablePrintReward;
    }
    // onFinished: (state, actions) => {
    //   state.count = false;
    //   state.id = actions.payload;
    //   console.log(state,state.isOpen,'disable')
    // },
  },
});

export const { increaseCount,setWheel,rewardClaimed,rewardPrinted } = wheelSlice.actions;

export default wheelSlice.reducer;
