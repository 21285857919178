import { publish } from './publisher.js';

const modelUpdatedHelper = (model) => {

    let self = {};
    
    self.update = () => {
      publish({data: model.model, id: model.id});
    };
      
    return self;
  }
  
  export default modelUpdatedHelper;