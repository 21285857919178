export const departmentUserConstants = {

    DEPARTMENT_USER_REQUEST: 'DEPARTMENT_USER_REQUEST',
    
    DEPARTMENT_USER_SUCCESS: 'DEPARTMENT_USER_SUCCESS',
    
    DEPARTMENT_USER_FAILURE: 'DEPARTMENT_USER_FAILURE',
    
    }
    
    
    
    
    export const getDepartmentUserApi = {
    LIST:'api/v1/users',
    VIEW:'api/v1/users/',
    
    }