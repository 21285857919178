import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

let detaisData = [];
const QualificationDetails = ({ showErrors, setShowErrors, index }) => {

  let educationDetailsModel = [
    { label: 'Education', type: "singleSelect", value: '', validators:[ { name: 'required'} ], data: [{ value: '', label: '' }, { value: 'highschool', label: 'High School Grad' }, { value: 'ba', label: 'B.A' }, { value: 'ma', label: 'M.A' }, { value: 'bcom', label: 'B.Com' }, { value: 'mcom', label: 'M.Com' }, { value: 'bca', label: 'BCA' }, { value: 'mca', label: 'MCA' }, { value: 'btech', label: 'B.Tech' }, { value: 'mtech', label: 'M.Tech' }], validators: ['required'] },
    { label: 'Name of Board / University', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: '', label: '' }, { value: 'cbse', label: 'CBSE' }, { value: 'icse', label: 'ICSE' }, { value: 'du', label: 'University of Delhi' }, { value: 'iit', label: 'Indian Institute of Technology' }, { value: 'dtu', label: 'Delhi Technological University' }, { value: 'ipu', label: 'Indraprastha University' }, { value: 'bits', label: 'Birla Institute of Technology' }, { value: 'amity', label: 'Amity University' }], validators: ['required'] },
    { label: 'Stream', type: "singleSelect", value: '', validators:[ { name: 'required'} ], data: [{ value: '', label: '' }, { value: 'mechanical', label: 'Mechanical' }, { value: 'cs', label: 'Computer Science' }, { value: 'civil', label: 'Civil' }, { value: 'electrical', label: 'Electrical' }, { value: 'electronics', label: 'Electronics' }, { value: 'biotech', label: 'Biotech' }, { value: 'design', label: 'Design' }, { value: 'digital', label: 'Digital Marketing' }], validators: ['required'] },
    { label: 'Marks Obtained (in %)', type: "textField", validators:[ { name: 'required'},{ name:'pattern', regex:/^[1-9][0-9]?$|^100$/, errorMessage:'Field contains invalid input'}], size: 'small', value: '' },
    { label: 'Passing Year', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: '', label: '' }, { value: '2010', label: '2010' }, { value: '2011', label: '2011' }, { value: '2012', label: '2012' }, { value: '2013', label: '2013' }, { value: '2014', label: '2014' }, { value: '2015', label: '2015' }], validators: ['required'] },
    { label: 'Grade', type: "textField", size: 'small', value: '', validators:[ { name: 'required'} ] },
  ];

  let educationDetailsHelper = new FormHelper({ model: educationDetailsModel, id: 'educationDetails' });

  const [educationDetailsData, setEducationDetailsData] = useState(educationDetailsModel);

  const valueChangeListner = () => {
    let educationDetailsHelper = educationDetailsData.reduce((prev,curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, []);

    var error = false;
    var errorMessage = false;
    
    for (let i = 0; i < educationDetailsData.length; i++) {
        for (let j = 0; j < educationDetailsData[i]?.validation?.length; j++) {
            var res = educationDetailsData[i]?.validation[j](educationDetailsData[i].value);
            if (res.error) {
                error = res.error;
                errorMessage = res.errorMessage;
                break;
            }
        }
    }
    // console.log({educationDetailsHelper});
    if (error) {
      setShowErrors(showErrors + 1);
      // setDataInIndex({error: true});
      detaisData[index] = {error: true};
      return;
    }

    // console.log({ educationDetailsHelper });
    // setDataInIndex(educationDetailsHelper);
    detaisData[index] = educationDetailsHelper;

  }

  useEffect(() => {
    valueChangeListner();
  }, [showErrors])


  return(<>
    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {setEducationDetailsData(data);  }} {...educationDetailsHelper.model} />
  </>)

}

const EducationDetails = ({ setCurrentJSXData, currentJSXData, checkError }) => {

 
  const [showErrors, setShowErrors] = React.useState(0);
  const [educationalDegrees, setEducationalDegrees] = React.useState([1]);
  // const [isTouched, setIsTouched] = React.useState(false);
  // const [isFilled, setIsFilled] = React.useState(false);

  const addNewEducationalDegre = () => {
   
    //  setEducationalDegrees([...educationalDegrees,  new FormHelper({ model: educationDetailsModel, id: 'educationDetails' + educationalDegrees.length })]);
     setEducationalDegrees([...educationalDegrees, 1]);
    //  setIsFilled(false)
   }

   const removeEducation = (i) => {
     let educationMembers = [...educationalDegrees];
     educationMembers.splice(i, 1);
     if (!educationMembers.length)
       educationMembers.push( 1 );
     setEducationalDegrees(educationMembers);
    //  setIsFilled(true)
   }

  const valueChangeListner = () => {
    setShowErrors(showErrors + 1);
    
    setTimeout(() => {
      // console.log("Suraj", {detaisData});
      let checkError = detaisData.reduce((prev,curr) => {
        if(curr.error){
          prev = true;
        }
        return prev;
      }, false);
      console.log("Suraj", {detaisData,checkError})
      if(!checkError){
        setCurrentJSXData({...currentJSXData, educationDetails: detaisData})
      }
    }, 100)
  }

  useEffect(() => {
    console.log("Akas top", { checkError });
    if (checkError) {
      valueChangeListner();
    }
  }, [checkError])

  //  educationDetailsHelper.isTouched.touched.subscribe(() => {
  //   setIsTouched(true);
  // })

  // educationDetailsHelper.isFilled.filled.subscribe(() => {
  //   setIsFilled(educationDetailsHelper.isFilled.filled.get())
  // })

     return(

          <>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
               {educationalDegrees.map((v,i) => {
                 return <Box key={'educationalDegrees' + i} component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <Box sx={{ gridColumn: 'span 3' }} id={v + "jds"}>
                   <Grid container spacing={2} display='flex'>
                         <Grid sx={{display:'flex', alignContent:'center', flexWrap:'wrap'}} item xs={10}>
                         <Typography variant='h8' fontWeight={800}>Education {i+1}</Typography>
                         </Grid>
                         <Grid item xs={2} className="dashboardStyle">
                             <IconButton aria-label="Example" onClick={() => removeEducation(i)} >
                             {/* disabled={!isTouched} */}
                                     <DeleteIcon icon={faEllipsisV}  size="small" />
                             </IconButton>
                         </Grid>
                   </Grid>
                   </Box>
                   <QualificationDetails showErrors={showErrors} setShowErrors={setShowErrors} index={i} key={v} />
                   {/* <RenderHelper {...educationDetailsHelper.model}/> */}
                 </Box>
               })}
               <Box sx={{ border: "var(--border-add-btn)", borderRadius: "var(--border-radius-add-btn)", display: "flex", justifyContent: "center" }}>
                 <Button sx={{ minWidth: "var(--min-width-15)" }} startIcon={<AddIcon />} onClick={addNewEducationalDegre}  >
                 {/* disabled={!isFilled} */}
                   Add Education
                 </Button>
               </Box>
             </Box>
          </>
     );
}

export default EducationDetails