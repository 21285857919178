export const departmentConstants = {

    DEPARTMENT_REQUEST: 'DEPARTMENT_REQUEST',
    
    DEPARTMENT_SUCCESS: 'DEPARTMENT_SUCCESS',
    
    DEPARTMENT_FAILURE: 'DEPARTMENT_FAILURE',
    
    }
    
    
    
    
    export const getDepartmentApi = {
    LIST:'api/v1/department',
    VIEW:'api/v1/department/',
    
    }