import React,{useState,useEffect} from 'react'
import {Box, Typography, CardContent, styled, Card, IconButton, Avatar, CardHeader, InputBase,
        List, ListItem, ListItemAvatar, ListItemText,
        Search as SearchIcon, Videocam as VideocamIcon, Description as DescriptionIcon, DownloadSharp as DownloadSharpIcon, blue} from '../../utils/AllImportsHelper';
import PolicyAndProcedureDummyData from '../../datafiles/dashboard/policyAndProcedureDummyData.json';
import { useTheme } from '@mui/material/styles';
import policySvg from "../../assets/images/policy.svg"
import ArticleIcon from '@mui/icons-material/Article';
// D:\Onboarding-Update\onboarding\src\assets\doc
import ApiService from '../../utils/ApiService';
import CircularProgress from '@mui/material/CircularProgress';

const Policy = () => {
    const theme = useTheme();
    const [dense, setDense] = React.useState(false);
    const [documentUrl, setDocumentUrl] = useState('');
    // const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(documentFile)}&embedded=true`;
    // function openDocumentInNewTab(url) {
    //   window.open(url, '_blank');
    // }
    function openDocument(fileUrl) {
      window.open(fileUrl, '_blank');
    }

    const [loading, setLoading] = useState(true); // Add loading state
    const [policyAndProcedure, setPolicyAndProcedure] = useState([]);

    useEffect(() => {
      ApiService.get('corporateDocument', {}).then(res => {
        // setLoading(false)
        let data = res.data.data
        // console.log({res,data});
        if (data) {
          setPolicyAndProcedure(data);
          setLoading(false);
            // let reverseData = _.orderBy(data, ['type'], ['asc']);
            // setDashboardList(reverseData)
        }
    }).catch((error) => {
        // setLoading(false)
        console.log(error);
        setLoading(false);
    })
    }, [])

    const getIcon = (url) => {

      const type = url?.split('.')?.[(url?.split('.')?.length ?? 1) - 1];
      switch(type){
        case "pdf" : return "/images/pdf.png";
        case "docx" : return "/images/docx.png";
        case "xlsx" : return "/images/xls.png";
      }
    }

    // useEffect(() => {
    //   fetch('PolicyAndProcedureDummyData.json')
    //     .then(response => response.json())
    //     .then(documentUrl => setDocumentUrl(documentUrl))
    //     .catch(error => console.error(error));
    // }, []);

    const handleButtonClick = (url) => {
      // Update the document URL state variable
      setDocumentUrl(url);
    
      // Trigger a download if needed
      // This will open a new tab with the document and prompt the user to download it
      window.open(url,'_blank');
      // console.log(url)
    };
    const handleButtonClickDownload = (url) => {
      // Trigger a download of the document
      // const blob = new Blob(['text/csv'], { type: '.csv' });
      // const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    };

    
    //search bar menu //
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '36px',
    backgroundColor: '#EDF5FE',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));




  //table fuction ///
  function generate(element) {
    return [0].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }
  return (
    <>
         <Card sx={{ minHeight: 'var(--min-height-dashboard-components)', height:'100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardHeader sx={{ p:'var(--padding-all-1)' }}//[[previously pb:1.2 pt:1.5]]
              avatar={
                <Avatar>
                   <ArticleIcon fontSize="small" />
                </Avatar>

              }
              title={<Typography variant="h6">Policy and Procedure</Typography>}
            // subheader="September 14, 2016"
            />
            <CardContent sx={{ pt: 'var(--padding-none)' }}>
              <Box sx={{mb:1}}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon sx={{color:'#64748B'}}  />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
              </Box>
              <Box id='PolicyAndProcedureData' className='policy-data'>
              {loading ? ( // Render loader if loading state is true
       <Box className='policy-data' sx={{display:'flex',justifyContent:'center',alignItems:'center'}}> <CircularProgress /></Box>
      ) : (
        <>
                {policyAndProcedure.map((key, index) => {
                  return (
                    <List dense={dense} key={index}>
                      {generate(
                        <Card sx={{ backgroundColor: "var(--color-white)", marginBottom: "var(--margin-small)", ':hover': { bgcolor: 'var(--color-lightblue)'}}}>
                          <CardContent>
                            <ListItem className='policy-list' secondaryAction={
                              <IconButton className='download-icon' edge="end" aria-label="Download Icon" onClick={() =>handleButtonClickDownload(key.fileUrl)}>
                                <DownloadSharpIcon color="primary" fontSize='small' />
                              </IconButton>}>
                              <ListItemAvatar sx={{display:'flex'}}> <img  alt={key.name} src={getIcon(key.fileUrl)}/></ListItemAvatar>
                              <ListItemText primary={<Typography variant="subtitle2" sx={{cursor:'pointer'}} onClick={() =>handleButtonClick(key.fileUrl)}>{key.name}</Typography>} /></ListItem>
                          </CardContent>
                        </Card>
                      )}
                    </List>

                  )
                })}
                </>
              )}
              </Box>
            </CardContent>
            {/* </Box> */}
            {/* <div> */}
    {/* <button onClick={handleButtonClick}>View/Download Document</button> */}
   {/* {documentUrl && (
      <iframe src={documentUrl} style={{ height:'500px',width:'200px' }} />
    )} */}


  {/* </div> */}
          </Card>
        
    </>
  )
}

export default Policy