import React from 'react';
import {
    Box, Typography, Card, Stack
  } from '../../utils/AllImportsHelper';

import badge from '../../assets/images/badge-vector.svg';
import Coin from '../../assets/images/coin-vector.svg';
import Certificate from '../../assets/images/certificate-vector.svg';

const Achievements = () => {
     return(

          <>
           <Card className='congratulation' sx={{ width:'100%', p:"var(--padding-congratulations-profile)", borderRadius: "var(--border-radius-accordian)", mb:'1rem'}}>
                                <Stack>
                                    <Typography variant='h4' color="var(--color-leaderboard-title)" fontWeight='var(--font-weight-5)'>Congratulations! You have won</Typography>
                                    <Box sx={{ display:'inline-flex', p:'var(--padding-horizontal-1)', justifyContent: 'space-around'}}>
                                            <Stack gap={1} sx={{ display:'inline-flex', justifyContent: 'center'}}>
                                                <img className="coin-image-profile" src={Coin} alt="coin-logo"/>
                                                <Typography variant='body1' fontSize='1.125rem'><b>48</b> Coins</Typography>
                                            </Stack>
                                            <Stack gap={1} sx={{ display:'inline-flex', justifyContent: 'center'}}>
                                                <img className="coin-image-profile" src={badge} alt="badge-logo"/>
                                                <Typography variant='body1' fontSize='1.125rem'><b>1</b> Badge</Typography>
                                            </Stack>
                                            <Stack gap={1} sx={{ display:'inline-flex', justifyContent: 'center'}}>
                                                <img className="coin-image-profile" src={Certificate} alt="certificate-logo"/>
                                                <Typography variant='body1' fontSize='1.125rem'><b>1</b> Certificate</Typography>
                                            </Stack>
                                    </Box>
                                </Stack>
                            </Card>
          </>

     );
}

export default Achievements