import React, { useState, useEffect } from 'react';
import MuiListItem from '@mui/material/ListItem';
import {
  List, Avatar, ListItem, ListItemAvatar, ListItemText, Card, Typography, Divider,
  Folder as FolderIcon, Videocam as VideocamIcon, Person as PersonIcon, LinkedIn as LinkedInIcon, Description as DescriptionIcon,
} from '../../utils/AllImportsHelper'
import DynamicDrawer from '../../features/drawers/DynamicDrawer';
import PolicyReviewDrawer from '../../components/drawerBody/PolicyReviewDrawer'
import InvestmentDrawer from '../../components/drawerBody/InvestmentDrawer';
import LinkedInDrawer from '../../components/drawerBody/LinkedInDrawer';
import SharedDrawer from '../../features/drawers/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../../features/drawers/drawerSlice';
import Box from '@mui/material/Box';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Coin from '../../assets/images/gold-coin.png';
import { Link } from "react-router-dom";
import ApiService from '../../utils/ApiService';
import { useNavigate } from 'react-router-dom';
import { setTaskDetails } from "../../features/taskDetailsStore/taskSlice";
// import CircularProgress from '@mui/material/CircularProgress';
// import policyReviewData from '../../components/dashboardPages/Policy'

// const ListItemTheme = styled(ListItem)({
//         paddingLeft:'0',
//         margin:'2px',
//             '&:hover': {
//                 backgroundColor: 'var(--color-lightblue)',
//                 borderRadius:'5px',
//                 margin:'2px',
//             },

// });

// const ListItemAvatarTheme = styled(ListItemAvatar)( {
//       minWidth: '48px',
//   });

const ListItemTheme = styled(ListItem)(({ theme }) => ({
  paddingLeft: '0',
  //  margin: '2px',
  '&:hover': {
    backgroundColor: 'var(--color-lightblue)', /* theme.palette.action.hover, */
    borderRadius: '5px',
    //  margin: '2px',
  },
  '& .MuiListItemAvatar-root': {
    minWidth: '42px',
  },
}));

const TaskList = () => {
  const [minDaysLeft, setMinDaysLeft] = useState('');
  const [doneTask, setDoneTask] = useState('');
  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    ApiService.get('task', {}).then(res => {
      // setLoading(false)
      let data = res.data.data
      dispatch(setTaskDetails(data));
      const minDaysLeft = data.reduce((min, current) => {
        return current.daysLeft < min ? current.daysLeft : min;
      }, data[0].daysLeft); // Set initial value to the first object's daysLeft
      setMinDaysLeft(minDaysLeft)
      // console.log("Minimum daysLeft value:", minDaysLeft);
      const totalDoneTasks = data.filter(task => task.status === "1").length;
      setDoneTask(totalDoneTasks)
      console.log(totalDoneTasks)
      const currentDate = new Date();
      const targetDate = new Date(currentDate.getTime() + minDaysLeft * 24 * 60 * 60 * 1000);

console.log("Target date:", targetDate);
      // console.log({res,data});
      if (data) {
        setTaskList(data);
        setLoading(false);
          // let reverseData = _.orderBy(data, ['type'], ['asc']);
          // setDashboardList(reverseData)
      }
  }).catch((error) => {
      // setLoading(false)
      setLoading(false);
      console.log(error);
  })
  }, [])

  const navigate = useNavigate();

  const goToEmployeeDetails = () => {
    // navigate('/employeeDetails');
  };

  const goToVideos = () => {
    navigate('/Videos');
  };


  const [dense, setDense] = React.useState(false);
  const dispatch = useDispatch();
  const policyReviewDrawer = (id, taskId) => {
    dispatch(openDrawer({id, data: {taskId : taskId}}));
    // console.log({id, taskId})
  }
  const cancel = (id) => {
    dispatch(closeDrawer(id));
  }
  // const [policyOpen, setOpenPolicyDrawer] = useState(false);

  // const policyReviewDrawer = () => {
  //   setOpenPolicyDrawer(!policyOpen);
  // };

  // const policyReviewData = {
  //   Body: PolicyReviewDrawer,
  //   onClick: policyReviewDrawer,
  //   open: policyOpen
  // };

  // const [openInvestment, openInvestmentDrawer] = useState(false);

  // const investmentDrawer = () => {
  //   openInvestmentDrawer(!openInvestment);
  // };

  // const investmentData = {
  //   Body: InvestmentDrawer,
  //   onClick: investmentDrawer,
  //   open: openInvestment
  // };


  // const [openLinkedInDrawer, setOpenLinkedInDrawer] = useState(false);

  // const linkedInDrawer = () => {
  //   setOpenLinkedInDrawer(!openLinkedInDrawer);
  // };

  // const linkedInDrawerData = {
  //   Body: LinkedInDrawer,
  //   onClick: linkedInDrawer,
  //   open: openLinkedInDrawer
  // }

  const handleClick = (sequence,id) => {
    // const navigate = useNavigate();
    switch (sequence) {
      case "1": goToEmployeeDetails(); break;
      case "2": policyReviewDrawer('policy', id);
      break;
      case "3": policyReviewDrawer('investment',id); break;
      case "4": goToVideos(); break;
      case "5": policyReviewDrawer('linkedin'); break;
      default: console.log("Wrong Choice");
      // console.log("Clicked ID: ", id);
    }
    // console.log(sequence,id)
  }

  const getPrimaryStatusTemplate = (status) => {
    return status == 0 ? <Typography className='pending-item' variant='caption' component="div" align="center" >Pending</Typography> : <Typography className='done-item' variant='caption' component="div" align="center" >Done</Typography>;
  }

  const getCurrentIcon = (sequence) => {
    switch (sequence) {
      case "1": return <PersonIcon color="primary" fontSize='small' />;
      case "2": return <DescriptionIcon color="primary" fontSize='small' />;
      case "3": return <FolderIcon color="primary" fontSize='small' />;
      case "4": return <VideocamIcon color="primary" fontSize='small' />;
      case "5": return <LinkedInIcon color="primary" fontSize='small' />;
      default: console.log("Wrong Choice");
    }
  }

  return (
    <>
      <Card sx={{ minHeight: 'var(--min-height-dashboard-components )', height: '100%', p: 2, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>

        <Box>
          <ListItem sx={{ p: 0 }}

            secondaryAction={<ListItemText edge="end"
              primary={<Box className='tasklist-progressbar'><Typography variant='caption' component="div">{doneTask} of 5 tasks completed</Typography><CircularProgress size='1.5rem' variant="determinate" value={80} /></Box>}
              secondary={<Typography variant='caption' component="div" color="var(--color-dashboard-tasklist)">{minDaysLeft} Days left</Typography>} />}>
            <Avatar sx={{ mr: "var(--margin-right-1)" }}>
              <PlaylistAddCheckIcon fontSize='small' />
            </Avatar>
            <ListItemText sx={{ pr: 'var(--padding-tasklist-element9)' }} primary={<Typography variant='h6'>To do task list (5)</Typography>} />
          </ListItem>
        </Box>

        <Box className='table-height'>
          <List dense={dense} sx={{ cursor: 'pointer' }} >

              {/* <ListItemTheme
                onClick={goToEmployeeDetails}
                secondaryAction={<ListItemText edge="end"
                  primary={<Typography className='pending-item' variant='caption' component="div" align="center" >Pending</Typography>}
                  secondary={<Typography variant='caption' color='var(--color-dashboard-tasklist)'>2 Days left</Typography>} />}>
                <ListItemAvatar><PersonIcon color="primary" fontSize='small' /></ListItemAvatar>
                <ListItemText sx={{ pr: 'var(--padding-tasklist-element5)' }} primary={<Typography variant="subtitle2" color="black">Complete profile</Typography>} secondary={<Typography variant="body2" color='secondary'>Complete personal, professional, financial etc details</Typography>} />
              </ListItemTheme>


            <Divider />

            <ListItemTheme onClick={() => policyReviewDrawer('policy')}
              secondaryAction={<ListItemText edge="end"
                primary={<Typography className='pending-item' variant='caption' component="div" align="center" >Pending</Typography>}
                secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>3 Days left</Typography>} />} >
              <ListItemAvatar><DescriptionIcon color="primary" fontSize='small' /></ListItemAvatar>
              <ListItemText sx={{ pr: 'var(--padding-tasklist-element5)' }} primary={<Typography variant="subtitle2">Policy review and self attest documents</Typography>} secondary={<Typography variant="body2" color='secondary'>Preview all the company policy and submit all related document with self attest</Typography>} />
            </ListItemTheme>


            <Divider />

            <ListItemTheme onClick={() => policyReviewDrawer('investment')}
              secondaryAction={<ListItemText edge="end"
                primary={<Typography className='done-item' variant='caption' component="div" align="center" >Done</Typography>} />}>
              <ListItemAvatar><FolderIcon color="primary" fontSize='small' /></ListItemAvatar>
              <ListItemText
                sx={{ pr: 'var(--padding-tasklist-element5)' }}
                primary={<Typography variant="subtitle2">Investment declaration</Typography>}
                secondary={<Typography variant="body2" color='secondary'>Fill your income and declare your investment all the related document.</Typography>} />
            </ListItemTheme>


            <Divider />

              <ListItemTheme
                onClick={goToVideos}
                secondaryAction={<ListItemText edge="end"
                  primary={<Typography className='pending-item' variant='caption' component="div" align="center" >Pending</Typography>}
                  secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>5 Days left</Typography>} />} >
                <ListItemAvatar><VideocamIcon color="primary" fontSize='small' /></ListItemAvatar>
                <ListItemText
                  sx={{ pr: 'var(--padding-tasklist-element5)' }}
                  primary={<Typography variant="subtitle2" color="black">Preview training videos</Typography>}
                  secondary={<Typography variant="body2" color='secondary'>Watch all training related videos</Typography>} />
              </ListItemTheme>
           
            <Divider />

            <ListItemTheme onClick={() => policyReviewDrawer('linkedin')}
              secondaryAction={<ListItemText edge="end"
                primary={<Typography className='pending-item' variant='caption' component="div" align="center" >Pending</Typography>}
                secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>7 Days left</Typography>} />}>
              <ListItemAvatar><LinkedInIcon color="primary" fontSize='small' /></ListItemAvatar>
              <ListItemText
                sx={{ pr: 'var(--padding-tasklist-element5)' }}
                primary={<Box display='inline-flex' sx={{ gap: '.5rem' }}><Typography variant="subtitle2">Update Linkedin profile and earn coins </Typography><img src={Coin} alt="coin-logo" className='tasklist-coin' /></Box>}
                secondary={<Typography variant="body2" color='secondary'>Update your linkedin profile with attached image and content</Typography>} />
            </ListItemTheme> */}
   {loading ? ( // Render loader if loading state is true
       <Box className='policy-data' sx={{display:'flex',justifyContent:'center',alignItems:'center'}}> <CircularProgress /></Box>
      ) : (
        <>
            {taskList.map((row,index) => {
              return (
                <ListItemTheme key={row.id} onClick={() => handleClick(row.sequence,row.id)}
                secondaryAction={<ListItemText edge="end"
                  primary={getPrimaryStatusTemplate(row.status)}
                  secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>{row.endDate}</Typography>} />}>
                <ListItemAvatar>{getCurrentIcon(row.sequence)}</ListItemAvatar>
                <ListItemText
                  sx={{ pr: 'var(--padding-tasklist-element5)' }}
                  primary={<Box display='inline-flex' sx={{ gap: '.5rem' }}><Typography variant="subtitle2">{row.description}</Typography>
                    {/* <img src={Coin} alt="coin-logo" className='tasklist-coin' /> */}
                  </Box>}
                  secondary={<Typography variant="body2" color='secondary'>{row.name}</Typography>} />
              </ListItemTheme>
              )
            })}
            </>
      )}
          </List>
        </Box>
      </Card>



      <SharedDrawer id='policy'>
        <Box className="tasklist_drawer">
          <PolicyReviewDrawer parentId='policy'  />
        </Box>
      </SharedDrawer>

      <SharedDrawer id='investment'>
        <Box className="tasklist_drawer">
          <InvestmentDrawer parentId="investment" />
        </Box>
      </SharedDrawer>
      <SharedDrawer id='linkedin'>
        <Box className="tasklist_drawer">
          <LinkedInDrawer parentId='linkedin' />
        </Box>
      </SharedDrawer>
    </>
  )
}

export default TaskList