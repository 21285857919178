import axios from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
const BASE_URL = 'https://gatewayapi.eela.tech/api/v1';

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL,
      withCredentials: true,
    });

    // Define your endpoints here
    this.endpoints = {
      task: "task",
      upcomingEvents: "upcoming-events",
      usersHierarchy: "users/hierarchy",
      department: "department",
      orgDepartment: 'department',
      orgDepartmentUser: 'users',
      user: 'user',
      userInfo: "userinfo",
      userById: "user",
      policyDocuments: "policy-documents",
      videoUpload: "video-upload",
      videoUploadService: "video-service",
      streamCategory: "stream-category",
      leaderSpeak: "leader-speak",
      orgUsers: 'users/hierarchy',
      gamificationUserSearch: 'gamification-user',
      getAllBadges: 'gamification-badge',
      recommendedVideo:'recommended-video',
      uploadDocByUser: 'user-corporate-upload-doc',
      corporateDocument : 'corporate-document',
      notification : 'event-notification',
      usersPostTemplate:'users-post-template',
      postTemplateBanner: 'post-template-banner',
      postLink: 'post-link',
      onboardingMailinfo:'onboarding-mailinfo',
      processTriggerMail: 'process-trigger-mail',
      comapnyLog: "company/1",
      iconVault:'icon-vault',
      updateUser: 'onboarding-users'
    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    console.debug("get:endpointKey", params)
    console.debug("get", endpointKey)

    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
    
      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  async putAdopter(endpointKey, data = null, searchParam = null, userId = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      endpoint = endpoint + '?app=' + getAppToken();
      if(userId){
        endpoint = endpoint + '&userId=' + userId;
      }
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  async postAdopter(endpointKey, data = null, searchParam = null, userId = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      endpoint = endpoint + '?app=' + getAppToken();
      if(userId){
        endpoint = endpoint + '&userId=' + userId;
      }
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  

}
}



export default new APIService(BASE_URL);
