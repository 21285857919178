import React from "react";
import "../assets/styles/Theme.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tick from '../assets/images/green-tick-vector.svg';

const ChangePassword = () => {
     
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
     setOpen(true);
   };
 
   const handleClose = (value) => {
     setOpen(false);
   };


     return(

          <>
               <Box sx={{display:'flex', height:'38rem', width:'100%', justifyContent:'center',pt:'3rem', backgroundColor:'white'}}>
                    <Box sx={{height:'27.5rem', width:'27rem', p:'1rem'}}>
                         <Stack>

                              <Box sx={{maxWidth:'60%', ml:'5rem', mb:'2rem', display:'flex', flexDirection:'column'}}>
                                   <Typography textAlign='center' variant='h5' fontWeight='var(--font-weight-6)'>Change Password</Typography>
                                   <Typography textAlign='center' variant='body2' sx={{opacity:'38%'}}>Set a strong password to prevent unauthorized access to your account. </Typography>
                              </Box>

                              <TextField
                                   id="current-password"
                                   label="Enter Current Password"
                              />

                              <Typography mb="1rem" component={Link} to='/Verification' variant='body2' textAlign='right'>Forgot Password?</Typography>

                              <TextField
                                   sx={{mb:"1.25rem"}}
                                   id="new-password"
                                   label="Enter New Password"
                              />

                              <TextField
                                   sx={{mb:"2rem"}}
                                   id="confirm-password"
                                   label="Confirm New Password"
                              />

                              <Stack direction="row">
                                   <Button 
                                        onClick={handleClickOpen}
                                        sx={{width:'100%'}} 
                                        variant="contained">
                                        <Typography variant='body1' fontWeight='var(--font-weight-5)'>CHANGE PASSWORD</Typography>
                                   </Button>
                                   <Dialog
                                   open={open}
                                   onClose={handleClose}
                                   aria-labelledby="dialog-title"
                                   >
                                   <DialogContent>
                                   <Box>
                                        <Stack>
                                             <Box display='flex' sx={{justifyContent:'center', p:'2rem 0rem'}}>
                                                  <img src={Tick} alt='tick-icon'/>  
                                             </Box>
                                             <Typography textAlign='center' variant='h5' fontWeight='var(--font-weight-6)'>
                                                  Changed Successfully
                                             </Typography>
                                             <Box p='.25rem'>
                                                  <Typography textAlign='center' variant='body2'>
                                                       Your Password has been successfully changed
                                                  </Typography>
                                                  <Typography textAlign='center' variant='body2'>
                                                       Please login again
                                                  </Typography>
                                             </Box>
                                             <Button sx={{width:'100%', borderRadius:'var(--border-radius-add-btn)', mt:'2rem', height:'3rem'}} onClick={handleClose} variant="outlined">Login</Button>
                                             
                                        </Stack>
                                   </Box>
                                   </DialogContent>
                                   </Dialog>
                              </Stack>


                         </Stack>
                    </Box>
               </Box>
          </>

     );
}

export default ChangePassword