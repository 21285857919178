import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Button, PhotoCamera as PhotoCamera } from '../utils/AllImportsHelper';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Badge from '@mui/material/Badge';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AnimatedCoin from '../assets/images/animatedCoin.gif';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Person2Icon from '@mui/icons-material/Person2';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AddIcon from '@mui/icons-material/Add';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import UploadIcon from '@mui/icons-material/Upload';
import MedicationIcon from '@mui/icons-material/Medication';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BadgeIcon from '@mui/icons-material/Badge';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DynamicDrawer from '../features/drawers/DynamicDrawer';
import  FileUploadDocument  from '../components/drawerBody/FileUploadDocumentDrawer';
import manfollow from '../assets/images/walk.gif';

import SharedDrawer from '../features/drawers/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../features/drawers/drawerSlice';
import GamificationDrawer from '../components/drawerBody/GamificationDrawer';

import BasicDetails from "../components/empDetailsComponents/BasicDetails.js";
import FamilyDetails from "../components/empDetailsComponents/FamilyDetails.js";
import EmergencyDetails from "../components/empDetailsComponents/EmergencyDetails.js";
import EducationDetails from "../components/empDetailsComponents/EducationDetails.js";
import ExperienceDetails from "../components/empDetailsComponents/ExperienceDetails.js";
import MedicalDetails from "../components/empDetailsComponents/MedicalDetails.js";
import FinancialDetails from "../components/empDetailsComponents/FinancialDetails.js";
import EpfDetails from "../components/empDetailsComponents/EpfDetails.js";
import ApiService from '../utils/ApiService.js';
import { openSnackbar } from "../features/snakebar/snackbarSlice";

const EmployeeDetails = () => {

  const steps = [
    'Personal Details',
    'Professional Details',
    'Medical Insurance Details',
    'Financial Details',
    'EPF Details',
  ];

  const familyMemberDetails = { 'familyMemberName': '', 'relation': '', 'dateOfBirth': '', 'dependent': '', 'whetherEmployed': '', 'employeeCode': '' };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [expanded, setExpanded] = React.useState('panel01');
  const [membersFamily, setMembersFamily] = React.useState([familyMemberDetails]);
  const [educationalDegrees, setEducationalDegrees] = React.useState([0]);
  const [workExperiences, setWorkExperiences] = React.useState([0]);
  const [medicalInsurances, setMedicalInsurances] = React.useState([0]);
  const [sameAddress, setSameAddress] = React.useState(false);
  const [openUpload, uploadOpenDrawer] = useState(false);


  // const [count, setCount] = useState(0);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCount(count + 1);
  //   });
  // },[]);


  ///////////
  const userId = useSelector((state) => state?.user?.userListName?.userId)
  const policyDataTaskId = useSelector((state) => state?.routing);
  console.log(policyDataTaskId)


  const dispatch = useDispatch();
  const uploadDrawer = (id) => {
    dispatch(openDrawer(id));
  }
  const cancel = (id) => {
    dispatch(closeDrawer(id));
  }
  // 
  // const uploadDrawer = () => {
  //   uploadOpenDrawer(!openUpload);
  // };

  // const uploadData = {
  //   Body: FileUploadDocument,
  //   onClick: uploadDrawer,
  //   open: openUpload
  // };


  const handleSameAddressChange = (event) => {
    // console.log(event.target.checked);
    setSameAddress(event.target.checked);
  };

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [ currentJSXData , setCurrentJSXData ] = useState(false);
  const [ checkError, setCheckError ] = useState(0);

  const handleSave = () => {
    // check if data is available in currentJSXData
    // if available make api call
    setCheckError(checkError + 1);
    console.log("akab", {checkError})
  }

  
  const checkCurrentData = () => {
    let checkData = false;
    switch(activeStep){
      case 0: checkData = currentJSXData?.basicDetails && currentJSXData?.presentAddress && currentJSXData?.permanentAddress && currentJSXData?.familyDetails && currentJSXData?.emergencyDetailsHelper; break;
      case 1: checkData = currentJSXData?.educationDetails && currentJSXData?.experienceDetails; break;
      case 2: checkData = currentJSXData?.medicalDetails; break;
      case 3: checkData = currentJSXData?.financialDetails; break;
      case 4: checkData = currentJSXData?.epfDetails; break;
    }

    if(!checkData){
      return false;
    }

    let formData = new FormData();
    switch(activeStep){
      case 0: formData.append('firstName', currentJSXData.basicDetails['First Name']);
              formData.append('lastName' , currentJSXData.basicDetails['Last Name']);
              formData.append('gender' , currentJSXData.basicDetails['Gender']);
              formData.append('dateOfBirth' , currentJSXData.basicDetails['Date Of Birth']);
              formData.append('email' , currentJSXData.basicDetails['Email ID']);
              formData.append('phoneNo' , currentJSXData.basicDetails['Mobile No.']);
              // formData.append'departmentId'] , currentJSXData.basicDetails['Mobile No.'];
              // formData.append'designationId'] , currentJSXData.basicDetails['Mobile No.'];
              // formData.append'branchId'] , currentJSXData.basicDetails['Mobile No.'];
              // formData.append'hiringLeadId'] , currentJSXData.basicDetails['Mobile No.'];
              // formData.append'yearOfExperience'] , currentJSXData.basicDetails['Mobile No.'];
              // formData.append'employeeId'] , currentJSXData.basicDetails['Mobile No.'];
              // formData.append'onboardingUsersStatusId'] , currentJSXData.basicDetails['Mobile No.'];
              formData.append('emergencyContactPerson' , currentJSXData.emergencyDetailsHelper['Contact Name']);
              // formData.append('relationWithEmployee' , currentJSXData.basicDetails['Relation']);
              formData.append('identityMark' , currentJSXData.basicDetails['Identity Mark']);
              formData.append('maritalStatusId' , currentJSXData.basicDetails['Marital Status']);
                  break;
      case 1: 
      break;
      case 2: formData.append('gender' , currentJSXData.medicalDetails[0]['Gender']);
              formData.append('dateOfBirth' , currentJSXData.medicalDetails[0]['Date Of Birth']);
              // formData.append('relationWithEmployee' , currentJSXData.basicDetails['Relation']);
      break;
      case 3: formData.append('bankAccountNumber' , currentJSXData.financialDetails['Account Number']);
              formData.append('accountTypeId' , currentJSXData.financialDetails['Account Type']);
              // formData.append'branchId'] , currentJSXData.financialDetails['Branch'];
              formData.append('bankId' , currentJSXData.financialDetails['Name of the Bank']);
              formData.append('bankBranchId' , currentJSXData.financialDetails['Branch']);
              formData.append('aadhaar' , currentJSXData.financialDetails['Aadhaar Number']);
              formData.append('panCard' , currentJSXData.financialDetails['PAN']);
              formData.append('IFSCCode' , currentJSXData.financialDetails['IFSC Number']);
              formData.append('CIFNumber' , currentJSXData.financialDetails['CIF Number (Optional)']);
              formData.append('phoneNo' , currentJSXData.financialDetails['Mobile Number']);
              formData.append('email' , currentJSXData.financialDetails['Email ID']);
                break;
      case 4: formData.append('dateOfBirth' , currentJSXData.epfDetails['Date Of Birth']);
              formData.append('phoneNo' , currentJSXData.epfDetails['Mobile Number']);
              formData.append('designationId' , currentJSXData.epfDetails['Designation (Optional)']);
      break;
    }

    return formData;
  }

   useEffect(() => {
     
     let param = checkCurrentData();
     console.log({currentJSXData, param});
    if(param){
      // add user id
      ApiService.putAdopter('updateUser', param, userId).then((res) => {
        dispatch(openSnackbar({ dataLoad: true, message: 'Submitted successfully', severity: "info" }));

      }).catch((e) => {
        dispatch(openSnackbar({ dataLoad: true, message: e.res?.errors[0]?.title, severity: 'error' }));
      })
    }
  }, [currentJSXData])

  // const getStepJSX = (setCurrentJSXData, checkError) => {
  //   switch (activeStep) {
  //     case 0: return (
  //       <>
  //         <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
  //           <Box sx={{ width: "var(--width-full)" }}>
  //             <Typography variant="h5" fontWeight="700" fontSize="large">
  //               Personal Details
  //             </Typography>
  //           </Box>
  //           <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
  //             <Box color="text.secondary" fontSize="medium">
  //               Please fill your personal details
  //             </Box>
  //             <Badge color="error">
  //               <Box display='inline-flex' sx={{alignItems:'center'}}>
  //                 <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
  //                 <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
  //               </Box>
  //             </Badge>
  //           </Box>
  //         </Box>
  //         <Box>
  //           <Accordion expanded={expanded === 'panel01'} onChange={handleAccordianChange('panel01')} sx={{ borderRadius: "var(--border-radius-accordian)", '&:first-of-type': { borderRadius: "var(--border-radius-accordian)" }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" }, p:'.5rem 1rem'}}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
  //                 {(expanded === 'panel01') ? <Person2Icon color='primary' /> : <Person2Icon />}
  //               </Badge>
  //               <Box>
  //                 <Box>
  //                   <Typography fontWeight='700' variant='body1'>
  //                     Basic Details
  //                   </Typography>
  //                 </Box>
  //                 <Box>
  //                   <Typography variant='body2' color="text.secondary">
  //                     Please fill your basic details
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>

  //             <AccordionDetails>
  //               <BasicDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>

  //           </Accordion>
  //           <Accordion expanded={expanded === 'panel02'} onChange={handleAccordianChange('panel02')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
  //                 {(expanded === 'panel02') ? <FamilyRestroomIcon color='primary' /> : <FamilyRestroomIcon />}
  //               </Badge>
  //               <Box>
  //                 <Box>
  //                   <Typography fontWeight='bold' fontSize="medium" >
  //                     Family Details
  //                   </Typography>
  //                 </Box>
  //                 <Box>
  //                   <Typography fontSize="small" color="text.secondary">
  //                     Please fill your family details
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>

  //             <AccordionDetails>
  //               <FamilyDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>

  //           </Accordion>
  //           <Accordion expanded={expanded === 'panel03'} onChange={handleAccordianChange('panel03')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:last-of-type': { borderRadius: "var(--border-radius-accordian)" }, '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
  //                 {(expanded === 'panel03') ? <ContactEmergencyIcon color='primary' /> : <ContactEmergencyIcon />}

  //               </Badge>
  //               <Box>
  //                 <Box fontWeight='bold' fontSize="medium">
  //                   <Typography fontWeight='bold' fontSize="medium">
  //                     Emergency Contact Details
  //                   </Typography>
  //                 </Box>
  //                 <Box>
  //                   <Typography fontSize="small" color="text.secondary">
  //                     Please fill the emergency contact details
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>

  //             <AccordionDetails>
  //               <EmergencyDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>

  //           </Accordion>
  //         </Box>
  //       </>
  //     );

  //     case 1: return (
  //       <>
  //         <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
  //           <Box sx={{ width: "var(--width-full)" }}>
  //             <Typography variant="h5" fontWeight="700" fontSize="large" >
  //               Professional Details
  //             </Typography>
  //           </Box>
  //           <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
  //             <Box color="text.secondary" fontSize="medium">
  //               Please fill your professional details
  //             </Box>
  //             <Badge color="error">
  //               <Box display='inline-flex' sx={{alignItems:'center'}}>
  //                   <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
  //                   <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
  //               </Box>
  //             </Badge>
  //           </Box>
  //         </Box>
  //         <Box>
  //           <Accordion expanded={expanded === 'panel11'} onChange={handleAccordianChange('panel11')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>

  //                 {(expanded === 'panel11') ? <SchoolIcon color='primary' /> : <SchoolIcon />}

  //               </Badge>
  //               <Box>
  //                 <Box>
  //                   <Typography fontWeight='bold' fontSize="medium">
  //                     Educational Details
  //                   </Typography>
  //                 </Box>
  //                 <Box>
  //                   <Typography fontSize="small" color="text.secondary">
  //                     Please fill your educational details
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>

  //             <AccordionDetails>
  //               <EducationDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>

  //           </Accordion>
  //           <Accordion expanded={expanded === 'panel12'} onChange={handleAccordianChange('panel12')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>

  //                 {(expanded === 'panel12') ? <WorkIcon color='primary' /> : <WorkIcon />}
  //               </Badge>
  //               <Box>
  //                 <Box>
  //                   <Typography fontWeight='bold' fontSize="medium">
  //                     Work Experience
  //                   </Typography>
  //                 </Box>
  //                 <Box>
  //                   <Typography fontSize="small" color="text.secondary">
  //                     Please fill your work experience
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>

  //             <AccordionDetails>
  //               <ExperienceDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>

  //           </Accordion>
  //         </Box>
  //         <Card sx={{ padding: "var(--padding-all-1)", background: "var(--background-white)", borderRadius: "var(--border-radius-accordian)", marginTop: "var(--margin-top-1)",p:'1rem 2rem' }}>
  //           <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
  //             <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
  //               <UploadIcon />
  //             </Badge>
  //             <Box>
  //               <Box>
  //                 <Typography fontWeight='bold' fontSize="medium">
  //                   Upload Documents
  //                 </Typography>
  //               </Box>
  //               <Box>
  //                 <Typography fontSize="small" color="text.secondary">
  //                   Please upload your academic & work experience documents
  //                 </Typography>
  //               </Box>
  //             </Box>
  //           </Box>
  //           <Box>
  //             <Box sx={{ marginTop: "var(--margin-top-0_5)" }}>
  //               <Grid container spacing={2}>
  //                 <Grid item xs={10}>
  //                   <Button variant="contained" startIcon={<UploadFileIcon />} size="small" onClick={() => uploadDrawer('policy')} >
  //                     file upload
  //                   </Button>
  //                   <SharedDrawer id='policy'>
  //                     <Box sx={{ position: 'relative', minWidth: '25em', height: '100vh' }}>
  //                       <FileUploadDocument setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //                     </Box>
  //                   </SharedDrawer>
  //                 </Grid>
  //                 <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
  //                   <Box>
  //                     <FileDownloadIcon color="action" />
  //                   </Box>
  //                 </Grid>
  //               </Grid>
  //             </Box>
  //           </Box>
  //         </Card>
  //       </>
  //     );

  //     case 2: return (
  //       <>
  //         <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
  //           <Box sx={{ width: "var(--width-full)" }}>
  //             <Typography variant="h5" fontWeight="700" fontSize="large" >
  //               Medical Insurance Details
  //             </Typography>
  //           </Box>
  //           <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
  //             <Box color="text.secondary" fontSize="medium">
  //               Please fill insurance details
  //             </Box>
  //             <Badge color="error">
  //               <Box display='inline-flex' sx={{alignItems:'center'}}>
  //                 <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
  //                 <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
  //               </Box>
  //             </Badge>
  //           </Box>
  //         </Box>
  //         <Box>
  //           <Accordion expanded={expanded === 'panel21'} onChange={handleAccordianChange('panel21')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>

  //                 {(expanded === 'panel21') ? <MedicationIcon color='primary' /> : <MedicationIcon />}
  //               </Badge>
  //               <Box>
  //                 <Box>
  //                   <Typography fontWeight='bold' fontSize="medium">
  //                     Medical Insurance Details
  //                   </Typography>
  //                 </Box>
  //                 <Box>
  //                   <Typography fontSize="small" color="text.secondary">
  //                     Please fill your medical insurance details
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>

  //             <AccordionDetails>
  //               <MedicalDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>

  //           </Accordion>
  //         </Box>
  //       </>
  //     );

  //     case 3: return (
  //       <>
  //         <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
  //           <Box sx={{ width: "var(--width-full)" }}>
  //             <Typography variant="h5" fontSize="large" fontWeight="700" >
  //               Financial Details
  //             </Typography>
  //           </Box>
  //           <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
  //             <Box color="text.secondary" fontSize="medium">
  //               Please fill your bank details
  //             </Box>
  //             <Badge color="error">
  //               <Box display='inline-flex' sx={{alignItems:'center'}}>
  //                 <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
  //                 <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
  //               </Box>
  //             </Badge>
  //           </Box>
  //         </Box>
  //         <Box>
  //           <Accordion expanded={expanded === 'panel31'} onChange={handleAccordianChange('panel31')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
  //                 {(expanded === 'panel31') ? <AccountBalanceIcon color='primary' /> : <AccountBalanceIcon />}
  //               </Badge>
  //               <Box>
  //                 <Box>
  //                   <Typography fontWeight='700' fontSize="medium">
  //                     Financial Details
  //                   </Typography>
  //                 </Box>
  //                 <Box>
  //                   <Typography fontSize="small" color="text.secondary">
  //                     Please fill your financial details
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>

  //             <AccordionDetails>
  //               <FinancialDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>

  //           </Accordion>
  //         </Box>
  //       </>
  //     );

  //     case 4: return (
  //       <>
  //         <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
  //           <Box sx={{ width: "var(--width-full)" }}>
  //             <Typography variant="h5" fontWeight="700" fontSize="large" >
  //               Employee Provident Fund Details
  //             </Typography>
  //           </Box>
  //           <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
  //             <Box color="text.secondary" fontSize="medium">
  //               Please fill your EPF details
  //             </Box>
  //             <Badge color="error">
  //               <Box display='inline-flex' sx={{alignItems:'center'}}>
  //                 <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
  //                 <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
  //               </Box>
  //             </Badge>
  //           </Box>
  //         </Box>
  //         <Box>
  //           <Accordion expanded={expanded === 'panel41'} onChange={handleAccordianChange('panel41')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
  //             <AccordionSummary
  //               expandIcon={<ExpandMoreIcon />}
  //               aria-controls="panel1bh-content"
  //               id="panel1bh-header"
  //             >
  //               <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
  //                 {(expanded === 'panel41') ? <BadgeIcon color='primary' /> : <BadgeIcon />}
  //               </Badge>
  //               <Box>
  //                 <Box fontWeight='bold' fontSize="medium">
  //                   Employee Provident Fund Details
  //                 </Box>
  //                 <Box>
  //                   <Typography fontSize="small" color="text.secondary">
  //                     Please fill your EPF details
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             </AccordionSummary>


  //             <AccordionDetails>
  //               <EpfDetails setCurrentJSXData={setCurrentJSXData} checkError={checkError} />
  //             </AccordionDetails>


  //           </Accordion>
  //         </Box>
  //       </>
  //     );


  //     default: return (<></>);
  //   }
  // }

  // const [currentStepJSX, setCurrentStepJSX] = React.useState(getStepJSX(setCurrentJSXData,checkError));

  React.useEffect(() => {
    // setCurrentStepJSX(getStepJSX(setCurrentJSXData,checkError));
    setCheckError(0);
  }, [activeStep, expanded, educationalDegrees, membersFamily, workExperiences, sameAddress]);

  const goToPreviousStep = () => {
    if (activeStep !== 0) {
      setExpanded('panel' + (activeStep - 1) + '1');
      setActiveStep(activeStep - 1);
    }
  };

  const goToNextStep = () => {
    if (activeStep !== steps.length - 1) {
      let comp = completed;
      comp[activeStep] = true;
      setCompleted(comp);
      setExpanded('panel' + (activeStep + 1) + '1');
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <>
      <Box sx={{ padding: 'var(--padding-all-1)' }}>
        <Box sx={{ width: "var(--width-full)", borderRadius: "var(--border-radius-form)", padding: "var(--padding-vertical-1)", background: "var(--background-page-btn-section)" }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', padding: "var(--padding-horizontal-1)" }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: "var(--column-gap)" }}>
              {activeStep === 0 ?
                <IconButton onClick={() => { goToPreviousStep(); }} size="small" sx={{ background: "var(--background-page-btn-inactive)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-inactive)" } }}>
                  <KeyboardArrowLeftIcon />
                </IconButton> : <IconButton onClick={() => { goToPreviousStep(); }} size="small" sx={{ background: "var(--background-page-btn-active)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-active-hover)" } }}>
                  <KeyboardArrowLeftIcon sx={{ color: 'var(--color-white)' }} />
                </IconButton>
              }
              {activeStep !== steps.length - 1 ?
                <IconButton onClick={() => { goToNextStep(); }} size="small" sx={{ background: "var(--background-page-btn-active)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-active-hover)" } }}>
                  <KeyboardArrowRightIcon sx={{ color: 'var(--color-white)' }} />
                </IconButton> : <IconButton onClick={() => { goToNextStep(); }} size="small" sx={{ background: "var(--background-page-btn-inactive)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-inactive)" } }}>
                  <KeyboardArrowRightIcon />
                </IconButton>

              }
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: "var(--column-gap)" }}>
              <Button size="small" sx={{ '&:hover': { background: "var(--background-page-btn-inactive-hover)" } }}>
                Cancel
              </Button>
              <Button variant="outlined" size="small" sx={{ '&:hover': { background: "var(--background-page-btn-inactive-hover)" } }}>
                Reset
              </Button>
              <Button variant="contained" size="small" sx={{ '&:hover': { background: "var(--background-page-btn-active-hover)" } }} onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
        <Card sx={{ width: "var(--width-full)", marginTop: "var(--margin-top-2)", backgroundColor: "var(--background-white)", borderRadius: "var(--border-radius-accordian)", paddingTop: "var(--padding-top-2)", paddingBottom: "var(--padding-bottom-1)",mb:'var(--padding-bottom-1)', position: 'relative', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)' }}>
          <Box sx={{ position: 'absolute', left: 'clamp(calc(20% - 2rem),calc(20% * ' + (activeStep + 1) + ' - 2rem),calc(80% - 2rem)) ', top: '0rem' }}>
          <img src={manfollow} alt="My Image" style={{height:"3rem"}}/>
          </Box>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={'steps' + index} completed={completed[index] && activeStep !== index}>
                <StepLabel><Typography variant='body1'>{label}</Typography></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Card>
        <Box>
            {/* {currentStepJSX} */}
            {/* switch (activeStep) {
      case 0: return  */}
      { activeStep == 0 && (
        <>
          <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
            <Box sx={{ width: "var(--width-full)" }}>
              <Typography variant="h5" fontWeight="700" fontSize="large">
                Personal Details
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
              <Box color="text.secondary" fontSize="medium">
                Please fill your personal details
              </Box>
              <Badge color="error">
                <Box display='inline-flex' sx={{alignItems:'center'}}>
                  <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
                  <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
                </Box>
              </Badge>
            </Box>
          </Box>
          <Box>
            <Accordion expanded={expanded === 'panel01'} onChange={handleAccordianChange('panel01')} sx={{ borderRadius: "var(--border-radius-accordian)", '&:first-of-type': { borderRadius: "var(--border-radius-accordian)" }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" }, p:'.5rem 1rem'}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
                  {(expanded === 'panel01') ? <Person2Icon color='primary' /> : <Person2Icon />}
                </Badge>
                <Box>
                  <Box>
                    <Typography fontWeight='700' variant='body1'>
                      Basic Details
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color="text.secondary">
                      Please fill your basic details
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <BasicDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>

            </Accordion>
            <Accordion expanded={expanded === 'panel02'} onChange={handleAccordianChange('panel02')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
                  {(expanded === 'panel02') ? <FamilyRestroomIcon color='primary' /> : <FamilyRestroomIcon />}
                </Badge>
                <Box>
                  <Box>
                    <Typography fontWeight='bold' fontSize="medium" >
                      Family Details
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="small" color="text.secondary">
                      Please fill your family details
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <FamilyDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>

            </Accordion>
            <Accordion expanded={expanded === 'panel03'} onChange={handleAccordianChange('panel03')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:last-of-type': { borderRadius: "var(--border-radius-accordian)" }, '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
                  {(expanded === 'panel03') ? <ContactEmergencyIcon color='primary' /> : <ContactEmergencyIcon />}

                </Badge>
                <Box>
                  <Box fontWeight='bold' fontSize="medium">
                    <Typography fontWeight='bold' fontSize="medium">
                      Emergency Contact Details
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="small" color="text.secondary">
                      Please fill the emergency contact details
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <EmergencyDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>

            </Accordion>
          </Box>
        </>
      )}
      {/* // ); */}

      {/* case 1: return ( */}
      { activeStep == 1 && (
        <>
          <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
            <Box sx={{ width: "var(--width-full)" }}>
              <Typography variant="h5" fontWeight="700" fontSize="large" >
                Professional Details
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
              <Box color="text.secondary" fontSize="medium">
                Please fill your professional details
              </Box>
              <Badge color="error">
                <Box display='inline-flex' sx={{alignItems:'center'}}>
                    <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
                    <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
                </Box>
              </Badge>
            </Box>
          </Box>
          <Box>
            <Accordion expanded={expanded === 'panel11'} onChange={handleAccordianChange('panel11')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>

                  {(expanded === 'panel11') ? <SchoolIcon color='primary' /> : <SchoolIcon />}

                </Badge>
                <Box>
                  <Box>
                    <Typography fontWeight='bold' fontSize="medium">
                      Educational Details
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="small" color="text.secondary">
                      Please fill your educational details
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <EducationDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>

            </Accordion>
            <Accordion expanded={expanded === 'panel12'} onChange={handleAccordianChange('panel12')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>

                  {(expanded === 'panel12') ? <WorkIcon color='primary' /> : <WorkIcon />}
                </Badge>
                <Box>
                  <Box>
                    <Typography fontWeight='bold' fontSize="medium">
                      Work Experience
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="small" color="text.secondary">
                      Please fill your work experience
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <ExperienceDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>

            </Accordion>
          </Box>
          <Card sx={{ padding: "var(--padding-all-1)", background: "var(--background-white)", borderRadius: "var(--border-radius-accordian)", marginTop: "var(--margin-top-1)",p:'1rem 2rem' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
                <UploadIcon />
              </Badge>
              <Box>
                <Box>
                  <Typography fontWeight='bold' fontSize="medium">
                    Upload Documents
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize="small" color="text.secondary">
                    Please upload your academic & work experience documents
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginTop: "var(--margin-top-0_5)" }}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Button variant="contained" startIcon={<UploadFileIcon />} size="small" onClick={() => uploadDrawer('policy')} >
                      file upload
                    </Button>
                    <SharedDrawer id='policy'>
                      <Box sx={{ position: 'relative', minWidth: '25em', height: '100vh' }}>
                        <FileUploadDocument setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
                      </Box>
                    </SharedDrawer>
                  </Grid>
                  <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
                    <Box>
                      <FileDownloadIcon color="action" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </>
      ) }
      {/* ); */}

      {/* case 2: return ( */}
      { activeStep == 2 && (
        <>
          <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
            <Box sx={{ width: "var(--width-full)" }}>
              <Typography variant="h5" fontWeight="700" fontSize="large" >
                Medical Insurance Details
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
              <Box color="text.secondary" fontSize="medium">
                Please fill insurance details
              </Box>
              <Badge color="error">
                <Box display='inline-flex' sx={{alignItems:'center'}}>
                  <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
                  <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
                </Box>
              </Badge>
            </Box>
          </Box>
          <Box>
            <Accordion expanded={expanded === 'panel21'} onChange={handleAccordianChange('panel21')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>

                  {(expanded === 'panel21') ? <MedicationIcon color='primary' /> : <MedicationIcon />}
                </Badge>
                <Box>
                  <Box>
                    <Typography fontWeight='bold' fontSize="medium">
                      Medical Insurance Details
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="small" color="text.secondary">
                      Please fill your medical insurance details
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <MedicalDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>

            </Accordion>
          </Box>
        </>
      ) }
      {/* ); */}

      {/* case 3: return ( */}
      { activeStep == 3 && (
        <>
          <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
            <Box sx={{ width: "var(--width-full)" }}>
              <Typography variant="h5" fontSize="large" fontWeight="700" >
                Financial Details
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
              <Box color="text.secondary" fontSize="medium">
                Please fill your bank details
              </Box>
              <Badge color="error">
                <Box display='inline-flex' sx={{alignItems:'center'}}>
                  <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
                  <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
                </Box>
              </Badge>
            </Box>
          </Box>
          <Box>
            <Accordion expanded={expanded === 'panel31'} onChange={handleAccordianChange('panel31')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
                  {(expanded === 'panel31') ? <AccountBalanceIcon color='primary' /> : <AccountBalanceIcon />}
                </Badge>
                <Box>
                  <Box>
                    <Typography fontWeight='700' fontSize="medium">
                      Financial Details
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontSize="small" color="text.secondary">
                      Please fill your financial details
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                <FinancialDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>

            </Accordion>
          </Box>
        </>
      )}
      {/* ); */}

      {/* case 4: return ( */}
      { activeStep == 4 && (
        <>
          <Box sx={{ padding: "var(--padding-top-accordian-header)" }}>
            <Box sx={{ width: "var(--width-full)" }}>
              <Typography variant="h5" fontWeight="700" fontSize="large" >
                Employee Provident Fund Details
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', rowGap: "var(--row-gap)" }}>
              <Box color="text.secondary" fontSize="medium">
                Please fill your EPF details
              </Box>
              <Badge color="error">
                <Box display='inline-flex' sx={{alignItems:'center'}}>
                  <img className='emp-details-coin-img' src={AnimatedCoin} alt="React Logo" />
                  <Typography variant='body1' fontWeight='var(--font-weight-6)'>20 </Typography>
                </Box>
              </Badge>
            </Box>
          </Box>
          <Box>
            <Accordion expanded={expanded === 'panel41'} onChange={handleAccordianChange('panel41')} sx={{ marginTop: "var(--margin-top-1)", borderRadius: "var(--border-radius-accordian)", '&:before': { opacity: 0 }, "&.Mui-expanded": { boxShadow: "var(--box-shadow-accordian-open)" },p:'.5rem 1rem' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Badge sx={{ marginRight: "var(--margin-right-1)" }}>
                  {(expanded === 'panel41') ? <BadgeIcon color='primary' /> : <BadgeIcon />}
                </Badge>
                <Box>
                  <Box fontWeight='bold' fontSize="medium">
                    Employee Provident Fund Details
                  </Box>
                  <Box>
                    <Typography fontSize="small" color="text.secondary">
                      Please fill your EPF details
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>


              <AccordionDetails>
                <EpfDetails setCurrentJSXData={setCurrentJSXData} currentJSXData={currentJSXData} checkError={checkError} />
              </AccordionDetails>


            </Accordion>
          </Box>
        </>
      )}
    {/* //   ); */}


    {/* //   default: return (<></>);
    // } */}
        </Box>
        {/* <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{ width: "var(--width-full)", marginTop: "var(--margin-top-2)", backgroundColor: "var(--background-white)", borderRadius: "var(--border-radius-accordian)", paddingTop: "var(--padding-top-2)", paddingLeft: "var(--padding-top-2)", paddingBottom: "var(--padding-bottom-1)", position: 'relative' }}>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={'steps' + index} completed={completed[index] && activeStep !== index}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box>
                  {currentStepJSX}
                </Box>
              </Grid>
        </Grid> */}
          {/* <DynamicDrawer data={uploadData} PaperProps={{ sx: { minWidth: "25%" }, }} /> */}
      </Box>
    </>
  )
}

export default EmployeeDetails