import React, { useState, useEffect, useRef } from "react";
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishIcon from '@mui/icons-material/Publish';
import PDF from '../../assets/images/PDF.jpg';
import  UploadDocument  from '../../components/drawerBody/UploadDocument';
import { Button, Box, Grid, Alert, Stack, Typography, Avatar, Link, Card } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { openSnackbar } from "../../features/snakebar/snackbarSlice";
import DynamicSnackbar from "../../features/snakebar/Snackbar";
import ApiService from "../../utils/ApiService";
import { CircularProgress } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2563EB',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000000',
    fontWeight: '400',
    boxShadow: '-1px 0px 6px #aaa',
    // padding: "15px",
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #2563EB',
    borderRadius: 2,
  },
}));


const PolicyReviewDrawer = (parentId) => {
  // if (id) {
    // console.log("ID received:", taskId);
    // Fetch data or perform other actions using the id
  // }
  const policyDataTaskId = useSelector((state) => state?.drawer?.data?.taskId); // {} by default
// console.log({openSnackbar})
const [flag, setFlag] = useState(false)
  // console.log({policyDataTaskId});
  function generate(element) {
    return [0].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }
  // const [dense, setDense] = React.useState(false);s

  /////////////-----upload file---////////////////
  // const [image, setImage] = useState();
  // const [preview, setPreview] = useState();

  //create reference
  // const fileInputRef = useRef();
  //simulate click on this input  -attach ref to input
  // to put value in state we need to put onChange

  // useEffect(() => {
  //   if (image) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreview(reader.result);
  //     };

  //     //reader.readAsArrayBuffer <-  arraybuffer
  //     reader.readAsDataURL(image); //represented as a base64string
  //   } else {
  //     setPreview(null);
  //   }
  // }, [image]);

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  //stack item//
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  //

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  // console.log(imagePreviews)
  const [message, setMessage] = useState([]);
  const [fileUploadData, setfileUploadData] = useState();
  const [categoriesName, setCategoriesName] = useState([]);
  const progressInfosRef = useRef(null);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [extensionErr, setRxtensionErr] = useState(false)
  const categoryListData = useSelector((state) => (state.category));
  const userId = useSelector((state) => state?.user?.userListName.userId);
  // console.log(userId)
  const [getUploadDoc, setGetUploadDoc] = useState([]);
  // console.log(getUploadDoc)
  const [corporateDocumentId,setCorporateDocumentId] = useState('');
  const [getFilteredData, setFilteredData] = useState([]);

  let obj = [0, 1, 2, 3, 4];
  const allowedExtensions = ['.png', '.jpg', '.jpeg', '.gif'];
  const [validCategory, setValidCategory] = useState([])

  const selectFiles = (event) => {
    const file = event.target.files[0];
    // console.log(('a1', { file }))

    if (file) {
      const invalidFiles = [];
      const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
      const fileExtension = '.' + file.name.split('.').pop();

      if (allowedExtensions.includes(fileExtension.toLowerCase()) && fileSizeInMB <= 3) {
        const imageUrl = URL.createObjectURL(file);
        const dataSrc = getDataSrcFromImageUrl(imageUrl);
        setSelectedFiles([file]);
        setImagePreviews([imageUrl]);
        setProgressInfos({ val: [] });
        setMessage([]);
        setInputKey(inputKey + 1);
        setfileUploadData([file]);
        const progressInfo = {
          percentage: 0,
          fileName: file.name,
          fileSize: file.size
        };
        progressInfosRef.current = {
          val: [progressInfo],
        };
        upload(0, file);
        setRxtensionErr(false);
      } else {
        event.target.value = null; // Clear the input
        setRxtensionErr(fileSizeInMB <= 3 ? 'File(s) not supported. Upload a supported format.' : 'File size exceeds the limit (3MB).');
      }
    }
  };
  const getDataSrcFromImageUrl = (imageUrl, dataSrc) => {
    const imageData = imageUrl.split(',');
    if (imageData.length === 2) {
      const base64Data = imageData[1];
      const formatData = imageData[0].match(/:(.*?);/);
      if (formatData && formatData[1]) {
        const dataSrc = `data:${formatData[1]};base64,${base64Data}`;
        return dataSrc;
      }
    }
    return null;
  };

  const handleRemove = (index) => {
    let removeFiles = [...selectedFiles];
    removeFiles.splice(index, 1);
    // setState({ uploadedFiles });
    setSelectedFiles([...removeFiles]);

    let removeImagePreviews = [...imagePreviews];
    removeImagePreviews.splice(index, 1);
    setImagePreviews(removeImagePreviews);

    let removeProgressInfos = [...progressInfos.val];
    removeProgressInfos.splice(index, 1);
    setProgressInfos({ val: removeProgressInfos });
  }

  function formatBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];

    setProgressInfos({ val: _progressInfos });

  };
  const inputElementsRef = useRef([]);
  useEffect(() => {
    const inputElement = document.getElementById('tags-outlined');
    if (inputElement)
      inputElement.setAttribute("enterkeyhint", "enter");
    // console.log({ inputElement });
  }, [])

  const [inputKey, setInputKey] = useState(0);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();



    const files = event.dataTransfer.files;

    // Validate files and call selectFiles function
    selectFiles({ ...event, target: { ...event.target, files: files } });
  };
//  { related: 'corporateDocument',userId: userListId }
  useEffect(() => {
    ApiService.get('corporateDocument').then(res => {
        let response = res.data.data
        setGetUploadDoc(response)
       
        const filteredData = response.filter(item => item.taskId == policyDataTaskId);
        // console.log(filteredData)
        setFilteredData(filteredData)
        setCorporateDocumentId(filteredData[0].id)
    }).catch((error) => {
        console.log(error);
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }))
    })
},[])

// const handleDownload = (fileUrl, fileName) => {
//   fetch(fileUrl)
//     .then(response => response.blob())
//     .then(blob => {
//       const blobUrl = window.URL.createObjectURL(blob);
//       const anchor = document.createElement('a');
//       anchor.href = blobUrl;
//       anchor.download = fileName;
//       anchor.click();
//       window.URL.revokeObjectURL(blobUrl);
//     })
//     .catch(error => console.error('Error downloading file:', error));
// };

const getIcon = (url) => {

  const type = url?.split('.')?.[(url?.split('.')?.length ?? 1) - 1];
  switch(type){
    case "pdf" : return "/images/pdf.png";
    case "docx" : return "/images/docx.png";
    case "xlsx" : return "/images/xls.png";
  }
}

// const handleDownload = async (fileUrl, fileName) => {

//   // const canOpen = ['image/png', 'image/jpg', 'image/jpeg', ];
//   const xhr = new XMLHttpRequest();
//     xhr.open('GET', fileUrl, true);
//     xhr.responseType = 'blob';

//     xhr.onprogress = (event) => {
//       // console.log({event})
//      };

//     xhr.onload = () => {
//       const blob = xhr.response;
//       const url = URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.href = url;
//       // const videoType = extractVideoFileFormat(fileUrl);
//       link.setAttribute('download', fileName); // Adjust filename as needed
//       link.click();
      
//       URL.revokeObjectURL(url);
//       // dispatch(closeDownloadBar());
//       // console.log("Close should be called");
//     };

//     xhr.onerror = () => {
//       // dispatch(closeDownloadBar());
//       // console.log("Close should be called");
//       console.error('Error downloading video');
//     };

//     xhr.send();

// };

const handleDownload = (url, name) => {
  // console.log({url,name})
  fetch(url)
      .then(response => response.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error downloading image:', error));
}

const uploadDoc = () => {
  let formData = new FormData();
  // formData.append('app', getAppToken());

  if (fileUploadData) {
    formData.append('file', fileUploadData[0]);
  }
  formData.append('activeStatus', 1);
  formData.append('userId', userId);
  formData.append('corporateDocumentId' , corporateDocumentId)
  setFlag(true)
  // try {
  //   const response = ApiService.postAdopter('uploadDocByUser', formData);
  ApiService.postAdopter('uploadDocByUser', formData).then(response => {
    // Handle successful response
    // console.log(response)
    setFlag(false)
    handleDrawerClose() 
    // dispatch(openSnackbar({ dataLoad: true, message: 'Document uploaded successfully', severity: 'success' }));
    dispatch(openSnackbar({ dataLoad: true, message: response.data.data.title, severity: "info" }));
   
  }).catch((error) => {
    // Handle errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const errorMessage = error.response.data.message || 'Something went wrong';
      dispatch(openSnackbar({ dataLoad: true, message: errorMessage, severity: 'error' }));
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      dispatch(openSnackbar({ dataLoad: true, message: 'No response received', severity: 'error' }));
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
      dispatch(openSnackbar({ dataLoad: true, message: 'Error setting up request', severity: 'error' }));
    }
    setFlag(false)
  });
};



  return (
    <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer)', lg:'var(--width-drawer)'}, overflowY: "clip"}}>
    <Stack sx={{ backgroundColor: "var(--color-lightblue)" }}>
              <Box sx={{p:'0.25rem .5rem'}}>
                  <Grid container direction="row" alignItems="center">
                      <Grid item xs={10}>
                          <Box pl='1rem' display='flex' sx={{flexDirection:'column'}}>
                              <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Policy review and self attested documents</Typography>
                              <Typography variant="body2" display="block" color='var(--color-greyblue)' >Review company policy and self attest all related documents</Typography>
                          </Box>
                      </Grid>
                      <Grid item xs={2} sx={{display:'flex', justifyContent:'flex-end'}}>
                          <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                              <CloseIcon sx={{color:'var(--color-black)'}}/>
                          </IconButton>
                      </Grid>
                  </Grid>
              </Box>
    </Stack>
      {/* <Stack sx={{ backgroundColor: "#DBEAFE" }}>
        <Box sx={{ mx: '15px', mt:'5px' }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <div><Typography variant="h8" color="text.primary" fontWeight="bold" display="block" gutterBottom>Policy review and self attested documents</Typography></div>
              <div><Typography variant="h8" color="text.secondary" display="block" gutterBottom>Review company policy and self attest all related documents</Typography></div>
            </Grid>
            <Grid item xs={2}>
              <IconButton sx={{ ml: "40px" }} aria-label="upload picture" component="label" onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Stack> */}
      <Box sx={{ maxHeight:'40rem', overflow:'auto', pt:'var(--equal-hw1)' }}>
      <Stack sx={{ boxShadow: 'none' }} >
        <List>
          {/* {generate( */}
            <Card>
             {getFilteredData.map((item) => {
                return (
              <ListItem key={item.taskId} secondaryAction={<IconButton edge="end" aria-label="download" onClick={() => handleDownload(item.fileUrl,item.fileName)}><FileDownloadIcon color="primary" /></IconButton>}>
                <ListItemAvatar sx={{height:'40px'}}><img src={getIcon(item.fileUrl)} alt={item.name} style={{height:'30px'}} /></ListItemAvatar>
                <ListItemText primary={item.name} secondary={formatBytes(item.size)} />
              </ListItem>
                )})
}
            </Card>
           {/* )}  */}
        </List>
      </Stack>
      <Stack sx={{ boxShadow: 'none' }}>
        <List>
          {generate(
            <Box>
              <ListItem sx={{pb:'0rem'}}>
                <ListItemAvatar sx={{minWidth:'2rem'}}>
                  <PublishIcon color="primary" />
                </ListItemAvatar>
                <ListItemText 
                  primary={<Typography variant='body1' fontWeight='var(--font-weight-7)'>Upload Document</Typography>}
                  secondary="Please Upload Self attested document here"
                />
              </ListItem>
            </Box>
          )}
        </List>
      </Stack>
      {/* <Stack sx={{ boxShadow: 'none' }}>
        <Item sx={{ border: "2px dashed rgba(0, 0, 0, 0.12)", borderRadius: '12px', ':hover': { backgroundColor: "#DBEAFE", borderColor:'var(--color-mui-primary-blue)' } }}>
          <Box>
            <IconButton color="primary" aria-label="upload picture" component="label">
              <UploadFileIcon />
            </IconButton>
          </Box>
          <Box><span><Button variant="text">Click to upload </Button></span> <span> or drag and drop </span></Box>
          <Box><Typography variant="h8" color="text.secondary" display="block" gutterBottom>Only SVG, PNG, JPG or GIF (max. 3MB)</Typography></Box>
        </Item>
      </Stack>  */}
       <Box sx={{ minWidth: { sm: 'var(--width-drawer-sm)', md: 'var(--width-drawer)', lg: 'var(--width-drawer)' } }}>
      <Box sx={{ padding: "15px", maxHeight: '40rem', overflow: 'auto', pt: 'var(--equal-hw1)' }}>
        <Box sx={{ mb: 2 }}>
          <Box>
            <Box>
              <label htmlFor="fileUploadKM">
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: "0.5rem",
                    border: "2px dashed rgba(0, 0, 0, 0.12)",
                    borderRadius: "12px",
                    backgroundColor: "rgba(201, 201, 201, 0.1)",
                    ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                  }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                    <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                      <UploadFileIcon color="primary" />
                    </Avatar>
                  </Box>
                  <Box className="upload-btn-wrapper">
                    <span>
                      <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                        Click to upload
                      </Link>
                      <input
                        id="fileUploadKM"
                        key={inputKey}
                        type="file"
                        name="myfile"
                        multiple
                        accept=".png, .jpg, .jpeg, .gif"
                        // accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={selectFiles}
                      />

                    </span>
                    <span> or drag and drop *</span>
                  </Box>
                  <Box m='1rem 0rem 0rem'>
                    <Typography variant="caption" color="text.secondary" display="block" gutterBottom> SVG, PNG, JPG or GIF (max. 3mb)</Typography>
                  </Box>
                  <Box mb='1rem'>
                    {extensionErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                      {extensionErr}
                    </Alert> : ""}
                  </Box>
                </Stack>
              </label>
            </Box>
          </Box>

          <Box sx={{ ml: '3rem' }}>
            {progressInfos &&
              progressInfos.val.length > 0 &&
              progressInfos.val.map((progressInfo, index) => (
                <Box key={index} sx={{ my: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                      <img height={60} width={60} src={imagePreviews} className="preview" alt={"image-" + index} key={index} />
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Box>
                        <Box>
                          <HtmlTooltip title={progressInfo.fileName}>
                            <span>
                              {progressInfo.fileName.length > 28
                                ? progressInfo.fileName.substring(0, 28) + '...'
                                : progressInfo.fileName}
                            </span>
                          </HtmlTooltip>
                        </Box>
                        <Box sx={{ my: 1 }}>
                          <Stack direction="row" spacing={2}>
                            <span><li>{formatBytes(progressInfo.fileSize)}</li></span>

                            <span>
                              {message.length > 0 && (
                                <Box className="alert alert-secondary" role="alert">
                                  <li>
                                    {message[index]}
                                  </li>
                                </Box>
                              )}
                            </span>
                          </Stack>
                        </Box>
                        <Box>
                          <LinearProgress variant="determinate" value={100} sx={{ '& .MuiLinearProgress-bar': { backgroundColor: 'red' } }} />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box sx={{ mb: 1 }}>
                        <IconButton aria-label="upload picture" component="label" onClick={() => handleRemove(index)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
        </Box>
      
      </Box>
      <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '4rem', height: '4rem' }}>
        <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
        {flag == false ?
          <Button variant="contained" size="small" onClick={() => uploadDoc()}
          disabled={
            (!selectedFiles || !fileUploadData[0]) || !imagePreviews.length
          }
          >SUBMIT</Button>
          :
          <Button variant="contained">
              <CircularProgress color={'success'} size="20px" />
          </Button>
  }
          <Button variant="text" size="small" onClick={handleDrawerClose} >CANCEL</Button>
        </Stack>
      </Box>
    </Box>
      </Box>
      {/* <Box sx={{backgroundColor:'white', width:'100%', position:'absolute', bottom:'0', mt:'4rem', height:'4rem'}}>
        <Stack sx={{ mx:'1rem', pt:'1rem'}} direction="row" spacing={1}>
          <Button variant="contained" size="small">SUBMIT</Button>
          <Button variant="text" size="small" onClick={handleDrawerClose} >CANCEL</Button>
        </Stack>
      </Box> */}
      
    </Box>

  )
}

export default PolicyReviewDrawer