import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Button } from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

let detaisData = [];
const WorkExperienceDetails = ({ showErrors, setShowErrors, index }) => {

  let experienceDetailsModel = [
    { label: 'Job Title', type: "textField", size: 'small', validators:[{name:'required'}], value: '' },
    { label: 'Company Name', type: "textField", size: 'small', validators:[{name:'required'}], value: '' },
    { label: 'Employed From', type: "dateField", validators:[{name:'required'}], value: '' },
    { label: 'Employed To ', type: "dateField", validators:[{name:'required'}], value: '' },
    { label: 'Job Location', type: "textField", validators:[{name:'required'}], size: 'small', value: '' },
  ];

  let experienceDetailsHelper = new FormHelper({ model: experienceDetailsModel, id: 'experienceDetails' });

  const [experienceDetailsModelData, setExperienceDetailsModelData] = useState(experienceDetailsModel);

  const valueChangeListner = () => {
    let experienceDetailsHelper = experienceDetailsModelData.reduce((prev,curr) => {
      prev[curr.label] = curr.value;
      return prev;
    }, []);

    var error = false;
    var errorMessage = false;
    
    for (let i = 0; i < experienceDetailsModelData.length; i++) {
        for (let j = 0; j < experienceDetailsModelData[i]?.validation?.length; j++) {
            var res = experienceDetailsModelData[i]?.validation[j](experienceDetailsModelData[i].value);
            if (res.error) {
                error = res.error;
                errorMessage = res.errorMessage;
                break;
            }
        }
    }

    // console.log({experienceDetailsHelper});
    if (error) {
      setShowErrors(showErrors + 1);
      // setDataInIndex({error: true});
      detaisData[index] = {error: true};
      return;
    }

    // console.log({ experienceDetailsHelper });
    // setDataInIndex(experienceDetailsHelper);
    detaisData[index] = experienceDetailsHelper;

  }

  
  useEffect(() => {
    valueChangeListner();
  }, [showErrors])

  return(<>
    <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {setExperienceDetailsModelData(data);  }} {...experienceDetailsHelper.model} />
  </>)

}


const ExperienceDetails = ({ setCurrentJSXData, currentJSXData, checkError }) => {

  // const [employedFrom, setEmployedFrom] = React.useState([dayjs('2022-04-07')]);
  // const [employedTo, setEmployedTo] = React.useState([dayjs('2022-04-07')]);
  const [workExperiences, setWorkExperiences] = React.useState([1]);
  const [isTouched, setIsTouched] = React.useState(false);
  const [isFilled, setIsFilled] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(0);

//   experienceDetailsHelper.isTouched.touched.subscribe(() => {
//    setIsTouched(true);
//  })

//  experienceDetailsHelper.isFilled.filled.subscribe(() => {
//      setIsFilled(experienceDetailsHelper.isFilled.filled.get())
//    })

  const addNewWorkExperience = () => {
     setWorkExperiences([...workExperiences, 1]);
    //  setIsFilled(false)
   }

   const removeExperience = (i) => {
     let experienceMembers = [...workExperiences];
     experienceMembers.splice(i, 1);
     if (!experienceMembers.length)
     experienceMembers.push(1);
     setWorkExperiences(experienceMembers);
    //  setIsFilled(true)
   }

   const valueChangeListner = () => {
    setShowErrors(showErrors + 1);
    
    setTimeout(() => {
      // console.log("Suraj", {detaisData});
      let checkError = detaisData.reduce((prev,curr) => {
        if(curr.error){
          prev = true;
        }
        return prev;
      }, false);
      console.log("Suraj", {detaisData,checkError})
      if(!checkError){
        setCurrentJSXData({...currentJSXData, experienceDetails: detaisData})
      }
    }, 100)
  }

  useEffect(() => {
    console.log("Akas top", { checkError });
    if (checkError) {
      valueChangeListner();
    }
  }, [checkError])

     return(

          <>
             <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
               {workExperiences.map((v,i) => {
                 return <Box key={'workExperiences' + i} component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <Box sx={{ gridColumn: 'span 3' }} id={v + "jds"}>
                   <Grid container spacing={2} display='flex'>
                         <Grid item sx={{display:'flex', alignContent:'center', flexWrap:'wrap'}} xs={10}>
                         <Typography variant='h8' fontWeight={800}>Experience {i+1}</Typography>
                         </Grid>
                         <Grid item xs={2} className="dashboardStyle">
                             <IconButton aria-label="Example" onClick={() => removeExperience(i)} >
                             {/* disabled={!isTouched} */}
                                     <DeleteIcon icon={faEllipsisV}  size="small" />
                             </IconButton>
                         </Grid>
                   </Grid>
                   </Box>
                   {/* <RenderHelper {...experienceDetailsHelper.model} /> */}
                   <WorkExperienceDetails key={v} showErrors={showErrors} setShowErrors={setShowErrors} index={i} />
                 </Box>
               })}
               <Box sx={{ border: "var(--border-add-btn)", borderRadius: "var(--border-radius-add-btn)", display: "flex", justifyContent: "center" }}>
                 <Button sx={{ minWidth: "var(--min-width-15)" }} startIcon={<AddIcon />} onClick={addNewWorkExperience} >
                 {/* disabled={!isFilled} */}
                   Add Experience
                 </Button>
               </Box>
             </Box>
          </>

     );
}    

export default ExperienceDetails