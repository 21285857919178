import React, { useEffect,useState } from 'react'
import {
    Box, Paper, LinearProgress, Typography, useMediaQuery, CardContent, styled, Card, Stack, IconButton, Grid, Button, Badge, Avatar, Dialog, DialogTitle, DialogContent, DialogActions,
    Close as CloseIcon, DonutLargeSharp as DonutLargeSharpIcon, KeyboardBackspace as KeyboardBackspaceIcon, UploadFile as UploadFileIcon, Edit as EditIcon, ModeEditSharp as ModeEditSharpIcon, Delete as DeleteIcon
} from '../../utils/AllImportsHelper';
import ProfileDummyData from '../../datafiles/dashboard/profileDummyData.json';
import ProfileImage from '../../assets/images/Avtaar.png';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { openDialog, closeDialog } from "../../features/dialogBox/dialogBoxSlice";
// import { setProfileImage} from "../../features/commonDataSlice/commonSlice";
import { setOpen, setImage } from "../../features/uploadPic/uploadSlice";
import { setProfileImage } from '../../features/commonDataSlice/commonSlice';
import SharedDialog from '../../features/dialogBox/dialogBox';
import ChangeProfile from './ChangeProfileDialog';
import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';
import { Link } from "react-router-dom";

const Profile = () => {
    const dispatch = useDispatch();
    const dialogContent = (id) => {
      dispatch(openDialog(id));
    }
    const cancel = (id) => {
    dispatch(closeDialog(id));
    }
    // const dispatch = useDispatch();
    const handleEndedDialog = (id) => {
      // setOpenCongrats(true);
      dispatch(openDialog(id));
    }
    const [lastDate, setLastDate] = useState('');
    const userDetail = useSelector((state) => state?.user?.userListName)
    console.log('abc', userDetail)
    
    const taskDetails = useSelector((state) => state?.task?.taskDetails)
    const [taskList, setTaskList] = useState(taskDetails ?? '--')

    // setTaskList(taskDetails)
    useEffect(()=> {
    console.log(taskDetails)
        if (!taskDetails) {
            // Handle case where taskDetails is undefined
            console.log("Task details not available");
          } else {
        const minDaysLeft = taskDetails?.reduce((min, current) => {
            return current?.daysLeft < min ? current?.daysLeft : min;
          }, taskDetails[0]?.daysLeft); // Set initial value to the first object's daysLeft
          const currentDate = new Date();
          const targetDate = new Date(currentDate.getTime() + minDaysLeft * 24 * 60 * 60 * 1000);
          setLastDate(targetDate)
        }
    },[taskDetails])
   
    const navigateToEmployee = (e) => {
        dispatch(setCurrentRoute('/employeeDetails'));
    } 

    const handleCropSave = () => {
        // Dispatch the action to update the user's profile picture
        dispatch(setImage(image));
        // Close the dialog
        // dispatch(openDialog(Id));
        dialogContent('changeProfileDialog')
      };

    const {image} = useSelector(state => state.profile);
 
    // console.log({image});
    
    const theme = useTheme();
    const [progress, setProgress] = React.useState(20);
    function LinearProgressWithLabel(props) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        20
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    function formatDate(dateString) {
        // Create a Date object from the input date string
        const inputDate = new Date(dateString);
      
        // Define an array for month names
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June', 'July',
          'August', 'September', 'October', 'November', 'December'
        ];
      
        // Extract day and month from the input date
        const day = inputDate.getDate();
        const monthIndex = inputDate.getMonth();
        const year = inputDate.getFullYear();
        // Format the date as "DD Month"
        const formattedDate = `${day} ${months[monthIndex]} ${year}`;
      
        return formattedDate;
      }

    return (
        <>
            <Card sx={{minHeight: 'var(--min-height-dashboard-components )',padding:2, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <Card sx={{ backgroundColor: "var(--color-lavender)", boxShadow: 'var(--box-shadow-none)' }} >
                    <CardContent sx={{'&:last-child': {pb:'1rem'}}}>
                        <Stack direction="row" spacing={1}>
                            {/* <Badge
                                // onClick={handleClickOpen}
                                sx={{ borderRadius: '100px' }}
                                id="edit-icon"
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <Avatar sx={{ height: '1.5rem', width: '1.5rem', marginRight: 'var(--margin-right-1)', border:'1px solid white' }} onClick={handleCropSave}>
                                        <ModeEditSharpIcon sx={{ fontSize: 'small' }} />
                                    </Avatar>
                                }
                            > */}
                                {/* <Avatar id="profile-icon" alt="Travis Howard" src={image} sx={{ height: 'var(--equal-hw8)', width: 'var(--equal-hw8)' }} /> */}
                                <Avatar id="profile-icon" alt={userDetail?.userName} src={userDetail?.userAvatar} sx={{ height: 'var(--equal-hw8)', width: 'var(--equal-hw8)' }} /> 
                            {/* </Badge> */}
                            <SharedDialog id='changeProfileDialog'>
                                <Box >
                                   <ChangeProfile/>
                                </Box>
                            </SharedDialog>

                            <Box pt={2}>
                                <Typography variant="subtitle1">
                                    {userDetail?.userName ?? '---'}  {userDetail?.lastName ?? '---'}
                                </Typography>
                                <Box sx={{ mt: 4 }} >
                                    <Typography variant="subtitle2">
                                        {userDetail?.employeeId ?? '---'}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {userDetail?.designation?.designation ?? '---'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Stack>
                    </CardContent>
                </Card>
                <Box sx={{ p: 2 }}>
                    <Box>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={5}>
                                <Typography variant="body2" color="secondary" gutterBottom>
                                    Department :
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom>{userDetail?.department?.department ?? '---'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={5}>
                                <Typography variant="body2" color="secondary" gutterBottom>
                                    Email :
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom>{userDetail?.email ?? '---'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={5}>
                                <Typography variant="body2" color="secondary" gutterBottom>
                                    Complete before :
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom>{formatDate(lastDate)}</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ width: 1 }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <Typography variant="subtitle2">You have completed details <b>1 out of 5</b></Typography>

                    </Box>
                    <Link to="/employeeDetails">
                    <Box onClick={navigateToEmployee} sx={{ padding: 'var(--padding-top-1)', textAlign: "center" }}>
                        <Button sx={{ borderRadius: 'var(--border-radius-dashboard-profile)' }} size='large' variant="contained" startIcon={<DonutLargeSharpIcon />}>
                            <Typography variant='subtitle2'sx={{ letterSpacing: '.1px'}} >COMPLETE PROFILE</Typography>
                        </Button>
                    </Box>
                    </Link>
                </Box>
            </Card>
        </>
    )
}

export default Profile