import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

const EmergencyDetails = ( { setCurrentJSXData, currentJSXData, checkError } ) => {

     const [showErrors, setShowErrors] = React.useState(0);

     let emergencyDetailsModel = [
          { label: 'Contact Name', type: "textField", size: 'small', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid charecters'} ], value: '' },
          { label: 'Relation', type: "singleSelect", value: '', validators:[ { name: 'required'} ], data: [{ value: '', label: '' }, { value: 'father', label: 'Father' }, { value: 'mother', label: 'Mother' }, { value: 'sibling', label: 'Sibling' }, { value: 'spouse', label: 'Spouse' }, { value: 'son', label: 'Son' }, { value: 'daughter', label: 'Daughter' }], validators: ['required'] },
          { label: 'Mobile Number', type: "textField", size: 'small', validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain numbers'},{name:'length', max:15}], value: ''},
          { label: 'Alternate Mobile Number', type: "textField", size: 'small', value: '', validators:[{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain numbers'},{name:'length', max:15}] },
          { label: 'Email', type: "textField", size: 'small', value: '', validators:[{ name:'pattern', regex:/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, errorMessage:'e.g: hello@gmail.com' } ] },
        ];

     let emergencyDetailsHelper = new FormHelper({ model: emergencyDetailsModel, id: 'emergencyDetails' });

     const [emergencyDetailsHelperData, setEmergencyDetailsHelperData] = useState(emergencyDetailsModel);

     const valueChangeListner = () => {
       let emergencyDetailsHelper = emergencyDetailsHelperData.reduce((prev,curr) => {
         prev[curr.label] = curr.value;
         return prev;
       }, []);
   
       var error = false;
       var errorMessage = false;
       
       for (let i = 0; i < emergencyDetailsHelperData.length; i++) {
           for (let j = 0; j < emergencyDetailsHelperData[i]?.validation?.length; j++) {
               var res = emergencyDetailsHelperData[i]?.validation[j](emergencyDetailsHelperData[i].value);
               if (res.error) {
                   error = res.error;
                   errorMessage = res.errorMessage;
                   break;
               }
           }
       }
   
       if(error){
           setShowErrors(showErrors + 1);
           return;
       }
   
      //  console.log({emergencyDetailsHelper});
      setCurrentJSXData({...currentJSXData,emergencyDetailsHelper})
     }

     useEffect(() => {
      console.log("Akas top", {checkError});
      if(checkError){
        valueChangeListner();
      }
    }, [checkError])

     return(

          <>
          <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
               {/* <RenderHelper {...emergencyDetailsHelper.model}/> */}
               <RenderHelper showErrors={showErrors} getCurrentValues={(data) => {setEmergencyDetailsHelperData(data);  }} {...emergencyDetailsHelper.model} />
          </Box>
          </>

     )    
}

export default EmergencyDetails