import React, { useEffect, useState, useRef } from "react";
import {Box, Typography, CardContent, Card, Avatar, CardHeader,
        List, ListItem, ListItemAvatar, ListItemText, Divider,
        ThumbUp as ThumbUpIcon, NewspaperSharp as NewspaperSharpIcon,
        blue} from '../../utils/AllImportsHelper';
import NewsAndUpdatesDummyData from '../../datafiles/dashboard/newsAndUpdatesDummyData.json';
import { styled } from "@mui/material/styles";
import { openSnackbar } from "../../features/snakebar/snackbarSlice";
import ApiService from "../../utils/ApiService";
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import CircularProgress from '@mui/material/CircularProgress';

const ListItemTheme = styled(ListItem)(({ theme }) => ({
  paddingTop: '0',
  paddingBottom: '0',
  //  margin: '2px',
  // '&:hover': {
  //   backgroundColor: 'var(--color-lightblue)', /* theme.palette.action.hover, */
  //   borderRadius: '5px',
  //   //  margin: '2px',
  // },
  // '& .MuiListItemAvatar-root': {
  //   minWidth: '42px',
  // },
}));

const NewsUpdates = () => {
    const [dense, setDense] = React.useState(false);
    const [loading, setLoading] = useState(true);
    // const userDetail = useSelector((state) => state?.user?.userListName?.userId)
    // console.log('abc', userDetail)
    const dispatch = useDispatch();
    const [newsData, setNewsData] = useState([])
  useEffect(() => {
    ApiService.get('leaderSpeak').then(res => {
        let response = res.data.data
        // console.log(response)
        setNewsData(response)
        setLoading(false);
        // if (response) {
        //     dispatch(setUserInfo(response));
        // }
    }).catch((error) => {
      setLoading(false);
        console.log(error);
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }))
    })
},[])

const sanitizeAndStyleHTML = (html) => {
  return DOMPurify.sanitize(html)
    .replace(/<strong>/g, '') // Remove <strong> tags
    .replace(/<\/strong>/g, '') // Remove </strong> tags
    .replace(/<ul>/g, '<div>')
    .replace(/<\/ul>/g, '</div>')
    .replace(/<li>/g, ' ')
    .replace(/<\/li>/g, '<br>');
};

function formatDate(dateString) {
  // Create a Date object from the input date string
  const inputDate = new Date(dateString);

  // Define an array for day names
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Extract day, month, and time from the input date
  const day = inputDate.getDate();
  const monthIndex = inputDate.getMonth();
  const dayIndex = inputDate.getDay();

  // Format the date as "DD Month"
  const formattedDate = `${day}:00 ${days[dayIndex]}`;

  return formattedDate;
}

  return (
    <>
         <Card sx={{  minHeight: 'var(--min-height-dashboard-components )', height:'100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardHeader sx={{ p:'var(--padding-all-1)' }}
              avatar={
                <Avatar>
                  <NewspaperSharpIcon fontSize="small" />
                </Avatar>

              }
              title={<Typography variant="h6">News & Updates</Typography>}
            // subheader="September 14, 2016"
            />
            <CardContent sx={{ p: 'var(--padding-none)' }}>
              <Box id='NewsAndUpdateData' className='news-update'>
              {loading ? ( // Render loader if loading state is true
       <Box className='policy-data' sx={{display:'flex',justifyContent:'center',alignItems:'center'}}> <CircularProgress /></Box>
      ) : (
        <>
                {newsData.map((key, index) => {
                  return (
                    // <Card key={index}>
                    <List dense={dense} key={index} sx={{pt:0,pb:0}}>
                      <ListItemTheme alignItems="flex-start">
                        <ListItemAvatar><Avatar sx={{background:'transparent'}} className="newsupdate-avatar" src={key?.leaderImage} alt="" /></ListItemAvatar>
                        <ListItemText   
                            primary={<Typography variant="subtitle2">{key?.title}</Typography>} 
                            secondary={<Typography variant="body2" color='secondary' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(key?.description)) }}></Typography>} />
                      </ListItemTheme>
                      <ListItem 
                        secondaryAction={<ListItemText edge="end" 
                            secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>{formatDate(key?.created_at)}</Typography>} />}>
                        <ListItemAvatar></ListItemAvatar>{/**/}
                        <ThumbUpIcon fontSize='xs' /> 
                        <ListItemText 
                          primary={<Typography sx={{ml:'var(--margin-small)'}} variant="caption">{key?.reaction_count}</Typography>}
                           />
                      </ListItem>
                      <Divider />
                    </List>
                    // </Card>
                  )
                })}
                </>
      )}
              </Box>
            </CardContent>
          </Card>
    </>
  )
}

export default NewsUpdates