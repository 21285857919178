import React, { useState, useEffect, useRef } from "react";
import UploadService from "../../Service/fileUploadService";
// import LinearProgress from '@mui/material/LinearProgress';
// import Stack from '@mui/material/Stack';
import { Button, Box, Grid, Stack, Typography, Avatar, Link } from "@mui/material";
import WordFile from "../../assets/images/word.png";
import PDFFile  from "../../assets/images/pdf.png";
import ExcelFile from "../../assets/images/excel.png"
import ImageFile from "../../assets/images/picUpload.png"
// import { Box, Button, Grid} from '../../utils/AllImportsHelper';
// import Divider from '@mui/material/Divider';
// import Typography from '@mui/material/Typography';
import UploadFileIcon from '@mui/icons-material/UploadFile';
// import Paper from '@mui/material/Paper';
// import Stack from '@mui/material/Stack';
// import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import CloseIcon from '@mui/icons-material/Close';
// import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
// import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';



export const FileUploadDocumentDrawer = (parentId) => {
  //stack item//
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  // const [state, setState] = useState({ uploadedFiles: [] });

  
  //linear process///////
  // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  //   height: 3,
  //   borderRadius: 5,
  // }));
  //

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);
  const [progressInfos, setProgressInfos] = useState({ val: [] });

  

  const selectFiles = (event) => {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }

    setSelectedFiles(event.target.files);
    setImagePreviews(images);
    setProgressInfos({ val: [] });
    setMessage([]);
    // imageUpload();
    const files = Array.from(event.target.files);

    let _progressInfos = files.map((file) => ({
      percentage: 0,
      fileName: file.name,
      fileSize: file.size
    }));


    // console.log("Nation wants to know", {files,_progressInfos})

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => UploadService.getFiles())
      .then((files) => {
        setImageInfos(files.data);
      })
      .catch((error) => {
        setImageInfos(error);
      });
  };

  const handleRemove = (index)=> {
    let removeFiles = [...selectedFiles];
    removeFiles.splice(index, 1);
    // setState({ uploadedFiles });
    // console.log("usi stage pe aa rhe the",removeFiles,progressInfos)
   setSelectedFiles([...removeFiles]);

   let removeImagePreviews = [...imagePreviews];
   removeImagePreviews.splice(index, 1);
   setImagePreviews(removeImagePreviews);

   let removeProgressInfos = [...progressInfos.val];
   removeProgressInfos.splice(index, 1);
   setProgressInfos({val: removeProgressInfos});
 }

  function formatBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }
  // const imageUpload = () => {
    // const files = Array.from(selectedFiles);

    // let _progressInfos = files.map((file) => ({
    //   percentage: 0,
    //   fileName: file.name,
    //   fileSize: file.size
    // }));


    // // console.log("Nation wants to know", {files,_progressInfos})

    // progressInfosRef.current = {
    //   val: _progressInfos,
    // };

    // const uploadPromises = files.map((file, i) => upload(i, file));

    // Promise.all(uploadPromises)
    //   .then(() => UploadService.getFiles())
    //   .then((files) => {
    //     setImageInfos(files.data);
    //   });
  // };
  //   setMessage([]);
  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    try {
      await UploadService.upload(file, (event) => {
        _progressInfos[idx].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
        setProgressInfos({ val: _progressInfos });
      });
      setMessage((prevMessage) => [
        ...prevMessage,
        "Upload Succesfull",
      ]);
    } catch {
      _progressInfos[idx].percentage = 0;
      setProgressInfos({ val: _progressInfos });

      setMessage((prevMessage_1) => [
        ...prevMessage_1,
        "Upload Failure",
      ]);
    }
  };
  useEffect(() => {
    UploadService.getFiles().then((response) => {
      setImageInfos(response.data);
    });
  }, []);
  return (
    <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer)', lg:'var(--width-drawer)'}}}>
          <Stack sx={{ backgroundColor: "var(--color-lightblue)" }}>
                  <Box sx={{p:'.5rem .1rem'}}>
                      <Grid container direction="row" alignItems="center">
                          <Grid item xs={10}>
                              <Box pl='1rem' display='flex'>
                                  <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Upload Documents</Typography>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sx={{display:'flex', justifyContent:'flex-end'}}>
                              <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                                  <CloseIcon sx={{color:'var(--color-black)'}}/>
                              </IconButton>
                          </Grid>
                      </Grid>
                  </Box>
          </Stack>
      <Box style={{ padding: "15px", maxHeight:'40rem', overflow:'auto', pt:'var(--equal-hw1)' }}>
        <Box>
          <Box>
            <Box>
              <Stack
                sx={{
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',  
                  marginTop: "0.5rem",
                  border: "2px dashed rgba(0, 0, 0, 0.12)",
                  borderRadius: "12px",
                  backgroundColor: "rgba(201, 201, 201, 0.1)",
                  ":hover": { backgroundColor: "#DBEAFE", borderColor:"var(--color-mui-primary-blue)" },
                }}
              >
                <Box className="upload-file-icon" sx={{p:"1rem 0rem"}}>
                  <Avatar sx={{height:'2.5rem', width:'2.5rem', backgroundColor:'#1976D21F' }}>
                    <UploadFileIcon color="primary"/>
                  </Avatar>
                </Box>
                <Box className="upload-btn-wrapper">
                    <span>
                    {/* <IconButton color="primary" aria-label="upload picture" component="label">
                    <UploadFileIcon />
                    </IconButton> */}
                      <Link className="btn" sx={{ textDecoration: "underline" }}>
                        Click to upload
                      </Link>
                      <input
                        type="file"
                        name="myfile"
                        multiple
                        accept="image/*,.mp4,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={selectFiles}
                      />
                    </span>
                    <span> or drag and drop </span>
                </Box>
                <Box m='1rem 0rem'>
                    <Typography variant="body2" color="text.secondary" display="block" gutterBottom>SVG, PNG, JPG or GIF (max. 3MB)</Typography>
                </Box>
              </Stack>
            </Box>
          </Box>
          <Box sx={{ ml:'3rem' }}>
            {progressInfos &&
              progressInfos.val.length > 0 &&
              progressInfos.val.map((progressInfo, index) => (
                <Box key={index} sx={{ my: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                      <Box>
                        {(selectedFiles?.[index]?.name.endsWith(".docx")) ?
                          (
                            <>
                              <img
                                className="preview"
                                src={WordFile}
                                alt={"image-" + index}
                                key={index}
                              />
                            </>
                          )
                          :selectedFiles?.[index]?.name.endsWith(".pdf") ? (
                            <img
                              className="preview"
                              src={PDFFile}
                              alt={"image-" + index}
                              key={index}
                            />
                          ) : selectedFiles?.[index]?.name.endsWith(".xlsx") ||
                            selectedFiles?.[index]?.name.endsWith(".xls") ||
                            selectedFiles?.[index]?.name.endsWith(".csv") ? (
                            <img
                              className="preview"
                              src={ExcelFile}
                              alt={"image-" + index}
                              key={index}
                            />
                          ) : (
                            <img height={30} width={30} className="preview" src={ImageFile} alt={"image-" + index} key={index} />
                          )
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Box>
                        <Box>{progressInfo.fileName}</Box>
                        <Box sx={{ my: 1 }}>
                          <Stack direction="row" spacing={2}>
                            <span><li>{formatBytes(progressInfo.fileSize)}</li></span>

                            <span>
                              {message.length > 0 && (
                                <Box className="alert alert-secondary" role="alert">
                                  <li>
                                    {message[index]}
                                  </li>
                                </Box>
                              )}
                            </span>
                          </Stack>
                        </Box>
                        <Box>
                          {/* {console.log({progressInfos})} */}
                          {/* <LinearProgress value={progressInfo.percentage} /> */}
                          <LinearProgress variant="determinate" value={100} sx={{'& .MuiLinearProgress-bar': {backgroundColor: 'red'}}} />
                          {/* <LinearProgress variant="determinate" value={100} /> */}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} md={2}>
                      <Box sx={{ mb: 1 }}>
                        <IconButton aria-label="upload picture" component="label" onClick={() => handleRemove(index)}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Box>
          <Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{backgroundColor:'white', width:'100%', position:'absolute', bottom:'0', mt:'4rem', height:'4rem'}}>
        <Stack sx={{ mx:'1rem', pt:'1rem'}} direction="row" spacing={1}>
          <Button variant="contained" size="small">SUBMIT</Button>
          <Button variant="text" size="small" onClick={handleDrawerClose} >CANCEL</Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default FileUploadDocumentDrawer