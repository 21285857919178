import React from "react";
import { useState } from 'react';
import "../assets/styles/Theme.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../features/drawers/drawerSlice';
import HelpCenterDrawer from '../components/drawerBody/HelpCenterDrawer';
import SharedDrawer from '../features/drawers/Drawer';

const faqData = [
     {
         question: "What is the Employee Onboarding process",
         answer: "Onboarding is a process to introduce and welcome employees to the organization and speed up the adjustment process encouraging integration into the Wayne State University environment. The WSU onboarding process consists of four orientation elements. This site is supplemental to the orientation process and serves as a central portal of information to use as either a starting point or as a reference.",
     },
     {
          question: "Who should Participate in an onboarding program?",
          answer: "Every employee who joins a company or who assumes new responsibilities should participate in a tailored onboarding program. Managers, who also have the responsibility of onboarding their own employees, should definitely undergo the onboarding experience. Senior Managers and Executives also need to participate.",
     },
     {
          question: "How will an onboarding program benefit company?",
          answer: "Because onboarding programs prepare employees to execute their functions, these programs increase business results, employee engagement, and company branding which, in turn, lead to higher company profits.",
     },
     {
          question: "About how long should an onboarding program take?",
          answer: "In our experience, an onboarding program should take at least three to six months depending on the employee's role.",
     },
];



const HelpCenter = () => {

     const dispatch = useDispatch();
     const openingTheDrawer = (id) => {
          dispatch(openDrawer(id));
     }

     
     
     const [expanded, setExpanded] = React.useState('panel01');
     
     const handleAccordianChange = (panel) => (event, isExpanded) => {
          setExpanded(isExpanded ? panel : false);
        };

     return(
          <>
          <Box sx={{p:'2rem',height:'45rem', backgroundColor:'white'}}>
               <Box sx={{height:'100%'}}>
                    <Box>
                         <Typography fontSize='1.25rem' fontWeight='700' >Help Center</Typography>
                         <Typography variant='body2' sx={{pb:'.5rem',opacity:'38%'}}>Everything you want to know about Onboarding</Typography>
                         <Divider />  
                    </Box>     
                    <Box>
                         <Typography p='1rem 0rem' fontSize='1.53rem' fontWeight='var(--font-weight-6)' >FAQ</Typography>
                    </Box>
                    <Box>
                         {faqData.map((text, index) => (
                         <Accordion
                              key={index}
                              // expanded={expanded === 'panel01'} onChange={handleAccordianChange('panel01')} sx={{ "&.Mui-expanded": { backgroundColor: "black" } }}>
                              disableGutters
                              elevation={0}
                              sx={{
                                   pb:'1rem',
                                   '&:before': {
                                        display: 'none',
                                   }
                              }}>
                              <AccordionSummary
                                   expandIcon={<ExpandMoreIcon />}
                                   aria-controls="panel1a-content"
                                   id="panel1a-header"
                              >
                                   <Typography variant='h6'>{text.question}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                   <Typography variant='body2'>
                                   {text.answer}
                                   </Typography>
                              </AccordionDetails>
                         </Accordion>
                         ))}
                         <Typography display='inline-flex' variant='body1' fontSize="1.125rem" pl="1rem">Still have question or query?</Typography>
                         <Link><Typography onClick={() => openingTheDrawer('helpCenter')} className="help-center-link" sx={{display:'inline-flex', variant:'body1', fontSize:"1.125rem", pl:'.5rem'}}>Write to us</Typography></Link>
                         
                         <SharedDrawer id='helpCenter'>
                              <Box className="tasklist_drawer">
                                 <HelpCenterDrawer parentId='helpCenter'/>
                              </Box>
                         </SharedDrawer>
                         
                    </Box>
               </Box>
               
          </Box>
          </>
     );
}

export default HelpCenter