import { configureStore } from '@reduxjs/toolkit';
import drawerReducer from './features/drawers/drawerSlice';
import dialogReducer from './features/dialogBox/dialogBoxSlice';
import wheelSlice from './features/wheel/wheelSlice';
import uploadSlice from './features/uploadPic/uploadSlice';
import commonSlice from './features/commonDataSlice/commonSlice';
import sidebarSelectionSlice from './features/sidebarRouting/sidebarSelectionSlice';
import userInfo from './features/userInfoStore/userSlice';
import snackbarReducer from './features/snakebar/snackbarSlice';
import taskDetails from './features/taskDetailsStore/taskSlice';

const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    dialog: dialogReducer,
    wheel: wheelSlice,
    user: userInfo,
    task: taskDetails,
    profile: commonSlice,
    imageCropper: uploadSlice,
    routing: sidebarSelectionSlice,
    snackbar: snackbarReducer,
  },
});

export default store;
